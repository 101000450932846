import { Typography } from "@mui/material";
import { customTheme } from "../theme/muconfig";
import { HeaderFivee } from "./Headers/Headers";
interface IModel {
  children?: any;
  extraStyles?: any;
}

export const HeaderFive = (props: IModel) => {
  return (
    <HeaderFivee variant="h5" sx={{ color: customTheme.palette.primaryy.light, textTransform: "capitalize", ...props.extraStyles }}>
      {props.children}
    </HeaderFivee>
  );
};