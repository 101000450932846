import { Button, Typography, createTheme, styled } from "@mui/material";
import { customTheme } from "../../theme/muconfig";


export const HeaderOne = styled(Typography)(({ theme }) => ({

    [theme.breakpoints.down("xs")]: {
        fontSize: "48px"
    },
    [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "64px"
    },
    [theme.breakpoints.between("sm", "md")]: {
        fontSize: "64px"
    },
    [theme.breakpoints.between("md", "lg")]: {
        fontSize: "96px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "96px"
    },
}));

export const HeaderTwo = styled(Typography)(({ theme }) => ({

    [theme.breakpoints.down("xs")]: {
        fontSize: "34px"
    },
    [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "48px"
    },
    [theme.breakpoints.between("sm", "md")]: {
        fontSize: "48px"
    },
    [theme.breakpoints.between("md", "lg")]: {
        fontSize: "60px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "60px"
    },
}));

export const HeaderThree = styled(Typography)(({ theme }) => ({

    [theme.breakpoints.down("xs")]: {
        fontSize: "28px"
    },
    [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "34px"
    },
    [theme.breakpoints.between("sm", "md")]: {
        fontSize: "34px"
    },
    [theme.breakpoints.between("md", "lg")]: {
        fontSize: "48px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "48px"
    },
}));

export const HeaderFour = styled(Typography)(({ theme }) => ({

    [theme.breakpoints.down("xs")]: {

        fontSize: "20px"
    },
    [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "28px"
    },
    [theme.breakpoints.between("sm", "md")]: {
        fontSize: "28px"
    },
    [theme.breakpoints.between("md", "lg")]: {
        fontSize: "34px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "34px"
    },
}));

export const HeaderFivee = styled(Typography)(({ theme }) => ({
    color: customTheme.palette.primaryy.light,
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
        fontSize: "18px"
    },
    [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.between("sm", "md")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.between("md", "lg")]: {
        fontSize: "24px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "24px"
    },
}));

export const HeaderFiveTwo = styled(Typography)(({ theme }) => ({
    textTransform: "capitalize",

    [theme.breakpoints.down("xs")]: {

        fontSize: "18px"
    },
    [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.between("sm", "md")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.between("md", "lg")]: {
        fontSize: "24px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "24px"
    },
}));

export const HeaderSix = styled(Typography)(({ theme }) => ({

    [theme.breakpoints.down("xs")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "18px"
    },
    [theme.breakpoints.between("sm", "md")]: {
        fontSize: "18px"
    },
    [theme.breakpoints.between("md", "lg")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "20px"
    },
}));

export const BoddyOne = styled(Typography)(({ theme }) => ({

    [theme.breakpoints.down("xs")]: {

        fontSize: "12px"
    },
    [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.between("sm", "md")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.between("md", "lg")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "16px"
    },
}));

export const BoddyTwo = styled(Typography)(({ theme }) => ({

    [theme.breakpoints.down("xs")]: {

        fontSize: "10px"
    },
    [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.between("sm", "md")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.between("md", "lg")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "14px"
    },
}));

export const Caption = styled(Typography)(({ theme }) => ({

    [theme.breakpoints.down("xs")]: {

        fontSize: "8px"
    },
    [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "8px"
    },
    [theme.breakpoints.between("sm", "md")]: {
        fontSize: "10px"
    },
    [theme.breakpoints.between("md", "lg")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "12px"
    },
}));