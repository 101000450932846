import { CardContent } from "@mui/material";
interface IModel {
  children: any;
}

export const CardContents = (props: IModel) => {
  return (
    <CardContent
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      {props.children}
    </CardContent>
  );
};
