import { Alert, Input } from "@mui/material";
import { customTheme } from "../../../theme/muconfig";
import { AlertComponent, BudgetFreezeComponent, HackedComponent } from "../../../components/Error";
import classes from "./boardstyle.module.scss"
import classNames from "classnames";
import { BaseModalWrapper } from "../../../components/Modal/Modal";
import { HowToPlaye } from "../../../module-gamepages/views/HowPlayeContent";
import { QuizCard } from "../../../components/QuizeCard";
import { FunctionCard } from "../../../components/FunctionCard";
import { BlockBoardDilogBoxBottom, BlockBoardDilogBoxLeft, BlockBoardDilogBoxRight, BlockBoardDilogBoxUpper, BuyServicesBlockBoardDilogBoxBottom, BuyServicesBlockBoardDilogBoxRight, BuyServicesBlockBoardDilogBoxUpper, RepairBlockBoardDilogBoxBottom, RepairBlockBoardDilogBoxLeft, RepairBlockBoardDilogBoxRight, RepairBlockBoardDilogBoxUpper } from "../../../components/DilogBox/DilogBox";
import { BoddyOne, BoddyTwo, Caption, HeaderSix } from "../../../components/Headers/Headers";
import { EndGame } from "../../../components/WinnerGame/WinnerGame";
import { CountDownTimer } from "../../../components/CountDownTimer";
import { BlureBtn, CustomButton, PrimaryButton, SecondaryButton } from "../../../components/Buttons/Button";
import { GeneralErrorModule } from "../../../module-errores/GeneralErrorContent";
import { IBoardModel } from "./model";
import { createRef, useEffect, useRef, useState } from "react";
import { LighterModal } from "../../../components/LighterModal/LighterModal";
import { Corectness, Wrong, HackedAlert } from "../../../components/Animation/animation";
import { motion, transform } from "framer-motion";
import { Dice } from "../../../components/RollDice/view";
import zIndex from "@mui/material/styles/zIndex";
import { CardPrime } from "../../../components/CardPrime/view";
import { HowToPlaySlider } from "../../../components/HowToPlaySlider/HowToPlaySlider";

const dropIn: any = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.2,
            type: "spring",
            damping: 25,
            stiffness: 500
        }
    },
    exit: {
        y: "100vh",
        opacity: 0,
    }
}

const player_location = (location: any, size: any) => {
    let row = 1;
    let col = 1;
    let i = 1;
    while (i < location) {
        if (i < size) {
            col++;
        } else if (i < 2 * size - 1) {
            row++;
        } else if (i < 3 * size - 2) {
            col--;
        } else {
            row--;
        }

        i++
    }

    return [row, col]
}

function splitPlayersByLocation(players: Array<any>) {
    const locationMap = new Map();

    players.forEach((player: any) => {
        const location = player.location;

        if (!locationMap.has(location)) {
            locationMap.set(location, []);
        }

        locationMap.get(location).push(player);
    });

    const sameLocation: Array<any> = [];
    const differentLocation: Array<any> = [];

    locationMap.forEach((playersInLocation) => {
        if (playersInLocation.length > 1) {
            sameLocation.push(...playersInLocation);
        } else {
            differentLocation.push(playersInLocation[0]);
        }
    });

    return {
        sameLocation,
        differentLocation,
    };
}

export const View = (props: any) => {
    const { sameLocation, differentLocation } = splitPlayersByLocation(props.players_state);
    const [row_same, col_same] = sameLocation.length > 0 ? player_location(sameLocation[0].location + 1, 11) : [1, 1];
    //const [row_different, col_different] = differentLocation.length > 0 ? player_location(differentLocation[0].location + 1, 11) : [1, 1];
    const [gridSize, setGridSize] = useState(62);
    const [moveAnimationMode, setmoveAnimationMode] = useState(false);
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (currentIndex < props.services_text.length) {
            const timeout = setTimeout(() => {
                setCurrentText(prevText => prevText + props.services_text[currentIndex])
                setCurrentIndex(prevIndex => prevIndex + 1)
            }, 50);

            return () => clearTimeout(timeout)
        }
    }, [currentIndex, props.services_text])
    useEffect(() => {
        setCurrentIndex(0);
        setCurrentText('');
    }, [props.services_text]);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1440) {
                setGridSize(62);
                setmoveAnimationMode(true);
            } else if (window.innerWidth < 1440 && window.innerWidth >= 1024) {
                setGridSize(52);
                setmoveAnimationMode(true);
            } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                setmoveAnimationMode(false);
            } else if (window.innerWidth < 768 && window.innerWidth >= 425) {
                setmoveAnimationMode(false);
            } else if (window.innerWidth < 425 && window.innerWidth >= 320) {
                setmoveAnimationMode(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [differentLocation]);

    return (
        <>
            <BaseModalWrapper show_modal={props.show_modal} onBackDropClick={props.handler_toggleModal} >
                {props.current_page === 'howtoplay' && <motion.div
                    variants={dropIn}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    // className="modal-parent"
                    className={classNames(classes.how_play_container)}
                >
                    <HowToPlaySlider handler_toggleModal={props.handler_toggleModal} />
                </motion.div>}
                {props.current_page === 'function_card' &&
                    <FunctionCard type={props.function_card.type} price={props.function_card.price} rent={props.function_card.rent} people={props.function_card.people}
                        number_page={!props.function_card.boddy_3 ? 2 : 3}
                        header_one={props.function_card.header_1}
                        header_two={props.function_card.header_2}
                        content_one={props.function_card.body_1}
                        content_two={props.function_card.body_2}
                        handler_onActionImpliment={props.handler_onActionImpliment}
                        handler_onActionRefuse={props.handler_onActionRefuse}
                        refuse={props.refuse}
                        is_animating={props.is_animating}
                    />
                }
                {props.current_page === 'quiz' &&
                    <QuizCard handler_onAgree={props.handler_onAgree} handler_onDisAgree={props.handler_onDisAgree} type={props.quiz_card.type} header={props.quiz_card.header} text={props.quiz_card.text} handler_toggleModal={props.handler_toggleModal} />
                }
                {props.current_page === 'game_finished' && <EndGame show_close_icon={true} handler_toggleModal={props.handler_toggleModal} winner_nickname={props.winner_nickname} winner_loser_player_status={props.winner_loser_player_status} />}
                {props.current_page === "error" && <GeneralErrorModule currentPage={props.current_page} text_error={props.error_interaction_message.error} />}
            </BaseModalWrapper>
            {props.active_light_modal &&
                <>
                    {
                        props.successed_failed === "correct" ? <LighterModal z={true} successed_failed={props.successed_failed} show_modal={props.show_modal_two} onBackDropClick={props.handler_toggleModal_two}>
                            <Corectness />
                            <BaseModalWrapper show_modal={props.show_modal} onBackDropClick={props.handler_toggleModal} >
                                {props.current_page === 'quiz' &&
                                    <QuizCard handler_onAgree={props.handler_onAgree} handler_onDisAgree={props.handler_onDisAgree} type={props.quiz_card.type} header={props.quiz_card.header} text={props.quiz_card.text} handler_toggleModal={props.handler_toggleModal} />
                                }
                            </BaseModalWrapper>
                        </LighterModal> : <LighterModal z={true} successed_failed={props.successed_failed} show_modal={props.show_modal_two} onBackDropClick={props.handler_toggleModal_two}>
                            <Wrong />
                            <BaseModalWrapper show_modal={props.show_modal} onBackDropClick={props.handler_toggleModal} >
                                {props.current_page === 'quiz' &&
                                    <QuizCard handler_onAgree={props.handler_onAgree} handler_onDisAgree={props.handler_onDisAgree} type={props.quiz_card.type} header={props.quiz_card.header} text={props.quiz_card.text} handler_toggleModal={props.handler_toggleModal} />
                                }
                            </BaseModalWrapper>
                        </LighterModal>
                    }
                </>
            }
            {props.current_page === "hacked_alert" && <LighterModal z={true} successed_failed={"wrong"} show_modal={props.show_modal_two} onBackDropClick={props.handler_toggleModal_two}>
                <HackedAlert />
            </LighterModal>}
            <div className={classNames(classes.container)}>
                <div className={classNames(classes.left_container, classes.item)}>
                    <div className={classNames(classes.player_navbar_container, classes.box_item)} >
                        <div className={classNames(classes.player_navbar_container_avatar)} >
                            {
                                props.is_registered && <img alt={`${props.profile_avatar}`} src={`/images/Avatars/${props.profile_avatar}.svg`} />
                            }
                        </div>
                        <div className={classNames(classes.player_navbar_container_sounds)} onClick={props.onclick_sound}>
                            {
                                props.is_mute ? <img alt="sounds-mute" src="/images/ProfilePanel/sound_mute.svg" /> : <img alt="sounds" src="/images/ProfilePanel/sound.svg" />
                            }
                        </div>
                    </div>
                    <div className={classNames(classes.share_link_container, classes.box_item)} >
                        <HeaderSix variant="h6" textTransform={"capitalize"} color={customTheme.palette.primaryy.light}  >Share this game</HeaderSix>
                        <Caption sx={{ my: 2 }} variant="caption" color={customTheme.palette.neutrals.mlight}>
                            This is a private game room. Invite your friends to play by sharing the link below.
                        </Caption>
                        <div className={classNames(classes.share_link_box)}>
                            <Input sx={{ color: customTheme.palette.neutrals.mlight, minWidth: "0", width: "70%", }} type="text" value={props.dynamicLink} readOnly />
                            <BlureBtn onClick={props.handler_OnCopyLink}>
                                <img alt="copy url" src="/images/ProfilePanel/url_copy.svg" />
                            </BlureBtn>
                        </div>
                    </div>
                    <div className={classNames(classes.advertise_container, classes.box_item)} >
                        <img alt="advertis" src="/images/advertis/addbanner.svg" />
                    </div>
                </div>
                <div className={classNames(classes.board_container, classes.item)} style={{ position: "relative" }}>
                    {props.board_game_squares.map((square: IBoardModel, index: number) => {
                        let square_content;
                        if (square.type === "block") {
                            square_content = (
                                <>
                                    <div className={classNames(classes.board_square_block_container,
                                        square.content!.type === "mystery" ? classes.board_square_block_mystery : classes.board_square_block_other,
                                        ((square.col === 11 && square.row === 5) || (square.col === 11 && square.row === 8) || (square.col === 11 && square.row === 10)) ? classes.board_square_block_right_side : ((square.col === 1 && square.row === 4) || (square.col === 1 && square.row === 8)) ? classes.board_square_block_left_side : classes.board_square_block_top_bottom_side
                                    )} key={index}>
                                        <img
                                            alt={square.type}
                                            src={`/images/BoardGameIcones/${square.content!.type}.svg`} />
                                        {((square.col === 1 && square.row === 1) || (square.col === 1 && square.row === 11) || (square.col === 11 && square.row === 11) || (square.col === 11 && square.row === 1)) ? <Caption className={classNames(classes.board_square_block_text)} sx={{ color: square.content!.header === "hacked" ? customTheme.palette.accent_one.main : customTheme.palette.neutrals.with }} textTransform={"capitalize"}>
                                            {(square.content!.header)}
                                        </Caption> : ""}
                                        {
                                            square.row === 1 && square.col === 7 && props.player_state.location === 6 && (props.player_state.status === "token_changer" || props.player_state.status === "card_added") &&
                                            <BlockBoardDilogBoxUpper children={props.player_state.status === "token_changer" ? props.player_state.token_changer_obj.text : props.player_state.status === "card_added" ? props.player_state.response_text : props.player_state.status} />
                                        }
                                        {
                                            square.row === 11 && square.col === 8 && props.player_state.location === 23 && (props.player_state.status === "token_changer" || props.player_state.status === "card_added") &&
                                            <BlockBoardDilogBoxBottom children={props.player_state.status === "token_changer" ? props.player_state.token_changer_obj.text : props.player_state.status === "card_added" ? props.player_state.response_text : props.player_state.status} />
                                        }
                                        {
                                            square.row === 11 && square.col === 5 && props.player_state.location === 26 && (props.player_state.status === "token_changer" || props.player_state.status === "card_added") &&
                                            <BlockBoardDilogBoxBottom children={props.player_state.status === "token_changer" ? props.player_state.token_changer_obj.text : props.player_state.status === "card_added" ? props.player_state.response_text : props.player_state.status} />
                                        }
                                        {
                                            square.row === 8 && square.col === 1 && props.player_state.location === 33 && (props.player_state.status === "token_changer" || props.player_state.status === "card_added") &&
                                            <BlockBoardDilogBoxLeft children={props.player_state.status === "token_changer" ? props.player_state.token_changer_obj.text : props.player_state.status === "card_added" ? props.player_state.response_text : props.player_state.status} />
                                        }
                                        {
                                            square.row === 10 && square.col === 11 && props.player_state.location === 19 && (props.player_state.status === "token_changer" || props.player_state.status === "card_added") &&
                                            <BlockBoardDilogBoxRight children={props.player_state.status === "token_changer" ? props.player_state.token_changer_obj.text : props.player_state.status === "card_added" ? props.player_state.response_text : props.player_state.status} />
                                        }
                                    </div>
                                </>
                            )
                        } else if (square.type === "function") {
                            if (square.row === 1 && 2 <= square.col && square.col <= 10) {
                                square_content = (
                                    <div className={classNames(classes.board_square_function_container, classes.board_upper_side,
                                        square.content?.type === "identify" ? classes.board_square_function_container_identify :
                                            square.content?.type === "protect" ? classes.board_square_function_container_protect :
                                                square.content?.type === "detect" ? classes.board_square_function_container_detect :
                                                    square.content?.type === "recover" ? classes.board_square_function_container_recover :
                                                        square.content?.type === "respond" ? classes.board_square_function_container_respond : "",

                                    )}

                                    >
                                        <div className={classNames(classes.board_square_function_price_container,
                                            square.purchesed_color ?
                                                square.purchesed_color === "red" ?
                                                    classes.square_purchesed_color_red :
                                                    square.purchesed_color === "blue" ?
                                                        classes.square_purchesed_color_blue :
                                                        square.purchesed_color === "yellow" ?
                                                            classes.square_purchesed_color_yellow :
                                                            square.purchesed_color === "green" ?
                                                                classes.square_purchesed_color_green :
                                                                square.purchesed_color === "purple" ?
                                                                    classes.square_purchesed_color_purple :
                                                                    ""
                                                :
                                                ""
                                        )}>
                                            <Caption className={classNames(classes.board_square_function_price_text)}>{square.content?.price}</Caption>
                                        </div>
                                        <img className={classNames(classes.board_square_function_icon)} alt={square.content?.type} src={`/images/BoardGameIcones/${square.content?.type}.svg`} />
                                        <Caption>{square.content?.header}</Caption>
                                    </div>
                                )
                            } else if (square.row === 11 && 2 <= square.col && square.col <= 10) {
                                square_content = (
                                    <div className={classNames(classes.board_square_function_container, classes.board_bottom_side,
                                        square.content?.type === "identify" ? classes.board_square_function_container_identify :
                                            square.content?.type === "protect" ? classes.board_square_function_container_protect :
                                                square.content?.type === "detect" ? classes.board_square_function_container_detect :
                                                    square.content?.type === "recover" ? classes.board_square_function_container_recover :
                                                        square.content?.type === "respond" ? classes.board_square_function_container_respond : "",

                                    )}

                                    >
                                        <Caption>{square.content?.header}</Caption>
                                        <img className={classNames(classes.board_square_function_icon)} alt={square.content?.type} src={`/images/BoardGameIcones/${square.content?.type}.svg`} />
                                        <div className={classNames(classes.board_square_function_price_container,
                                            square.purchesed_color ?
                                                square.purchesed_color === "red" ?
                                                    classes.square_purchesed_color_red :
                                                    square.purchesed_color === "blue" ?
                                                        classes.square_purchesed_color_blue :
                                                        square.purchesed_color === "yellow" ?
                                                            classes.square_purchesed_color_yellow :
                                                            square.purchesed_color === "green" ?
                                                                classes.square_purchesed_color_green :
                                                                square.purchesed_color === "purple" ?
                                                                    classes.square_purchesed_color_purple :
                                                                    ""
                                                :
                                                "")}>
                                            <Caption className={classNames(classes.board_square_function_price_text)}>{square.content?.price}</Caption>
                                        </div>
                                    </div>
                                )
                            } else if (square.col === 1 && 2 <= square.row && square.row <= 10) {
                                square_content = (
                                    <div className={classNames(classes.board_square_function_container, classes.board_left_side,
                                        square.content?.type === "identify" ? classes.board_square_function_container_identify :
                                            square.content?.type === "protect" ? classes.board_square_function_container_protect :
                                                square.content?.type === "detect" ? classes.board_square_function_container_detect :
                                                    square.content?.type === "recover" ? classes.board_square_function_container_recover :
                                                        square.content?.type === "respond" ? classes.board_square_function_container_respond : "",

                                    )}

                                    >
                                        <Caption className={classNames(classes.board_square_function_header_left_side)} >{square.content?.header}</Caption>
                                        <img alt={square.content?.type} src={`/images/BoardGameIcones/${square.content?.type}.svg`} />
                                        <div className={classNames(classes.board_square_function_price_container,
                                            square.purchesed_color ?
                                                square.purchesed_color === "red" ?
                                                    classes.square_purchesed_color_red :
                                                    square.purchesed_color === "blue" ?
                                                        classes.square_purchesed_color_blue :
                                                        square.purchesed_color === "yellow" ?
                                                            classes.square_purchesed_color_yellow :
                                                            square.purchesed_color === "green" ?
                                                                classes.square_purchesed_color_green :
                                                                square.purchesed_color === "purple" ?
                                                                    classes.square_purchesed_color_purple :
                                                                    ""
                                                :
                                                "")}>
                                            <Caption className={classNames(classes.board_square_function_price_text)}>{square.content?.price}</Caption>
                                        </div>
                                    </div>
                                )
                            } else if (square.col === 11 && 2 <= square.row && square.row <= 10) {
                                square_content = (
                                    <div className={classNames(classes.board_square_function_container, classes.board_right_side,
                                        square.content?.type === "identify" ? classes.board_square_function_container_identify :
                                            square.content?.type === "protect" ? classes.board_square_function_container_protect :
                                                square.content?.type === "detect" ? classes.board_square_function_container_detect :
                                                    square.content?.type === "recover" ? classes.board_square_function_container_recover :
                                                        square.content?.type === "respond" ? classes.board_square_function_container_respond : "",
                                    )}

                                    >
                                        <Caption className={classNames(classes.board_square_function_header_right_side)}>{square.content?.header}</Caption>
                                        <img alt={square.content?.type} src={`/images/BoardGameIcones/${square.content?.type}.svg`} />
                                        <div className={classNames(classes.board_square_function_price_container,
                                            square.purchesed_color ?
                                                square.purchesed_color === "red" ?
                                                    classes.square_purchesed_color_red :
                                                    square.purchesed_color === "blue" ?
                                                        classes.square_purchesed_color_blue :
                                                        square.purchesed_color === "yellow" ?
                                                            classes.square_purchesed_color_yellow :
                                                            square.purchesed_color === "green" ?
                                                                classes.square_purchesed_color_green :
                                                                square.purchesed_color === "purple" ?
                                                                    classes.square_purchesed_color_purple :
                                                                    ""
                                                :
                                                "")}>
                                            <Caption className={classNames(classes.board_square_function_price_text)}>{square.content?.price}</Caption>
                                        </div>
                                    </div>
                                )
                            }
                        }

                        return <div
                            style={{
                                gridRow: square.row,
                                gridColumn: square.col,
                            }}
                            className={classNames(classes.board_square)}
                        >
                            {square_content}
                        </div>
                    })}
                    {
                        sameLocation.length > 0 && (
                            <div
                                style={{
                                    gridRow: row_same,
                                    gridColumn: col_same,
                                }}
                                className={classNames(classes.player_board_pawn_same_location_container)}
                            >
                                {sameLocation.map((player: any, index: any) => {
                                    return (
                                        <img
                                            key={player.player_number}
                                            alt={player.color}
                                            src={`/pawns/${player.color}_pawn.svg`}
                                            style={{ width: `${100 / sameLocation.length}%` }}
                                            className={classNames(!props.show_modal && classes.player_board_pawn_same_location)}
                                        />
                                    );
                                })}
                            </div>
                        )
                    }
                    {
                        differentLocation.map((player: any) => {
                            const [row_different, col_different] = player_location(player.location + 1, 11);
                            return (
                                <div
                                    style={{
                                        gridRow: row_different,
                                        gridColumn: col_different,
                                    }}
                                    className={classNames(classes.player_board_pawn_different_location_container)}
                                >
                                    <img
                                        key={player.player_number}
                                        alt={player.color}
                                        src={`/pawns/${player.color}_pawn.svg`}
                                        className={classNames(!props.show_modal && classes.player_board_pawn_same_location)}
                                    />

                                </div>
                            )
                            // if (moveAnimationMode) {
                            //     let pawnStyle;
                            //     if (player.player_number === props.pawnPosition.player_number && player.is_turn) {
                            //         pawnStyle = {
                            //             transform: `translate(${((props.pawnPosition.x) * gridSize + (props.currentPawnPosition.x) * gridSize)}px , ${(props.pawnPosition.y) * gridSize + (props.currentPawnPosition.y) * gridSize}px)`
                            //         };
                            //         return (
                            //             <div
                            //                 ref={props.pawns_comtainer}
                            //                 id={`pawn-${player.player_number}`}
                            //                 key={player.player_number}
                            //                 className={classNames(classes.pawn)}
                            //                 style={pawnStyle}
                            //             >
                            //                 <img
                            //                     alt={player.color}
                            //                     src={`/pawns/${player.color}_pawn.svg`}
                            //                     className={classNames(!props.show_modal && classes.player_board_pawn_different_location)}
                            //                 />
                            //             </div>
                            //         );
                            //     }
                            //     if (!player.is_turn) {
                            //         const [row_different, col_different] = differentLocation.length > 0 ? player_location(player.location + 1, 11) : [1, 1];
                            //         return (
                            //             <div
                            //                 key={player.player_number}
                            //                 style={{
                            //                     gridRow: row_different,
                            //                     gridColumn: col_different,
                            //                 }}
                            //                 className={classNames(!props.show_modal && classes.player_board_pawn_different_location)}
                            //             >
                            //                 <img
                            //                     alt={player.color}
                            //                     src={`/pawns/${player.color}_pawn.svg`}
                            //                     className={classNames(!props.show_modal && classes.player_board_pawn_different_location)}
                            //                 />
                            //             </div>
                            //         );
                            //     }
                            //     return null;
                            // } else {
                            //     const [row_different, col_different] = differentLocation.length > 0 ? player_location(player.location + 1, 11) : [1, 1];
                            //     return (
                            //         <div
                            //             key={player.player_number}
                            //             style={{
                            //                 gridRow: row_different,
                            //                 gridColumn: col_different,
                            //             }}
                            //             className={classNames(!props.show_modal && classes.player_board_pawn_different_location)}
                            //         >
                            //             <img
                            //                 alt={player.color}
                            //                 src={`/pawns/${player.color}_pawn.svg`}
                            //                 className={classNames(!props.show_modal && classes.player_board_pawn_different_location)}
                            //             />
                            //         </div>
                            //     );
                            // }

                        })
                    }
                    < div className={classNames(classes.board_middle_container)}>
                        <div className={classNames(classes.board_middle_dices_container)}>
                            <Dice shaking_dice_one_animation={props.shaking_dice_one_animation} shaking_dice_two_animation={props.shaking_dice_two_animation} rotationDiceOne={props.rotationDiceOne} rotationDiceTwo={props.rotationDiceTwo} />
                        </div>

                        <div className={classNames(classes.board_middle_btns_container)}>
                            {
                                (props.role === "admin" && props.player_status === "waiting-for-players") ? (
                                    <PrimaryButton onClick={props.handler_onStartGame} > start game</PrimaryButton>
                                ) : (props.role === "player" && props.player_status === "waiting-for-players") ? (
                                    <BoddyOne color={customTheme.palette.neutrals.with} variant="body1" >Waiting for {props.players_state[0].nickname} start game</BoddyOne>
                                ) : (props.player_state.turn_to_roll && !props.player_state.end_turn && props.player_status === "game-started" && props.player_state.status !== "punished") ? (
                                    <CustomButton exteraStyle={{ margin: "0 4px" }} onClick={props.handler_onRollingDice} backgroundColor={customTheme.palette.yellow.main} backgroundColor_hovered={customTheme.palette.yellow.dark} disabled={props.loading ? true : false} >{props.loading ? 'Rolling...' : 'Roll Dice'}</CustomButton>
                                ) : (!props.player_state.turn_to_roll && props.player_status === "game-started") ? (
                                    props.players_state.map((item: any) => {
                                        if (item.is_turn) {
                                            return <BoddyOne color={customTheme.palette.neutrals.with} key={item.player_number} variant="body1" textTransform={"capitalize"} >{item.nickname} is playing</BoddyOne>
                                        }
                                        return null
                                    })
                                ) : (props.player_state.turn_to_roll && props.player_state.end_turn && props.player_status === "game-started") ? (
                                    <SecondaryButton onClick={props.handler_onEndTurn}>end turn</SecondaryButton>
                                ) : (props.player_state.status === "punished") ? (
                                    <>
                                        {(props.player_state.gift_cards > 0) && (
                                            <CustomButton exteraStyle={{ margin: "0 4px" }} onClick={props.handler_onUseGiftCard} backgroundColor={customTheme.palette.green.main} backgroundColor_hovered={customTheme.palette.green.dark} >use gift card{`*${props.player_state.gift_cards}`}</CustomButton>
                                        )}
                                        <CustomButton exteraStyle={{ margin: "0 4px" }} onClick={props.handler_onPayToken} backgroundColor={customTheme.palette.red.main} backgroundColor_hovered={customTheme.palette.red.dark} >pay 100</CustomButton>
                                        <PrimaryButton exteraStyle={{ margin: "0 4px" }} onClick={props.handler_onDobleRollingDice} >Roll Double</PrimaryButton>
                                    </>
                                ) : (props.player_status === "game-finished") ? (
                                    <>
                                        <PrimaryButton exteraStyle={{ margin: "0 4px" }} onClick={props.handler_OnPlayeAgain} >
                                            play again
                                        </PrimaryButton>
                                        <SecondaryButton exteraStyle={{ margin: "0 4px" }} onClick={props.handler_RedirectLobby}>
                                            back to lobby
                                        </SecondaryButton>
                                    </>
                                ) : (props.error_interaction_message.error === "You do not have enough money to buy" && props.player_status === "game-started") ? (
                                    <SecondaryButton onClick={props.handler_onEndTurn}>end turn</SecondaryButton>
                                )
                                    : ""
                            }
                        </div>
                        <div className={classNames(classes.board_middle_game_logo_container)}>
                            <img alt="logo" src="/images/BoardGameIcones/board_logo.svg" />
                        </div>
                        <div className={classNames(classes.board_middle_alert_container)} >
                            {
                                props.show_counter_down && props.player_status == "game-started" &&
                                <Alert
                                    severity="warning"
                                    sx={{
                                        color: customTheme.palette.accent_two.dark,
                                        width: "100%",
                                        my: 1
                                    }}
                                >
                                    {
                                        props.players_state.map((item: any) => {
                                            if (item.is_turn) {
                                                return <Caption color={customTheme.palette.accent_two.dark} variant="caption" key={item.player_number}>
                                                    {item.nickname} kicked out in {<CountDownTimer initialTime={110} />} if don't play it's turn.
                                                </Caption>
                                            }
                                            return null
                                        })
                                    }
                                </Alert>
                            }

                            {
                                props.show_counter_down && props.player_status == "waiting-for-players" &&
                                <Alert
                                    severity="warning"
                                    sx={{
                                        color: customTheme.palette.accent_two.dark,
                                        width: "100%",
                                        my: 1
                                    }}
                                >
                                    {
                                        <Caption variant="caption" sx={{ color: customTheme.palette.accent_two.dark }} >
                                            game finished in {<CountDownTimer initialTime={110} />} time if admin don't start game .
                                        </Caption>
                                    }
                                </Alert>
                            }

                            {
                                props.player_state.status === "rent_payed" && props.player_state.turn_to_roll && props.show_rent_payed_hired_gift
                                && <Alert
                                    severity="warning"
                                    sx={{
                                        color: customTheme.palette.accent_two.dark,
                                        width: "100%",
                                        my: 1
                                    }}
                                >
                                    <Caption variant="caption" sx={{ color: customTheme.palette.accent_two.dark }}>
                                        It was deducted from your tokens to pay the service cost.
                                    </Caption>
                                </Alert>
                            }
                            {
                                props.player_state.status === "hired_gift" && props.player_state.turn_to_roll && props.show_rent_payed_hired_gift
                                && <Alert
                                    severity="success"
                                    sx={{
                                        color: customTheme.palette.accent_three.dark,
                                        width: "100%",
                                        my: 1
                                    }}
                                >
                                    <Caption variant="caption" sx={{ color: customTheme.palette.accent_three.dark }}>
                                        You received 200 tokens by moved on the house.
                                    </Caption>
                                </Alert>
                            }
                            {
                                props.player_state.status === "punished" && props.player_state.turn_to_roll && props.player_state.location === 20
                                &&
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: [0, 1, 0] }}
                                    transition={{ duration: 1, repeat: Infinity }}
                                >
                                    <HackedComponent
                                        extera_style={{ width: "100%" }}
                                    >
                                        {props.hacked_alert}
                                    </HackedComponent>
                                </motion.div>
                            }
                            {
                                props.player_state.status === "punished" && props.player_state.turn_to_roll && props.player_state.location === 30
                                &&
                                <BudgetFreezeComponent
                                    extera_style={{ width: "100%" }}
                                >
                                    {props.hacked_alert}
                                </BudgetFreezeComponent>
                            }
                            {props.error_interaction_message.error && <AlertComponent extera_style={{ width: "100%" }}>
                                {props.error_interaction_message.error}
                            </AlertComponent>
                            }
                            {
                                props.services_text &&
                                <div className={classNames(classes.text_of_board_block)}>
                                    <BoddyTwo>
                                        {currentText}
                                    </BoddyTwo>
                                </div>
                            }

                        </div>
                    </div>
                </div>

                <div className={classNames(classes.right_container, classes.item)}>
                    <div className={classNames(classes.players_container, classes.box_item)}>
                        {props.players_state.map((player: any) => {
                            return <>
                                <motion.div className={classNames(classes.players_panel_item_container,
                                    player.status === "freezed" && player.location === 30 ?
                                        classes.player_freezed : "",
                                    player.is_turn && classes.border_is_turned
                                )} key={player.player_number}
                                    onClick={() => props.handler_onRemovedPlayer(player.player_number)}
                                >
                                    <div className={classNames(classes.player_panel_item)}>
                                        <img
                                            className={classNames(classes.player_panel_pawn)}
                                            alt={player.color}
                                            src={`/pawns/${player.color}_pawn.svg`} />
                                        <Caption variant="caption" sx={{ mx: 1, color: player.player_number === 1 ? customTheme.palette.accent_two.dark : customTheme.palette.neutrals.xxlligth }} textTransform={"capitalize"}>{player.nickname}</Caption>
                                    </div>
                                    {
                                        (player.status === "kicked_out" || player.money === 0) ?
                                            <div className={classNames(classes.player_money)}>
                                                <BoddyOne key={player.player_number} variant="body1" color={customTheme.palette.accent_one.dark} textTransform={"uppercase"}>BLOCKED</BoddyOne>
                                            </div>
                                            :
                                            <div className={classNames(classes.player_money, player.status === "freezed" && player.location === 10 ?
                                                classes.money_freezed : "")} key={player.player_number} >
                                                <Caption variant="caption" sx={{ mx: 1, color: customTheme.palette.neutrals.xxlligth }} textTransform={"capitalize"}>{player.money}</Caption>
                                                <img alt="price" src="/images/price/price.svg" />
                                                {
                                                    props.player_state.role == "admin" && player.player_number !== 1 &&
                                                    <button className={classNames(classes.removed_player)}>
                                                        <img src="/images/CloseIcon/close.svg" />
                                                    </button>
                                                }
                                            </div>
                                    }
                                </motion.div>
                            </>
                        })}
                    </div>
                    <div className={classNames(classes.game_rule_container, classes.box_item)} >
                        <HeaderSix sx={{ p: 1, width: "100%" }} color={customTheme.palette.primaryy.light} textAlign={"center"} textTransform={"capitalize"} variant="h6">how to play ?</HeaderSix>
                        <BlureBtn onClick={props.handler_OnhowToPlay} exteraStyle={{ width: "70%", textTransform: "uppercase" }}>
                            how to play?
                            <img style={{ marginLeft: "4px" }} src="/images/HowPlayeBtn/icon.svg" alt="icon" />
                        </BlureBtn>
                    </div>
                </div>
            </div>
        </>
    )
}