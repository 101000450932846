import classNames from "classnames";
import classes from "./expiererror.module.scss";
import { HeaderFiveTwo, HeaderThree } from "../../components/Headers/Headers";
import { customTheme } from "../../theme/muconfig";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons/Button";
import { useNavigate } from "react-router-dom";

interface IModel {
    text: string;
}

export const View = (props: IModel) => {
    const navigate = useNavigate()

    const handler_onGameStarted = () => {
        localStorage.removeItem('countdownTime');
        localStorage.removeItem('countdownTimestamp');
        localStorage.removeItem('playerInfo');
        navigate("/");
    }

    const handler_onGoToLobby = () => {
        localStorage.removeItem('countdownTime');
        localStorage.removeItem('countdownTimestamp');
        localStorage.removeItem('playerInfo');
        navigate("/lobby");
    }
    return <div className={classNames(classes.container)}>
        <img src="/images/Errors/expired.svg" alt="expired" />
        <HeaderThree sx={{ mt: 2, mb: 1 }} variant="h3" color={customTheme.palette.neutrals.with} textTransform={"uppercase"} >Expired Game room</HeaderThree>
        <HeaderFiveTwo sx={{ my: 1 }} variant="body1" color={customTheme.palette.accent_one.dark} textTransform={"capitalize"} >{props.text}</HeaderFiveTwo>
        <div>
            <PrimaryButton exteraStyle={{ margin: "8px" }} onClick={handler_onGameStarted}>another game room</PrimaryButton>
            <SecondaryButton exteraStyle={{ margin: "8px" }} onClick={handler_onGoToLobby}>go to lobby</SecondaryButton>
        </div>
    </div>
}