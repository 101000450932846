import { API, APIPrivate } from "../../../apis";
import { ISignInComandRequestModlel, IConfirmEmailComandRequestModlel, IConfrimIdentityComandRequestModlel, ISignupComandRequestModlel, IConfirmEmailForgotPassComandRequestModlel, IConfrimIdentityForgotPassComandRequestModlel, IForgotPassFormCommandModel, IAvatarSelectedComandRequestModel, IChangeEmailCommandRequestModel, IChangeGeneralUserInformationRequest } from "../../../models";

interface IAPICall {
  SignUp: Function;
  ConfrimEmail: Function;
  ConfrimIdentity: Function;
  SignIn: Function;
  ConfrimEmailForgotPass: Function;
  ConfrimIdentityForgotPass: Function;
  ForgotPass: Function;
  GetGeneralProfileInfo: Function;
  GetAvatarProfile: Function;
  SaveAvatarSelected: Function;
  ConfrimIdentityChangeEmailRequest: Function;
  ChangeEmailRequest:Function;
  ResendCodeChangeEmailRequest:Function;
  ChangeGeneralUserInformationRequest: Function;
}

export const apiclients = (): IAPICall => {

  //sign up
  const ConfrimEmail = async (api_request: IConfirmEmailComandRequestModlel) => {
        const response = await API.post("accounts/signup/email-token/", JSON.stringify(api_request), {
      headers: { "Content-Type": "application/json" },
      withCredentials: true
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw response; // Throw the response to handle errors in the higher layer
    }

  }

  const ConfrimIdentity = async (api_request: IConfrimIdentityComandRequestModlel) => {

    const response = await API.post("accounts/signup/player/step-1/", JSON.stringify(api_request), {
      headers: { "Content-Type": "application/json" },
      withCredentials: true
    });


    if (response.status === 201) {
      return response.data;
    } else {
      throw response; // Throw the response to handle errors in the higher layer
    }
  }

  const SignUp = async (api_request: ISignupComandRequestModlel) => {

    const response = await API.post("accounts/signup/player/step-2/", JSON.stringify(api_request), {
      headers: { "Content-Type": "application/json" },
      withCredentials: true
    });
    if (response.status === 201) {
      localStorage.setItem("authTokens", JSON.stringify(response.data));
      return response.data;
    } else {
      throw response; // Throw the response to handle errors in the higher layer
    }

  }

  //sign in
  const SignIn = async (api_request: ISignInComandRequestModlel) => {

    const response = await API.post("accounts/login/player/", JSON.stringify(api_request), {
      headers: { "Content-Type": "application/json" },
      withCredentials: true
    });
    localStorage.setItem("authTokens", JSON.stringify(response.data));
    if (response.status === 200) {
      return response.data;
    } else {
      throw response; // Throw the response to handle errors in the higher layer
    }

  }

  //forgot pass
  const ConfrimEmailForgotPass = async (api_request: IConfirmEmailForgotPassComandRequestModlel) => {

    const response = await API.post("accounts/forget-password/email-token/", JSON.stringify(api_request), {
      headers: { "Content-Type": "application/json" },
      withCredentials: true
    });

    if (response.status === 201) {
      return response.data;
    } else {
      throw response; // Throw the response to handle errors in the higher layer
    }

  }

  const ConfrimIdentityForgotPass = async (api_request: IConfrimIdentityForgotPassComandRequestModlel) => {
  
    const response = await API.post("accounts/forget-password/validate-token/", JSON.stringify(api_request), {
      headers: { "Content-Type": "application/json" },
      withCredentials: true
    });


    if (response.status === 200) {
      return response.data;
    } else {
      throw response; // Throw the response to handle errors in the higher layer
    }
  }

  const ForgotPass = async (api_request: IForgotPassFormCommandModel) => {
    const response = await API.post("accounts/forget-password/change-pass/", JSON.stringify(api_request), {
      headers: { "Content-Type": "application/json" },
      withCredentials: true
    });
    if (response.status === 200) {
      localStorage.setItem("authTokens", JSON.stringify(response.data));
      return response.data;
    } else {
      throw response; // Throw the response to handle errors in the higher layer
    }

  }

  //profile accunt

  const GetGeneralProfileInfo = async () => {
    try {
      const general_data = await APIPrivate.get("accounts/profile/update/");
      if (general_data.status == 200) {
        return {
          api_result: general_data.data,
        }
      }
    } catch (error) {
      throw error;
    }
  }

  const GetAvatarProfile = async () => {
    try {
      const avatar_data = await APIPrivate.get("accounts/avatar/");
      if (avatar_data.status == 200) {
        return {
          api_result: avatar_data.data,
        }
      }
    } catch (error) {
      throw error;
    }
  }

  const SaveAvatarSelected = async (api_request: IAvatarSelectedComandRequestModel) => {
    try {
      const avatar_data = await APIPrivate.post("accounts/avatar/", JSON.stringify(api_request));
      if (avatar_data.status == 200) {
        return {
          api_result: true,
        }
      }
    } catch (error) {
      throw error;
    }
  }


////change email 

  const ResendCodeChangeEmailRequest = async () => {
    try {
      const send_change_email_token = await APIPrivate.post("accounts/email/change/request/"); 
      if (send_change_email_token.status === 200) {
        return {
          api_result: true,
        }
      }
    } catch (error) {
      throw error;
    }
  }

  const ConfrimIdentityChangeEmailRequest = async (api_request: IChangeEmailCommandRequestModel) => {
    try {
      const change_email_token = await APIPrivate.post("accounts/email/change/token/", JSON.stringify(api_request));
      if (change_email_token.status === 200) {
        return {
          api_result: true,
        }
      }
    } catch (error) {
      throw error;
    }
  }

  const ChangeEmailRequest = async (api_request: IChangeEmailCommandRequestModel) => {
    try {
      const confrim_change_email = await APIPrivate.post("accounts/email/change/new-email/", JSON.stringify(api_request));
      if (confrim_change_email.status === 201) {
        return {
          api_result: true,
        }
      }
    } catch (error) {
      throw error;
    }
  }

  ///change general info

  const ChangeGeneralUserInformationRequest = async (api_request: IChangeGeneralUserInformationRequest) => {
    try {
      const change_user_general_info = await APIPrivate.put("accounts/profile/update/", JSON.stringify(api_request));
      if (change_user_general_info.status === 200) {
        return {
          api_result: true,
        }
      }
    } catch (error) {
      throw error;
    }
  }

  return {
    SignUp,
    ConfrimEmail,
    ConfrimIdentity,
    SignIn,
    ConfrimEmailForgotPass,
    ConfrimIdentityForgotPass,
    ForgotPass,
    GetGeneralProfileInfo,
    GetAvatarProfile,
    SaveAvatarSelected,
    ConfrimIdentityChangeEmailRequest,
    ChangeEmailRequest,
    ResendCodeChangeEmailRequest,
    ChangeGeneralUserInformationRequest
  };
};





