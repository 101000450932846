// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.changeemail_container__0nhlH {
  width: 468px;
}

@media (min-width: 320px) and (max-width: 429px) {
  .changeemail_container__0nhlH {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/module-accounts/views/ChangeEmailContent/changeemail.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAIA;EACI;IACI,WAAA;EADN;AACF","sourcesContent":[".container {\n    width: 468px;\n}\n\n///////////media query ///////\n\n@media (min-width:320px)and (max-width:429px) {\n    .container {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `changeemail_container__0nhlH`
};
export default ___CSS_LOADER_EXPORT___;
