import { Alert } from "@mui/material";
import { customTheme } from "../theme/muconfig";
import { Caption } from "./Headers/Headers";

interface IModel {
  children?: any;
  extera_style?: any;
}

const BudgetFreezeIcon = () => (
  <img src="/images/BudgetFreezeIcon/ice.svg" />
)

const HackedIcon = () => (
  <img src="/images/hackedAlert/hackerror.svg" />
)

export const AlertComponent = (props: IModel) => {
  return (
    <Alert
      sx={{
        color: customTheme.palette.accent_one.dark,
      }}
      severity="error"
      style={{ ...props.extera_style }}    >
      <Caption variant="caption" sx={{ color: customTheme.palette.accent_one.dark }}>
        {props.children}
      </Caption>
    </Alert>
  );
};

export const BudgetFreezeComponent = (props: IModel) => {
  return (
    <Alert
      sx={{
        background: "radial-gradient(50% 50% at 50% 50%, rgba(0, 117, 255, 0.00) 0%, rgba(44, 141, 255, 0.12) 0.01%, rgba(31, 134, 255, 0.40) 100%)",
        WebkitBackdropFilter: "blur(5px)", // For webkit browsers
        backdropFilter: "blur(5px)" // For non-webkit browsers
      }}
      icon={<BudgetFreezeIcon />}
    >
      <Caption variant="caption" sx={{ color: customTheme.palette.primaryy.light }}>
        {props.children}
      </Caption>
    </Alert>
  );
};

export const HackedComponent = (props: IModel) => {
  return (
    <Alert
      sx={{
        color: customTheme.palette.accent_one.dark,
      }}
      severity="error"
      style={{ ...props.extera_style }}
      icon={<HackedIcon />}
    >
      <Caption variant="caption" sx={{ color: customTheme.palette.accent_one.dark }}>
        {props.children}
      </Caption>
    </Alert>
  );
};