
import classNames from "classnames";
import classes from "./dilogBox.module.scss";
import { BoddyOne, BoddyTwo, Caption } from "../Headers/Headers";

interface IModel {
    type?: string;
    children?: any;
    whitch_bottom?: any;
}

export const BlockBoardDilogBoxLeft = (props: IModel) => {
    return <div className={classNames(classes.block_board_dilog_box, classes.block_board_dilog_box_parent_left)} >
        <div
            className={classNames(classes.block_board_dilog_box_border_rigth_left, classes.block_board_dilog_box_left_border)}
        ></div>
        <div
            className={classNames(classes.block_board_dilog_box_content,classes.chance_block)}
        >
            <BoddyTwo>
                {props.children}
            </BoddyTwo>
        </div>

    </div>
}

export const BlockBoardDilogBoxRight = (props: IModel) => {
    return <div className={classNames(classes.block_board_dilog_box, classes.block_board_dilog_box_parent_rigth)} >
        <div
            className={classNames(classes.block_board_dilog_box_content,classes.chance_block)}
        >
            <BoddyTwo>
                {props.children}
            </BoddyTwo>
        </div>
        <div
            className={classNames(classes.block_board_dilog_box_border_rigth_left, classes.block_board_dilog_box_rigth_border)}
        ></div>
    </div>
}

export const BlockBoardDilogBoxBottom = (props: IModel) => {
    return <div className={classNames(classes.block_board_dilog_box, classes.block_board_dilog_box_parent_upper_bottom, classes.block_board_dilog_box_parent_bottom)} >
        <div
            className={classNames(classes.block_board_dilog_box_content,classes.chance_block,
            )}
        >
            <BoddyTwo>
                {props.children}
            </BoddyTwo>
        </div>
        <div
            className={classNames(classes.block_board_dilog_box_border_bottom_upper, classes.block_board_dilog_box_bottom_border)}
        ></div>
    </div>
}

export const BlockBoardDilogBoxUpper = (props: IModel) => {

    return <div className={classNames(classes.block_board_dilog_box, classes.block_board_dilog_box_parent_upper_bottom, classes.block_board_dilog_box_parent_upper)}
    >
        <div
            className={classNames(classes.block_board_dilog_box_border_bottom_upper, classes.block_board_dilog_box_top_border)}
        ></div>
        <div
            className={classNames(classes.block_board_dilog_box_content,classes.chance_block)}
        ><BoddyTwo>
                {props.children}
            </BoddyTwo>
        </div>

    </div>
}

///////////////////////Repair/////////////////////////
export const RepairBlockBoardDilogBoxLeft = (props: IModel) => {
    return <div className={classNames(classes.block_board_dilog_box, classes.block_board_dilog_box_parent_left)} >
        <div
            className={classNames(classes.block_board_dilog_box_border_rigth_left, classes.block_board_dilog_box_left_border)}
        ></div>
        <div
            className={classNames(classes.repair_block_board_dilog_box_content,classes.repair_block)}
        >
            <BoddyTwo>
                {props.children}
            </BoddyTwo>
        </div>

    </div>
}

export const RepairBlockBoardDilogBoxRight = (props: IModel) => {
    return <div className={classNames(classes.block_board_dilog_box, classes.block_board_dilog_box_parent_rigth)} >
        <div
            className={classNames(classes.block_board_dilog_box_content,classes.repair_block)}
        >
            <BoddyTwo>
                {props.children}
            </BoddyTwo>
        </div>
        <div
            className={classNames(classes.block_board_dilog_box_border_rigth_left, classes.repair_block_board_dilog_box_rigth_border)}
        ></div>
    </div>
}

export const RepairBlockBoardDilogBoxBottom = (props: IModel) => {
    return <div className={classNames(classes.block_board_dilog_box, classes.block_board_dilog_box_parent_upper_bottom, classes.block_board_dilog_box_parent_bottom)} >
        <div
            className={classNames(classes.block_board_dilog_box_content,classes.repair_block,
            )}
        >
            <BoddyTwo>
                {props.children}
            </BoddyTwo>
        </div>
        <div
            className={classNames(classes.block_board_dilog_box_border_bottom_upper, classes.repair_block_board_dilog_box_bottom_border)}
        ></div>
    </div>
}

export const RepairBlockBoardDilogBoxUpper = (props: IModel) => {

    return <div className={classNames(classes.block_board_dilog_box, classes.block_board_dilog_box_parent_upper_bottom, classes.block_board_dilog_box_parent_upper)}
    >
        <div
            className={classNames(classes.block_board_dilog_box_border_bottom_upper, classes.repair_block_board_dilog_box_top_border)}
        ></div>
        <div
            className={classNames(classes.block_board_dilog_box_content,classes.repair_block)}
        ><BoddyTwo>
                {props.children}
            </BoddyTwo>
        </div>

    </div>
}


///////////////////////BuyServices/////////////////////////

export const BuyServicesBlockBoardDilogBoxLeft = (props: IModel) => {
    return <div className={classNames(classes.block_board_dilog_box, classes.block_board_dilog_box_parent_left)} >
        <div
            className={classNames(classes.block_board_dilog_box_border_rigth_left, classes.block_board_dilog_box_left_border)}
        ></div>
        <div
            className={classNames(classes.repair_block_board_dilog_box_content,classes.buy_services)}
        >
            <BoddyTwo>
                {props.children}
            </BoddyTwo>
        </div>

    </div>
}

export const BuyServicesBlockBoardDilogBoxRight = (props: IModel) => {
    return <div className={classNames(classes.block_board_dilog_box, classes.block_board_dilog_box_parent_rigth)} >
        <div
            className={classNames(classes.block_board_dilog_box_content,classes.buy_services)}
        >
            <BoddyTwo>
                {props.children}
            </BoddyTwo>
        </div>
        <div
            className={classNames(classes.block_board_dilog_box_border_rigth_left, classes.repair_block_board_dilog_box_rigth_border)}
        ></div>
    </div>
}

export const BuyServicesBlockBoardDilogBoxBottom = (props: IModel) => {
    return <div className={classNames(classes.block_board_dilog_box, classes.block_board_dilog_box_parent_upper_bottom, classes.block_board_dilog_box_parent_bottom)} >
        <div
            className={classNames(classes.block_board_dilog_box_content,classes.buy_services,
            )}
        >
            <BoddyTwo>
                {props.children}
            </BoddyTwo>
        </div>
        <div
            className={classNames(classes.block_board_dilog_box_border_bottom_upper, classes.repair_block_board_dilog_box_bottom_border)}
        ></div>
    </div>
}

export const BuyServicesBlockBoardDilogBoxUpper = (props: IModel) => {

    return <div className={classNames(classes.block_board_dilog_box, classes.block_board_dilog_box_parent_upper_bottom, classes.block_board_dilog_box_parent_upper)}
    >
        <div
            className={classNames(classes.block_board_dilog_box_border_bottom_upper, classes.repair_block_board_dilog_box_top_border)}
        ></div>
        <div
            className={classNames(classes.block_board_dilog_box_content,classes.buy_services)}
        ><BoddyTwo>
                {props.children}
            </BoddyTwo>
        </div>

    </div>
}