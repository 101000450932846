import { Typography, Card, FormControl, InputLabel, Input, Select, MenuItem, Button } from "@mui/material"
import { customTheme } from "../../../theme/muconfig";
import classes from "./editprofilestyle.module.scss";
import classNames from "classnames";
import { AlertComponent } from "../../../components/Error";
import { LinkComponent } from "../../../components/LinkButtonComponent";
import { RedirectLink } from "../../../components/RedirectLink";
import { PrimaryButton } from "../../../components/Buttons/Button";
import { BaseModalWrapper } from "../../../components/Modal/Modal";
import { BoddyOne, HeaderFivee, HeaderSix, HeaderThree } from "../../../components/Headers/Headers";
import { Header } from "../../../module-gamepages/views/HeaderContent";
import { useMediaQuery, useTheme } from '@mui/material';
import { Footer } from "../../../module-gamepages/views/FooterContent";
import { PickAvatarModule } from "../PickProfileContent";
import { ForgotPasswordModule } from "../ForgotPasswordContent";
import { ChangeEmailModule } from "../ChangeEmailContent";
import { GeneralErrorModule } from "../../../module-errores/GeneralErrorContent";

export const View = (props: any) => {
    const options: any = [
        { value: "linkedIn", label: "LinkedIn" },
        { value: "friends", label: "Friends" },
        { value: "Email or newsletter", label: "Email or Newsletter" },
        { value: "other", label: "Other" },
    ];
    return (
        <>
            <BaseModalWrapper show_modal={props.show_modal} currentPage={props.currentPage } onBackDropClick={props.handler_toggleModal} >
                {props.currentPage === 'change_email' && <ChangeEmailModule show_close_icon={true} email={props.initial_value.email} handler_toggleModal={props.handler_toggleModal} />}
                {props.currentPage === 'choose_avatar' && <PickAvatarModule handler_toggleModal={props.handler_toggleModal} show_close_icon={true} />}
                {props.currentPage === "change_password" && <ForgotPasswordModule handler_toggleModal={props.handler_toggleModal} show_close_icon={true} />}
                {props.currentPage === "error" && <GeneralErrorModule text_error={props.error_variable} />}
            </BaseModalWrapper>

            <div className={classNames(classes.container)}>
                <Header />
                <div className={classNames(classes.content, classes.row)}>
                    <HeaderThree variant="h3" sx={{ my: 5, color: customTheme.palette.primaryy.light, textTransform: "capitalize" }}>settings</HeaderThree>
                    <Card
                        sx={{
                            background: customTheme.palette.neutrals.background_blury_gradient,
                        }}
                        className={classNames(classes.main_edit_profile_item_basic_info_container)}
                    >
                        <div className={classNames(classes.main_edit_profile_item_basic_info)}>
                            <div onClick={() => props.handler_onChooseAvatar("choose_avatar")} className={classNames(classes.main_edit_profile_item_avatar,
                                props.profile_avatar === "green" ?
                                    classes.profile_avatar_shadow_green :
                                    props.profile_avatar === "red" ?
                                        classes.profile_avatar_shadow_red :
                                        props.profile_avatar === "yellow" ?
                                            classes.profile_avatar_shadow_yellow :
                                            props.profile_avatar === "red" ?
                                                classes.profile_avatar_shadow_red :
                                                props.profile_avatar === "blue" ?
                                                    classes.profile_avatar_shadow_blue :
                                                    props.profile_avatar === "purple" ?
                                                        classes.profile_avatar_shadow_purple : classes.profile_avatar_shadow_green
                            )}>
                                <img alt={`${props.profile_avatar}`} src={`/images/Avatars/${props.profile_avatar}.svg`} />
                            </div>
                            <div className={classNames(classes.main_edit_profile_item_info_content)}>
                                <HeaderFivee variant="h5" sx={{ mb: 2, textTransform: "capitalize" }}>
                                    {props.initial_value.fullname}
                                </HeaderFivee>
                                <HeaderSix variant="h6" sx={{ mb: 2, color: customTheme.palette.primaryy.light }}>
                                    {props.initial_value.email}
                                </HeaderSix>
                                <PrimaryButton onClick={() => props.handler_onChangePassword("change_password")} exteraStyle={{ minWidth: 100, }}>
                                    change password
                                </PrimaryButton>
                            </div>
                        </div>
                    </Card>
                    <Card
                        sx={{
                            background: customTheme.palette.neutrals.background_blury_gradient,
                        }}
                        className={classNames(classes.main_edit_profile_item_basic_info_container_two)}
                    >
                        <HeaderFivee variant="h5" sx={{ color: customTheme.palette.primaryy.light, textTransform: "capitalize", mb: 2 }}>
                            General information
                        </HeaderFivee>
                        <div className={classNames(classes.general_info_contents)}>
                            <div className={classNames(classes.general_info_form_container)}>
                                <FormControl
                                    sx={{ width: "100%", mt: 1, mb: 4 }}
                                    variant="standard"
                                    error={
                                        !!props.form_error.fullname && props.form_toutch.fullname
                                    }
                                >
                                    <InputLabel
                                        sx={{
                                            color: customTheme.palette.neutrals.mlight,
                                            textTransform: "capitalize",
                                        }}
                                        htmlFor="fullname"
                                    >
                                        full name
                                    </InputLabel>
                                    <Input
                                        sx={{
                                            color: customTheme.palette.neutrals.mlight,
                                            textTransform: "capitalize",
                                        }}
                                        onChange={(e) => props.handler_OnChange(e)}
                                        type="text"
                                        id="fullname"
                                        name="fullname"
                                        value={props.form_data.fullname}
                                    />
                                    {!!props.form_error.fullname &&
                                        props.form_toutch.fullname && (
                                            <AlertComponent>
                                                {props.form_error.fullname}
                                            </AlertComponent>
                                        )}
                                </FormControl>
                                <FormControl
                                    sx={{ width: "100%", my: 4 }}
                                    variant="standard"
                                    error={
                                        !!props.form_error.company_name && props.form_toutch.company_name
                                    }
                                >
                                    <InputLabel
                                        sx={{
                                            color: customTheme.palette.neutrals.mlight,
                                            textTransform: "capitalize",
                                        }}
                                        htmlFor="company_name"
                                    >
                                        company name
                                    </InputLabel>
                                    <Input
                                        sx={{
                                            color: customTheme.palette.neutrals.mlight,
                                            textTransform: "capitalize",
                                        }}
                                        onChange={(e) => props.handler_OnChange(e)}
                                        type="text"
                                        id="company_name"
                                        name="company_name"
                                        value={props.form_data.company_name}
                                    />
                                    {!!props.form_error.company_name &&
                                        props.form_toutch.company_name && (
                                            <AlertComponent>
                                                {props.form_error.company_name}
                                            </AlertComponent>
                                        )}
                                </FormControl>

                            </div>
                            <div className={classNames(classes.general_info_form_container)}>

                                <FormControl
                                    sx={{ width: "100%", my: 1 }}
                                    variant="standard"
                                    error={
                                        !!props.form_error.email && props.form_toutch.email
                                    }
                                >
                                    <InputLabel
                                        sx={{
                                            color: customTheme.palette.neutrals.mlight,
                                            textTransform: "capitalize",
                                        }}
                                        htmlFor="email"
                                    >
                                        email
                                    </InputLabel>
                                    <Input
                                        sx={{
                                            color: customTheme.palette.neutrals.mlight,
                                            textTransform: "capitalize",
                                        }}
                                        onChange={(e) => props.handler_OnChange(e)}
                                        type="text"
                                        id="email"
                                        name="email"
                                        value={props.form_data.email}
                                        disabled
                                    />
                                    {!!props.form_error.email &&
                                        props.form_toutch.email && (
                                            <AlertComponent>
                                                {props.form_error.email}
                                            </AlertComponent>
                                        )}
                                </FormControl>
                                <LinkComponent handler_onRegister={() => props.handler_onChangeEmail("change_email")}  >
                                    change email
                                </LinkComponent>
                                <FormControl
                                    sx={{ width: "100%", my: 1 }}
                                    variant="standard"
                                    error={!!props.form_error.how_did_you_found_us && props.form_toutch.how_did_you_found_us}
                                >
                                    <InputLabel
                                        id="how_did_you_found_us"
                                        sx={{
                                            p: 0,
                                            width: "100%",
                                            background: "none",
                                            color: customTheme.palette.neutrals.mlight,
                                        }}
                                    >
                                        How did you find us ?
                                    </InputLabel>
                                    <Select
                                        labelId="how_did_you_found_us"
                                        id="how_did_you_found_us"
                                        name="how_did_you_found_us"
                                        onChange={(e) => props.handler_OnChange(e)}
                                        value={props.form_data.how_did_you_found_us}
                                        sx={{
                                            p: 0,
                                            width: "100%",
                                            background: "none",
                                            fontSize: "small",
                                        }}
                                    >
                                        {options.map((option: any) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {!!props.form_error.how_did_you_found_us && props.form_toutch.how_did_you_found_us && (
                                        <AlertComponent>{props.form_error.how_did_you_found_us}</AlertComponent>
                                    )}
                                </FormControl>
                            </div>
                        </div>
                        <div className={classNames(classes.save_changes_content)}>
                            <PrimaryButton onClick={props.handler_OnSubmit}>
                                save changes
                            </PrimaryButton>
                        </div>

                    </Card>
                    <PrimaryButton onClick={() => props.handler_onBackToLobbyPage()} exteraStyle={{ margin: "40px 0" }}>
                        back to home
                    </PrimaryButton>

                </div>
                <Footer />
            </div>
        </>
    )
}