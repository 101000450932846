import { useFormik } from "formik";
import * as Yup from "yup";
import * as Constants from "../../../constants/constants";
import React, { useEffect, useState } from "react";
import { apiclients } from "../../serviceclients/apiclients";
import { useNavigate } from "react-router-dom";
import { ErrorResult } from "../../../module-errores/error/container";

export const useContainer = () => {
    let error_variable
    const initialFormState: any = {
        email: "",
        company_name: "",
        fullname: "",
        how_did_you_found_us: ""
    };
    const navigate = useNavigate();
    const [show_error, set_show_error] = React.useState("");
    const [currentPage, setCurrentPage] = useState('');
    const [show_modal, set_show_modal] = useState(false);
    const [profile_avatar, set_profile_avatar] = useState("");
    const [initial_value, set_initial_value] = useState({
        email: "",
        company_name: "",
        fullname: "",
        how_did_you_found_us: ""
    });
    //Modal
    const handler_toggleModal = () => {
        set_show_modal(prev => !prev);
    }
    const handler_onCurretPage = (props: any) => {
        setCurrentPage(props);
        set_show_modal(true)
    }

    useEffect(() => {
        apiclients().GetGeneralProfileInfo()
            .then((command_result: any) => {
                set_initial_value({
                    company_name: command_result.api_result.company_name,
                    fullname: command_result.api_result.fullname,
                    how_did_you_found_us: command_result.api_result.how_did_you_found_us,
                    email: command_result.api_result.email
                });
                formik.setValues({
                    company_name: command_result.api_result.company_name,
                    fullname: command_result.api_result.fullname,
                    how_did_you_found_us: command_result.api_result.how_did_you_found_us,
                    email: command_result.api_result.email
                });
            })
            .catch((error_result: any) => {
                console.log(error_result)
            })

        apiclients().GetAvatarProfile()
            .then((command_result: any) => {
                set_profile_avatar(command_result.api_result.avatar_color);
            }).catch((err: any) => {
                console.log(err)
            })
    }, []);

    // validation 

    const ConfrimSchema = Yup.object().shape({
        email: Yup.string()
            .matches(Constants.REGEX_EMAIL, "invalid email address")
            .required("this field is required"),
        company_name: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        fullname: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        how_did_you_found_us: Yup.string()
            .required("this field is required"),
    });

    const handler_OnChange = (e: any) => {
        const { name, value } = e.target;
        formik.setFieldValue(name, value);
    };

    const handler_OnSubmit = (values: any ,{ setErrors }: { setErrors: any }) => {
        let command_request = {
            fullname: values.fullname,
            company_name: values.company_name,
            how_did_you_found_us: values.how_did_you_found_us
        }
        apiclients().ChangeGeneralUserInformationRequest(command_request)
            .then((command_result: any) => {
                if (command_result.api_result) {
                    window.location.reload();
                }
            })
            .catch((error_result: any) => {
                let error_variable = ErrorResult(error_result)
                setErrors({
                    fullname: error_result,
                    company_name: error_result,
                    how_did_you_found_us: error_result
                })
                console.log("error_result", error_result)
            })
    };

    const formik = useFormik<any>({
        initialValues: initialFormState,
        validationSchema: ConfrimSchema,
        validateOnChange: true,
        onSubmit: (values,{ setErrors }) => handler_OnSubmit(values,{ setErrors })
    });

    const handler_onChooseAvatar = (props: any) => {
        handler_onCurretPage(props)
    }

    const handler_onChangePassword = (props: any) => {
        handler_onCurretPage(props)
    }

    const handler_onChangeEmail = (props: any) => {
        apiclients().ResendCodeChangeEmailRequest()
            .then((command_result: any) => {
                if (command_result.api_result) {
                    handler_onCurretPage(props)
                }
            })
            .catch((error_result: any) => {
                error_variable = ErrorResult(error_result)
                handler_onCurretPage("error")
            })
    }

    const handler_onBackToLobbyPage = () => {
        navigate("/");
    }

    return {
        show_error,
        form_data: formik.values,
        form_error: formik.errors,
        form_toutch: formik.touched,
        handler_OnSubmit: formik.handleSubmit,
        handler_OnChange,
        handler_toggleModal,
        currentPage,
        show_modal,
        handler_onCurretPage,
        profile_avatar,
        handler_onChooseAvatar,
        initial_value,
        handler_onChangePassword,
        handler_onChangeEmail,
        handler_onBackToLobbyPage,
        error_variable
    }
}