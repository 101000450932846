import { ButtonBase } from "@mui/material";
import { customTheme } from "../theme/muconfig";
interface IModel {
  children?: any;
  display?: any;
  handler_Action: () => void;
  justifyContent?: any;
  style?:any;
 extraStyles?:any
}

export const ButtonLinkComponent = (props: IModel) => {
  return (
    <div style={{ marginTop: "8px",marginBottom:"8px", display: !(props.display) ? "flex" : "none", justifyContent: props.justifyContent ? props.justifyContent : "center", width: "100%" ,...props.extraStyles}} >
      <ButtonBase
        style={{
          textTransform: "capitalize",
          background: "none",
          color: customTheme.palette.primaryy.main,
          fontFamily:"inherit",
          fontSize:"inherit"
        }}
        onClick={() => props.handler_Action()}
      >
        {props.children}
      </ButtonBase>
    </div>
  );
};