import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useContainer = () => {
    const [currentPage, setCurrentPage] = useState('');
    const [show_modal, set_show_modal] = useState(false);
    const navigate = useNavigate();
    const handler_onBackToStartPage = () => {
        navigate("/");
    }

    //Modal
    const handler_onRegister = (props: any) => {
        setCurrentPage(props);
    }

    const handler_toggleModal = () => {
        set_show_modal(prev => !prev);
    }

    const handler_onSignIn = () => {
        handler_onRegister("login");
        set_show_modal(true);
    }

    const handler_onVisitLinkdin = (type: string) => {
        if (type === "farzaneh") {
            window.open('https://www.linkedin.com/in/farzanehfallahpour/', '_blank');
        }else if(type === "azadeh") {
            window.open('https://www.linkedin.com/in/azadeh-haratian-nezhadi/?originalSubdomain=nl', '_blank');
        }else if(type === "asal") {
            window.open('https://www.linkedin.com/in/asal-rajabzade/?originalSubdomain=ir', '_blank');
        }else if(type === "alireza") {
            window.open('https://www.linkedin.com/in/alirezamortezaei/?originalSubdomain=ir', '_blank');
        }
    };

    return {
        handler_onBackToStartPage,
        handler_onRegister,
        handler_toggleModal,
        currentPage,
        show_modal,
        handler_onSignIn,
        handler_onVisitLinkdin
    }
}