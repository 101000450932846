import * as React from "react";
import { IModel, IFormDataModel, IConfrimEmailModel, IVerifyCodeModel, IUserInfoModel, IApiCallModel } from "./model";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Constants from "../../../constants/constants";
import * as Accounts from "../../viewmodels";
import { useNavigate, useLocation } from 'react-router-dom';
import { ErrorResult } from "../../../module-errores/error/container";

export const useContainer = (props: any): IModel => {
  let show_close_icon = props.show_close_icon;
  let handler_onRegister = props.handler_onRegister;
  let handler_toggleModal = props.handler_toggleModal;
  let is_redirected=props.is_redirected;
  const initial_form_state: IFormDataModel = {
    email: "",
    company_name: "",
    verify_code: "",
    full_name: "",
    password: "",
    repeat_password: "",
    find_us: "",
  }
  const [active_step, setactive_step] = React.useState(1);

  const [confrim_schema, set_confrim_schema] = React.useState(
    Yup.object().shape({})
  );
  const [show_password, setshow_password] = React.useState(false);
  const [show_repeat_password, setshow_repeat_password] = React.useState(false);
  const handler_ClickShowPassword = () => setshow_password((show) => !show);
  const handler_MouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handler_ClickShowRepeatPassword = () =>
    setshow_repeat_password((show) => !show);

  const handler_MouseDownRepeatPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const navigate = useNavigate();

  // Count Down
  const initial_time = 120; // Initial countdown time in seconds
  const [time_remaining, settime_remaining] = React.useState(0);

  // Convert seconds to a display format (e.g., "00:10")
  const handler_FormatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  React.useEffect(() => {
    // Function to update the timer every second
    const updateTimer = () => {
      if (time_remaining > 0) {
        settime_remaining(time_remaining - 1);
      }
    };

    // Set up an interval to call the updateTimer function every second
    const intervalId = setInterval(updateTimer, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [time_remaining]);

  React.useEffect(() => {
    if (active_step === 1) {
      set_confrim_schema(
        Yup.object().shape({
          email: Yup.string()
            .matches(Constants.REGEX_EMAIL, "invalid email address")
            .required(""),
          company_name: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required(""),
        })
      );
    } else if (active_step === 2) {
      set_confrim_schema(
        Yup.object().shape({
          verify_code: Yup.number()
            .typeError("Input must be a number") // Display this message if it's not a number
            .test(
              "is-six-digits",
              "Number must have exactly 6 digits",
              (value) => {
                if (value === undefined) return true; // Skip validation if the value is not provided
                const stringValue = value.toString(); // Convert the number to a string
                return stringValue.length === 6; // Check if it has 6 digits
              }
            )
            .required("Number is required"),

        })
      );
    } else if (active_step === 3) {
      set_confrim_schema(
        Yup.object().shape({
          full_name: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
          password: Yup.string()
            .test("weak-password", "the password is weak", (value?: string) => {
              if (!value) {
                return true;
              }
              let not_weak =
                Constants.REGEX_PASSWORD_STRONG.test(value) ||
                Constants.REGEX_PASSWORD_MEDIUM.test(value);
              return not_weak;
            })
            .max(Constants.PASSWORD_MAX, "password is too long")
            .min(Constants.PASSWORD_MIN, "password is too short")
            .required("this field is required"),

          repeat_password: Yup.string()
            .oneOf([Yup.ref("password")], "Passwords must match") // This checks if it matches the "password" field.
            .required("This field is required"),

          find_us: Yup.string().required("Required"),
        })
      );
    }
  }, [active_step]);

  const ApiCalles = (): IApiCallModel => {

    const ConfrimEmailAPI = (values: IConfrimEmailModel,
      { setErrors }: { setErrors: any }) => {
      confrim_schema.isValid(values)
        .then((is_valid) => {
          if (is_valid) {
            return Accounts.AccountFacade().ConfrimEmail(formik.values)
          } else {
            console.log("not valid values")
          }
        }).then((result: any) => {
          if (result.ok_response) {
            if (active_step === 1) {
              settime_remaining(initial_time);
              setactive_step((prevactive_step) => prevactive_step + 1);
            } else if (active_step === 2) {
              settime_remaining(initial_time);
            }
          } else {
            console.log(result)
          }
        }).catch((error: any) => {
          let error_variable = ErrorResult(error)
          if(error_variable==="server_error"){
            navigate("/servererror")
          }
          setErrors({ email: error_variable })
        })
    }

    const ResendCodeAPI = () => {
      Accounts.AccountFacade().ConfrimEmail(formik.values)
        .then((result: any) => {
          if (result.ok_response) {
            settime_remaining(initial_time);
          } else {
            console.log(result)
          }
        }).catch((error: any) => {
          let error_variable = ErrorResult(error)
          if(error_variable==="server_error"){
            navigate("/servererror")
          }
          console.log("An error occurred:", error_variable);
        })
    }

    const ConfrimIdentityAPI = (values: IVerifyCodeModel, { setErrors }: { setErrors: any }) => {
      confrim_schema.isValid(values).then((is_valid) => {
        if (is_valid) {
          return Accounts.AccountFacade().ConfrimIdentity(formik.values)
        } else {
          console.log("not valid values")
        }
      }).then((result: any) => {
        if (result.ok_response) {
          setactive_step((prevactive_step) => prevactive_step + 1);
        } else {
          console.log(result)
        }
      }).catch((error: any) => {
        let error_variable = ErrorResult(error)
        if(error_variable==="server_error"){
          navigate("/servererror")
        }
        setErrors({ verify_code: error_variable })
      })
    }

    const SignUpAPI = (values: IUserInfoModel, { setErrors }: { setErrors: any }) => {
      confrim_schema.isValid(values).then(
        (is_valid) => {
          if (is_valid) {
            return Accounts.AccountFacade().SignUp(formik.values)
          } else {
            console.log("not valid values")
          }
        })
        .then((result: any) => {
          if (result.ok_response) {
            if(is_redirected){
              navigate("/nickname", { replace: true });
            }else{
              window.location.reload();
            }
          } else {
            console.log(result)
          }
        }).catch((error: any) => {
          let error_variable = ErrorResult(error)
          if(error_variable==="server_error"){
            navigate("/servererror")
          }
          setErrors({
            password: error_variable,
            repeat_password: error_variable
          })
        })
    }

    return {
      SignUpAPI,
      ConfrimEmailAPI,
      ConfrimIdentityAPI,
      ResendCodeAPI,
    }
  }
  const handler_ResendCode = () => {
    ApiCalles().ResendCodeAPI();
  };

  const formik = useFormik<IFormDataModel>({
    initialValues: initial_form_state,
    validationSchema: confrim_schema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values: any, { setErrors }) => {
      if (active_step === 1) {
        ApiCalles().ConfrimEmailAPI(values, { setErrors });
      } else if (active_step === 2 && time_remaining > 0) {
        ApiCalles().ConfrimIdentityAPI(values, { setErrors });
      }
      else if (active_step === 3) {
        ApiCalles().SignUpAPI(values, { setErrors });
      }
    }
  });

  const handler_OnChange = (e: any) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };


  return {
    active_step,
    show_password,
    show_repeat_password,
    time_remaining,
    form_data: formik.values,
    form_error: formik.errors,
    form_toutch: formik.touched,
    handler_onRegister,
    handler_NextStep: formik.handleSubmit,
    handler_OnChange,
    handler_FormatTime,
    handler_ClickShowPassword,
    handler_ClickShowRepeatPassword,
    handler_MouseDownPassword,
    handler_MouseDownRepeatPassword,
    handler_ResendCode,
    handler_toggleModal,
    show_close_icon
  };
};
