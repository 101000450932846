import classNames from "classnames";
import classes from "./pagenotfound.module.scss";
import { BoddyTwo, HeaderFour, HeaderTwo } from "../../components/Headers/Headers";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons/Button";
import { customTheme } from "../../theme/muconfig";

export const View = (props: any) => {
    return <div className={classNames(classes.container)}>
        <div className={classNames(classes.item, classes.text_container)}>
            <HeaderTwo sx={{ my: 2 }} color={customTheme.palette.neutrals.with} variant="h2" >404 Error</HeaderTwo>
            <HeaderFour sx={{ my: 2 }} color={customTheme.palette.neutrals.with} variant="h4" >Oops.. page not found</HeaderFour>
            <BoddyTwo sx={{ my: 2 }} color={customTheme.palette.neutrals.with} variant="body2">You’ve reached the edges of our cyber universe, there’s nothing here for you.</BoddyTwo>
            <SecondaryButton exteraStyle={{ marginY: "16px" }} onClick={() => props.handler_onBacktoStart()}>Back to start</SecondaryButton>
        </div>
        <div className={classNames(classes.item, classes.img_container)}>
            <img src="/images/Errors/notfounderr.svg" />
        </div>
    </div>
}