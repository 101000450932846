import { useEffect, useState } from "react";

interface IModel{
    initialTime:number;
}

export const CountDownTimer = (props:IModel) => {
    const initialTime = props.initialTime; // Initial countdown time in seconds
    const [timeRemaining, setTimeRemaining] = useState<number>(initialTime);

    const formatTime = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    };

    useEffect(() => {
        const storedTime = localStorage.getItem('countdownTime');
        const storedTimestamp = localStorage.getItem('countdownTimestamp');

        if (storedTime && storedTimestamp) {
            const elapsedSeconds = Math.floor((Date.now() - parseInt(storedTimestamp, 10)) / 1000);
            const remaining = Math.max(parseInt(storedTime, 10) - elapsedSeconds, 0);
            setTimeRemaining(remaining);
        } else {
            setTimeRemaining(initialTime);
            localStorage.setItem('countdownTime', initialTime.toString());
            localStorage.setItem('countdownTimestamp', Date.now().toString());
        }
    }, [initialTime]);

    useEffect(() => {
        const updateTimer = () => {
            if (timeRemaining > 0) {
                setTimeRemaining((prevTime: number) => prevTime - 1);
            } else {
                localStorage.removeItem('countdownTime');
                localStorage.removeItem('countdownTimestamp');
            }
        };

        const intervalId = setInterval(updateTimer, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [timeRemaining]);

    return (
        <>{formatTime(timeRemaining)}</>
    );
}