import { Button, Link } from '@mui/material';

interface IModel {
    children?: any;
    extraStyles?: any;
    link?: any;
    exteraStyle?: any;
}

export const RedirectLink = (props: IModel) => {
    return (
        <Link style={{...props.exteraStyle}} href={props.link} underline="hover">
            {props.children}
        </Link>
    )
}