import { useFormik } from "formik";
import * as Yup from "yup";
import { IModel, IFormDataModel } from "./model";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import useWebSocket, { ReadyState } from 'react-use-websocket';
export const useContainer = (props: any) => {

  const params = useParams();
  const params_url = params.id; //room_id
  const navigate = useNavigate();
  const [is_clicked, set_is_clicked] = useState("");
  const [available_players, set_available_players] = useState([]);
  const [error_page, set_error_page] = useState("");
  const [show_modal, set_show_modal] = useState(false);

  localStorage.removeItem('player_money');

  const initialFormState = {
    nick_name: "",
    id: ""
  };

  const handler_OnChoosePlayer = (type: any) => {
    formik.setFieldValue('id', type);
    set_is_clicked(type)
  };

  const handler_toggleModal = () => {
    set_show_modal(prev => !prev);
  };

  const handler_onChange = (e: any) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };

  const url = `${process.env.REACT_APP_SOCKET_URL}join_game/${params_url}/`;
  const { sendMessage, lastMessage, readyState } = useWebSocket(url, {
    onOpen: () => {
      sendMessage(JSON.stringify({ "command": "validate_auth_token", "player_auth_token": "" }))
    },
  });

  const parsedMessage = useMemo(() => lastMessage ? JSON.parse(lastMessage.data) : {}, [lastMessage]);
  const player_auth_token = parsedMessage.new_auth_token;
  const available_colors = parsedMessage.available_colors;
  const error = parsedMessage.error;
  useEffect(() => {
    if (player_auth_token) {
      let obj = { "role": "player", "player_auth_token": player_auth_token, "room_id": params_url };
      localStorage.setItem("playerInfo", JSON.stringify(obj));
      props.set_show_nickname_page(false);
      window.location.reload();
      setTimeout(() => {
        navigate(`/board/${params_url}`, { replace: true });
      }, 1000);
    }
  }, [player_auth_token, navigate, params_url, props]);

  useEffect(() => {
    if (available_colors && available_colors !== available_players) {
      set_available_players(available_colors);
    }
  }, [available_colors, available_players]);

  useEffect(() => {
    if (error) {
      set_error_page(error);
      localStorage.removeItem('countdownTime');
      localStorage.removeItem('countdownTimestamp');
      localStorage.removeItem('playerInfo');
      localStorage.removeItem('player_money');
      handler_toggleModal();
    }
  }, [error]);

  const formik = useFormik({
    initialValues: initialFormState,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      nick_name: Yup.string()
        .min(2, "Too Short!")
        .max(15, "Too Long!")
        .required("Required"),
      id: Yup.string().required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const color = values.id.toString();
      const nick_name = values.nick_name.toString();
      sendMessage(JSON.stringify({ "command": "new_player_joining", "color": color, "nickname": nick_name }));
      resetForm();
    },
  });

  const handler_onSignIn = () => {
    set_show_modal(true);
  };

  return {
    form_data: formik.values,
    form_error: formik.errors,
    form_toutch: formik.touched,
    handler_OnChoosePlayer,
    handler_onChange,
    handler_onSubmit: formik.handleSubmit,
    is_clicked,
    available_players,
    error_page,
    handler_toggleModal,
    show_modal,
    handler_onSignIn
  };
};



