import { Button, List, ListItem, Typography } from "@mui/material";
import { customTheme } from "../../../theme/muconfig";
import { HowToPlaye } from "../HowPlayeContent";
import classes from "./lobbystyle.module.scss";
import { useRef } from "react";
import { BoddyOne, HeaderFivee, HeaderFour, HeaderSix, HeaderTwo } from "../../../components/Headers/Headers";
import { Header } from "../HeaderContent";
import { Footer } from "../FooterContent";
import classNames from "classnames";
import { BaseModalWrapper } from "../../../components/Modal/Modal";
import { ForgotPasswordModule, SignInModule, SignUpModule } from "../../../module-accounts/views";
import { BlureBtn } from "../../../components/Buttons/Button";

export const View = (props: any) => {
    const top = useRef(null);
    const information = useRef(null);
    const howPlay = useRef(null);
    const teamInfo = useRef(null);
    const contact_us = useRef(null);
    const scrollToSection = (elementRef: any) => {
        elementRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    return <>
        <BaseModalWrapper show_modal={props.show_modal} onBackDropClick={props.handler_toggleModal} >
            {props.currentPage === 'login' && <SignInModule show_close_icon={true} handler_toggleModal={props.handler_toggleModal} handler_onRegister={props.handler_onRegister} />}
            {props.currentPage === 'signUp' && <SignUpModule show_close_icon={true} handler_toggleModal={props.handler_toggleModal} handler_onRegister={props.handler_onRegister} />}
            {props.currentPage === 'forgotpass' && <ForgotPasswordModule show_close_icon={true} handler_toggleModal={props.handler_toggleModal} handler_onRegister={props.handler_onRegister} />}
        </BaseModalWrapper>
        <div className={classNames(classes.container)} ref={top}>
            <Header show_link={true} contact_us={contact_us} information={information} howPlay={howPlay} teamInfo={teamInfo} handler_onSignIn={props.handler_onSignIn} />
            <div className={classNames(classes.content, classes.row)} >
                <div className={classNames(classes.game_info_container)}>
                    <div className={classNames(classes.game_info_items, classes.text)} >
                        <HeaderTwo variant="h2" sx={{
                            fontSize: "3.5vw", my: 1, textTransform: "uppercase", color: customTheme.palette.primaryy.light
                        }}>PLAY & LEARN</HeaderTwo>
                        <HeaderSix variant="h6" sx={{ fontSize: "1.3vw", my: 1, color: customTheme.palette.neutrals.with }}>
                            Play the first ever online board game inspired by the principals of the NIST framework.
                        </HeaderSix>
                        <Button onClick={() => props.handler_onBackToStartPage()} size="large" sx={{ px: 2, my: 1, color: customTheme.palette.neutrals.with, backgroundColor: customTheme.palette.primaryy.main, textTransform: "uppercase", '&:hover': { backgroundColor: customTheme.palette.primaryy.dark } }}>let's play</Button>
                    </div>
                    <div className={classNames(classes.game_info_items, classes.image)}>
                        <div className={classNames(classes.game_info_item)} >
                            <img src="./images/lobbyimages/lobbysyber.png" alt="syber-image" />
                        </div>
                    </div>
                </div>
                <div className={classNames(classes.nist_info_container)} ref={information}>
                    <div className={classNames(classes.nist_info_items, classes.nist_info_items_images)}>
                        <img src="./images/lobbyimages/lobbycards.png" alt="syber-image" />
                    </div>
                    <div className={classNames(classes.nist_info_items, classes.nist_info_items_text)}>
                        <HeaderFour variant="h4" sx={{ my: 1, color: customTheme.palette.neutrals.xxxxllight, textTransform: "uppercase" }}>Explore the NIST framework</HeaderFour>
                        <BoddyOne variant="body1" sx={{ color: customTheme.palette.neutrals.xxxxllight }}>Cybersprint is tailored to empower non-security teams with essential cybersecurity knowledge. The NIST framework is designed by the National Institute of Standards and Technology to help organizations strengthen their cybersecurity posture. This game serves as an engaging and interactive tool for non-security personnel to grasp key concepts and practices in the realm of cybersecurity.</BoddyOne>
                        <HeaderFivee variant="h5" sx={{ my: 1, color: customTheme.palette.neutrals.xxxxllight, textTransform: "captalize" }}>Key features</HeaderFivee>
                        <List>
                            <ListItem>
                                <BoddyOne variant="body1" >
                                    &#8226;  Engage in a learning experience that turns cybersecurity training into an enjoyable game.
                                </BoddyOne>
                            </ListItem>
                            <ListItem>
                                <BoddyOne variant="body1" >
                                    &#8226;  Explore the core pillars of the NIST: Identify, Protect, Detect, Respond and Recover. Understand how each pillar contributes to a robust cybersecurity strategy.
                                </BoddyOne>
                            </ListItem>
                            <ListItem>
                                <BoddyOne variant="body1" >
                                    &#8226;  Enhance communication skills related to cybersecurity issues within a team setting.
                                </BoddyOne>
                            </ListItem>
                            <ListItem>
                                <BoddyOne variant="body1" >
                                    &#8226;  Develop a keen understanding of risk management in the context of cybersecurity.
                                </BoddyOne>
                            </ListItem>
                        </List>
                        <HeaderFivee variant="h5" sx={{ my: 1, color: customTheme.palette.neutrals.xxxxllight, textTransform: "captalize" }}>Key features</HeaderFivee>
                        <List>
                            <ListItem>
                                <BoddyOne variant="body1" >
                                    &#8226;  IT graduate
                                </BoddyOne>
                            </ListItem>
                            <ListItem>
                                <BoddyOne variant="body1" >
                                    &#8226;  Executive team
                                </BoddyOne>
                            </ListItem>
                            <ListItem>
                                <BoddyOne variant="body1" >
                                    &#8226;  Non-security team members
                                </BoddyOne>
                            </ListItem>
                            <ListItem>
                                <BoddyOne variant="body1" >
                                    &#8226;  Employees from various departments interested in cybersecurity awareness
                                </BoddyOne>
                            </ListItem>
                            <ListItem>
                                <BoddyOne variant="body1" >
                                    &#8226;  People who enjoy learning new concepts through gaming experience
                                </BoddyOne>
                            </ListItem>
                        </List>
                    </div>
                </div>
                <div className={classNames(classes.game_rule)} ref={howPlay}>
                    <HowToPlaye show_close_icon={false} />
                </div>
                <div className={classNames(classes.team_info)} ref={teamInfo}>
                    <HeaderFivee variant="h5" sx={{ my: 2, textAlign: "center", color: customTheme.palette.primaryy.light, textTransform: "capitalize" }}>our team</HeaderFivee>
                    <div className={classNames(classes.team_info_container)} >
                        <div className={classNames(classes.team_info_box_items)} >
                            <div className={classNames(classes.team_info_box_image)}>
                                <img alt="ui-img" src="./images/lobbyimages/ui.webp" />
                            </div>
                            <HeaderSix variant="h6" sx={{ my: 1, color: customTheme.palette.neutrals.xxxxllight, textTransform: "capitalize" }}>Asal Rajabzadeh</HeaderSix>
                            <BoddyOne variant="body1" sx={{ my: 1, color: customTheme.palette.neutrals.xxxxllight, textTransform: "capitalize" }}> Art & Visual UI/UX</BoddyOne>

                            <BlureBtn exteraStyle={{ margin: "8px 0" }} onClick={() => props.handler_onVisitLinkdin("asal")} >
                                visit
                                <img style={{ margin: "0 5px" }} src="./images/lobbyimages/visitlinkdin.svg" alt="visitlinkdin" />
                            </BlureBtn>
                        </div>
                        <div className={classNames(classes.team_info_box_items)} >
                            <div className={classNames(classes.team_info_box_image)}>
                                <img className={classNames(classes.team_info_box_image_tecnichal)} alt="tecnichal-img" src="./images/lobbyimages/techtical.webp" />
                            </div>
                            <HeaderSix variant="h6" sx={{ my: 1, color: customTheme.palette.neutrals.xxxxllight, textTransform: "capitalize" }}>farzaneh fallahpour</HeaderSix>
                            <BoddyOne variant="body1" sx={{ my: 1, color: customTheme.palette.neutrals.xxxxllight, textTransform: "capitalize" }}>project manager & Software Engineer </BoddyOne>

                            <BlureBtn exteraStyle={{ margin: "8px 0" }} onClick={() => props.handler_onVisitLinkdin("farzaneh")} >
                                visit
                                <img style={{ margin: "0 5px" }} src="./images/lobbyimages/visitlinkdin.svg" alt="visitlinkdin" />
                            </BlureBtn>
                        </div>
                        <div className={classNames(classes.team_info_box_items)} >
                            <div className={classNames(classes.team_info_box_image)}>
                                <img alt="creator-img" src="./images/lobbyimages/creator.webp" />
                            </div>
                            <HeaderSix variant="h6" sx={{ my: 1, color: customTheme.palette.neutrals.xxxxllight, textTransform: "capitalize" }}>Azadeh Haratiannezhadi</HeaderSix>
                            <BoddyOne variant="body1" sx={{ my: 1, color: customTheme.palette.neutrals.xxxxllight, textTransform: "capitalize" }}>Creator & Security advisor</BoddyOne>

                            <BlureBtn exteraStyle={{ margin: "8px 0" }} onClick={() => props.handler_onVisitLinkdin("azadeh")} >
                                visit
                                <img style={{ margin: "0 5px" }} src="./images/lobbyimages/visitlinkdin.svg" alt="visitlinkdin" />
                            </BlureBtn>
                        </div>
                        
                        <div className={classNames(classes.team_info_box_items)} >
                            <div className={classNames(classes.team_info_box_image)}>
                                <img alt="software-img" src="./images/lobbyimages/software.webp" />
                            </div>
                            <HeaderSix variant="h6" sx={{ my: 1, color: customTheme.palette.neutrals.xxxxllight, textTransform: "capitalize" }}>Alireza Mortezaei</HeaderSix>
                            <BoddyOne variant="body1" sx={{ my: 1, color: customTheme.palette.neutrals.xxxxllight, textTransform: "capitalize" }}>Software Engineer</BoddyOne>

                            <BlureBtn exteraStyle={{ margin: "8px 0" }} onClick={() => props.handler_onVisitLinkdin("alireza")} >
                                visit
                                <img style={{ margin: "0 5px" }} src="./images/lobbyimages/visitlinkdin.svg" alt="visitlinkdin" />
                            </BlureBtn>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={contact_us}>
                <Footer />
            </div>
            <div className={classNames(classes.back_top_btn)}>
                <button onClick={() => scrollToSection(top)}>
                    <img className={classNames(classes.back_top_btn_img)} src="/images/BackTopIcone/backtotop.svg" alt="top" />
                </button>
            </div>
        </div>

    </>

}

