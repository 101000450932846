// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.termandcondition_parent__H33nd {
  width: 100%;
}

.termandcondition_container__\\+ahEH {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto;
  padding: 16px;
}

.termandcondition_back_top_btn__7H5IH button {
  border: none;
  background: none;
  padding: 10px;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
  color: azure;
}

.termandcondition_back_top_btn__7H5IH img {
  border-radius: 50%;
}

.termandcondition_back__WNuOc {
  transition: 0.5s;
}`, "",{"version":3,"sources":["webpack://./src/module-gamepages/views/ConditionContent/termandcondition.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,sBAAA;EACA,gBAAA;EACA,cAAA;EACA,aAAA;AACJ;;AAEA;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,eAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":[".parent {\n    width: 100%;\n}\n\n.container{\n    display: flex;\n    justify-content: center;\n    align-items: start;\n    flex-direction: column;\n    max-width: 900px;\n    margin: 0 auto;\n    padding: 16px;\n}\n\n.back_top_btn button {\n    border: none;\n    background: none;\n    padding: 10px;\n    position: fixed;\n    right: 0;\n    bottom: 0;\n    z-index: 1;\n    cursor: pointer;\n    color: azure;\n}\n\n.back_top_btn img {\n    border-radius: 50%;\n}\n\n.back {\n    transition: 0.5s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parent": `termandcondition_parent__H33nd`,
	"container": `termandcondition_container__+ahEH`,
	"back_top_btn": `termandcondition_back_top_btn__7H5IH`,
	"back": `termandcondition_back__WNuOc`
};
export default ___CSS_LOADER_EXPORT___;
