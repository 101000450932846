import { Typography } from "@mui/material";
import { customTheme } from "../theme/muconfig";
import { BoddyOne } from "./Headers/Headers";
interface IModel {
    children?: any;
    extraStyles?: any;
}

export const Paragraph = (props: IModel) => {
    return (
        <BoddyOne sx={{ color: customTheme.palette.neutrals.mlight, ...props.extraStyles }} variant="body1">
            {props.children}
        </BoddyOne>
    );
};