import { useFormik } from "formik";
import * as Yup from "yup";
import * as Constants from "../../../constants/constants";
import * as Accounts from "../../viewmodels";
import { IModel, formDataModel } from "./model";
import React from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { ErrorResult } from "../../../module-errores/error/container";

export const useContainer = (props: any): IModel => {
  let is_redirected=props.is_redirected
  const navigate = useNavigate();
  let show_close_icon = props.show_close_icon;
  let handler_onRegister = props.handler_onRegister
  let currentPage = props.currentPage
  let handler_toggleModal = props.handler_toggleModal
  // show password
  const [show_password, set_show_password] = React.useState(false);
  const initialFormState: any = {
    email: "",
    password: "",
  };
  const handler_ClickShowPassword = () => set_show_password((show) => !show);

  const handler_MouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // validation 

  const ConfrimSchema = Yup.object().shape({
    email: Yup.string()
      .matches(Constants.REGEX_EMAIL, "invalid email address")
      .required("this field is required"),
    password: Yup.string()
      .test("weak-password", "the password is weak", (value?: string) => {
        if (!value) {
          return true;
        }
        let not_weak =
          Constants.REGEX_PASSWORD_STRONG.test(value) ||
          Constants.REGEX_PASSWORD_MEDIUM.test(value);
        return not_weak;
      })
      .max(Constants.PASSWORD_MAX, "password is too long")
      .min(Constants.PASSWORD_MIN, "password is too short")
      .required("this field is required"),
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };

  const formik = useFormik<any>({
    initialValues: initialFormState,
    validationSchema: ConfrimSchema,
    validateOnChange: true,
    onSubmit: (values, { setErrors }) => handleSubmit(values, { setErrors }),
  });

  const handleSubmit = (
    values: formDataModel,
    { setErrors }: { setErrors: any }
  ) => {
    ConfrimSchema.isValid(values)
      .then((is_valid) => {
        if (is_valid) {
          return Accounts.AccountFacade().SignIn(values);
        } else {
          throw new Error("Form is not valid");
        }
      })
      .then((result: any) => {
        if (result.ok_response) {
          if(is_redirected){
            navigate("/nickname", { replace: true });
          }else{
            window.location.reload();
          }
        } else {
          console.log(result);
        }
      })
      .catch((error) => {
        let error_variable = ErrorResult(error)
        if(error_variable==="server_error"){
          navigate("/servererror")
        }
        setErrors({ email: error_variable })
      })
  };


  return {
    form_error: formik.errors,
    form_toutch: formik.touched,
    onBlur: formik.handleBlur,
    handleSubmit: formik.handleSubmit,
    handleChange,
    form_data: formik.values,
    show_password,
    handler_ClickShowPassword,
    handler_MouseDownPassword,
    handler_onRegister,
    currentPage,
    handler_toggleModal,
    show_close_icon,
  };
};
