import { IModel } from "./model";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  MobileStepper,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { CardComponent } from "../../../components/Card";
import { CardHeader } from "../../../components/CardHeader";
import { CardContents } from "../../../components/CardContents";
import { customTheme } from "../../../theme/muconfig";
import { AlertComponent } from "../../../components/Error";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ButtonLinkComponent } from "../../../components/LinkButton";
import { Paragraph } from "../../../components/Paragraph";
import { LinkComponent } from "../../../components/LinkButtonComponent";
import { CardPrimeComponent } from "../../../components/CardPrime";
import { CardPrime } from "../../../components/CardPrime/view";
import classNames from "classnames";
import classes from "./singup.module.scss";


export const View = (props: IModel) => {
  const options: any = [
    { value: "linkedIn", label: "LinkedIn" },
    { value: "friends", label: "Friends" },
    { value: "Email or newsletter", label: "Email or Newsletter" },
    { value: "other", label: "Other" },
  ];
  return (
    <div className={classNames(classes.container)}>
      <CardPrime  handler_toggleModal={props.handler_toggleModal} show_close_icon={props.show_close_icon} step={props.active_step}>
        <CardHeader
          header={
            props.active_step === 1
              ? "Welcome To CyberSprint"
              : props.active_step === 2
                ? "Verify your email"
                : props.active_step === 3
                  ? "Almost There"
                  : ""
          }
          paragraph={
            props.active_step === 1
              ? "Get access to the game by completing the signup process and start your adventure!"
              : props.active_step === 2
                ? "We’ve sent a 6 digit code to your email. Please check your inbox or spam and insert the code."
                : props.active_step === 3
                  ? "Set up your account essentials and let the game begin."
                  : ""
          }
        />
        {props.active_step === 1 && (
          <>
            <FormControl
              sx={{
                color: customTheme.palette.neutrals.mlight,
                width: "100%",
                my: 1,
              }}
              variant="standard"
              error={
                !!props.form_error.company_name &&
                props.form_toutch.company_name
              }
            >
              <InputLabel
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                htmlFor="company_name"
              >
                Company Name
              </InputLabel>
              <Input
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                value={props.form_data.company_name}
                onChange={(e) => props.handler_OnChange(e)}
                type="text"
                id="company_name"
                name="company_name"
                aria-describedby="component-error-text"
              />
              {!!props.form_error.company_name &&
                props.form_toutch.company_name && (
                  <AlertComponent>
                    {props.form_error.company_name}
                  </AlertComponent>
                )}
            </FormControl>
            <FormControl
              sx={{ width: "100%", my: 1 }}
              variant="standard"
              error={!!props.form_error.email && props.form_toutch.email}
            >
              <InputLabel
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                htmlFor="email"
              >
                Email
              </InputLabel>
              <Input
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                onChange={(e) => props.handler_OnChange(e)}
                type="email"
                id="email"
                name="email"
              />
              {!!props.form_error.email && props.form_toutch.email && (
                <AlertComponent>{props.form_error.email}</AlertComponent>
              )}
            </FormControl>
          </>
        )}
        {props.active_step === 2 && (
          <>
            <FormControl
              sx={{ width: "100%", my: 1 }}
              variant="standard"
              error={
                !!props.form_error.verify_code &&
                props.form_toutch.verify_code
              }
            >
              <InputLabel
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                htmlFor="verify_code"
              >
                Verify Code
              </InputLabel>
              <Input
                placeholder="XXXXXX"
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                onChange={(e) => props.handler_OnChange(e)}
                type="text"
                id="verify_code"
                name="verify_code"
              />
              {!!props.form_error.verify_code &&
                props.form_toutch.verify_code && (
                  <AlertComponent>
                    {props.form_error.verify_code}
                  </AlertComponent>
                )}
            </FormControl>
            {props.time_remaining ? (
              <Paragraph extraStyles={{ margin: "8px" }}>{props.handler_FormatTime(props.time_remaining)}</Paragraph>
            ) : (<Paragraph extraStyles={{ margin: "8px", display: "none" }}>{props.handler_FormatTime(props.time_remaining)}</Paragraph>)}

            <ButtonLinkComponent justifyContent={"center"} display={props.time_remaining} handler_Action={props.handler_ResendCode}>
              Resend Code
            </ButtonLinkComponent>
            <Paragraph extraStyles={{ my: 1 }}>By continuing, you agree that we create an account for you</Paragraph>
          </>
        )}
        {props.active_step === 3 && (
          <>
            <FormControl
              sx={{ width: "100%", my: 1 }}
              variant="standard"
              error={
                !!props.form_error.full_name && props.form_toutch.full_name
              }
            >
              <InputLabel
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                htmlFor="full_name"
              >
                full name
              </InputLabel>
              <Input
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                onChange={(e) => props.handler_OnChange(e)}
                type="text"
                id="full_name"
                name="full_name"
              />
              {!!props.form_error.full_name &&
                props.form_toutch.full_name && (
                  <AlertComponent>
                    {props.form_error.full_name}
                  </AlertComponent>
                )}
            </FormControl>
            <FormControl
              sx={{ width: "100%", my: 1 }}
              variant="standard"
              error={
                !!props.form_error.password && props.form_toutch.password
              }
            >
              <InputLabel
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                htmlFor="password"
              >
                Password
              </InputLabel>
              <Input
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                }}
                onChange={(e) => props.handler_OnChange(e)}
                id="password"
                name="password"
                type={props.show_password ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={props.handler_ClickShowPassword}
                      onMouseDown={props.handler_MouseDownPassword}
                    >
                      {props.show_password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {!!props.form_error.password &&
                props.form_toutch.password && (
                  <AlertComponent>
                    {props.form_error.password}
                  </AlertComponent>
                )}
            </FormControl>
            <FormControl
              sx={{ width: "100%", my: 1 }}
              variant="standard"
              error={
                !!props.form_error.repeat_password &&
                props.form_toutch.repeat_password
              }
            >
              <InputLabel
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                htmlFor="repeat_password"
              >
                repeat password
              </InputLabel>
              <Input
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                }}
                onChange={(e) => props.handler_OnChange(e)}
                id="repeat_password"
                name="repeat_password"
                type={props.show_repeat_password ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={props.handler_ClickShowRepeatPassword}
                      onMouseDown={props.handler_MouseDownPassword}
                    >
                      {props.show_repeat_password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {!!props.form_error.repeat_password &&
                props.form_toutch.repeat_password && (
                  <AlertComponent>
                    {props.form_error.repeat_password}
                  </AlertComponent>
                )}
            </FormControl>
            <FormControl
              sx={{ width: "100%", my: 1 }}
              variant="standard"
              error={!!props.form_error.find_us && props.form_toutch.find_us}
            >
              <InputLabel
                id="find_us"
                sx={{
                  p: 0,
                  width: "100%",
                  background: "none",
                  color: customTheme.palette.neutrals.mlight,
                }}
              >
                How did you find us ?
              </InputLabel>
              <Select
                labelId="find_us"
                id="find_us"
                name="find_us"
                onChange={(e) => props.handler_OnChange(e)}
                value={props.form_data.find_us}
                sx={{
                  p: 0,
                  width: "100%",
                  background: "none",
                  fontSize: "small",
                }}
              >
                {options.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {!!props.form_error.find_us && props.form_toutch.find_us && (
                <AlertComponent>{props.form_error.find_us}</AlertComponent>
              )}
            </FormControl>
          </>
        )}
        <MobileStepper
          variant="progress"
          steps={4}
          activeStep={props.active_step}
          sx={{
            width: "100%",
            background: "none",
            position: "static",
            my: 1
          }}
          nextButton={
            <Button
              onClick={props.handler_NextStep}
              size="small"
              disabled={
                props.active_step === 1
                  ? !(props.form_data.company_name && props.form_data.email)
                  : props.active_step === 2
                    ? !(props.form_data.verify_code && props.time_remaining)
                    : props.active_step === 3
                      ? !(
                        props.form_data.full_name &&
                        props.form_data.password &&
                        props.form_data.repeat_password &&
                        props.form_data.find_us
                      )
                      : true
              }
            >
              done
            </Button>
          }
          backButton={
            <Button size="small" sx={{ visibility: "hidden" }}>
              Back
            </Button>
          }
        />
        <Grid container sx={{ alignItems: "center", justifyContent: "center", my: 1 }}>
          <Grid item sx={{ mx: 1 }}><Paragraph>Have an account ?</Paragraph></Grid>
          <Grid item sx={{ mx: 1 }}>
            <LinkComponent justifyContent={"end"} handler_onRegister={() => props.handler_onRegister("login")} >
              Sign In
            </LinkComponent>
          </Grid>
        </Grid>
      </CardPrime>
    </div>
  );

}