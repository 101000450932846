// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generalerror_container__BDudg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 87%;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/module-errores/GeneralErrorContent/generalerror.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;AACJ","sourcesContent":[".container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    max-height: 87%;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `generalerror_container__BDudg`
};
export default ___CSS_LOADER_EXPORT___;
