import { useLocation, useNavigate } from "react-router-dom";
import { IModel } from "./model";
import { apiclients } from "../../serviceclients"
import { useEffect, useState } from "react";
const board_game=[
    {
        row: 1,
        col: 1,
        type: 'block',
        content: { type: "start" }
    },
    {
        row: 1,
        col: 2,
        type: 'function',
        content: { type: "identify", header: "id.am", price: "700" }
    },
    {
        row: 1,
        col: 3,
        type: 'function',
        content: { type: "identify", header: "id.be", price: "250" }
    },
    {
        row: 1,
        col: 4,
        type: 'block',
        content: { type: "services", header: "buy services", price: "-50" }
    },
    {
        row: 1,
        col: 5,
        type: 'function',
        content: { type: "identify", header: "id.gv", price: "200" }
    },
    {
        row: 1,
        col: 6,
        type: 'block',
        content: { type: "repair", header: "repair", price: "-50" }
    },
    {
        row: 1,
        col: 7,
        type: 'block',
        content: { type: "mystery" }
    },
    {
        row: 1,
        col: 8,
        type: 'function',
        content: { type: "identify", header: "id.ra", price: "840" }
    },
    {
        row: 1,
        col: 9,
        type: 'function',
        content: { type: "identify", header: "id.rm", price: "150" }
    },
    {
        row: 1,
        col: 10,
        type: 'block',
        content: { type: "services", header: "buy services", price: "-50" }
    },
    {
        row: 1,
        col: 11,
        type: 'block',
        content: { type: "budget_freeze",header: "budget freeze" }
    },
    {
        row: 2,
        col: 11,
        type: 'function',
        content: { type: "identify", header: "id.sc", price: "300" }
    },
    {
        row: 3,
        col: 11,
        type: 'function',
        content: { type: "protect", header: "pr.ac", price: "1300" }
    },
    {
        row: 4,
        col: 11,
        type: 'function',
        content: { type: "protect", header: "pr.at", price: "350" }
    },
    {
        row: 5,
        col: 11,
        type: 'block',
        content: { type: "services", header: "buy services", price: "-50" }
    },
    {
        row: 6,
        col: 11,
        type: 'function',
        content: { type: "protect", header: "pr.ds", price: "1300" }
    },
    {
        row: 7,
        col: 11,
        type: 'function',
        content: { type: "protect", header: "pr.ip", price: "350" }
    },
    {
        row: 8,
        col: 11,
        type: 'block',
        content: { type: "repair", header: "repair", price: "-50" }
    },
    {
        row: 9,
        col: 11,
        type: 'function',
        content: { type: "protect", header: "pr.ma", price: "300" }
    },
    {
        row: 10,
        col: 11,
        type: 'block',
        content: { type: "mystery" }
    },
    {
        row: 11,
        col: 11,
        type: 'block',
        content: { type: "hacked" ,header:"hacked"}
    },
    {
        row: 11,
        col: 10,
        type: 'function',
        content: { type: "protect", header: "pr.pt", price: "1000" }
    },
    {
        row: 11,
        col: 9,
        type: 'function',
        content: { type: "detect", header: "de.ae", price: "450" }
    },
    {
        row: 11,
        col: 8,
        type: 'block',
        content: { type: "mystery" }
    },
    {
        row: 11,
        col: 7,
        type: 'function',
        content: { type: "detect", header: "de.cm", price: "850" }
    },
    {
        row: 11,
        col: 6,
        type: 'block',
        content: { type: "services", header: "buy services", price: "-150" }
    },
    {
        row: 11,
        col: 5,
        type: 'block',
        content: { type: "mystery" }
    },
    {
        row: 11,
        col: 4,
        type: 'function',
        content: { type: "detect", header: "de.dp", price: "400" }
    },
    {
        row: 11,
        col: 3,
        type: 'function',
        content: { type: "respond", header: "rs.rp", price: "50" }
    },
    {
        row: 11,
        col: 2,
        type: 'block',
        content: { type: "repair", header: "repair", price: "-50" }
    },
    {
        row: 11,
        col: 1,
        type: 'block',
        content: { type: "audit" ,header:"waiting for audit"}
    },
    {
        row: 10,
        col: 1,
        type: 'function',
        content: { type: "respond", header: "rs.co", price: "250" }
    },
    {
        row: 9,
        col: 1,
        type: 'function',
        content: { type: "respond", header: "rs.an", price: "500" }
    },
    {
        row: 8,
        col: 1,
        type: 'block',
        content: { type: "mystery" }
    },
    {
        row: 7,
        col: 1,
        type: 'function',
        content: { type: "respond", header: "rs.mi", price: "400" }
    },
    {
        row: 6,
        col: 1,
        type: 'function',
        content: { type: "respond", header: "rs.im", price: "200" }
    },
    {
        row: 5,
        col: 1,
        type: 'function',
        content: { type: "recover", header: "rc.rp", price: "50" }
    },
    {
        row: 4,
        col: 1,
        type: 'block',
        content: { type: "repair", header: "repair", price: "-100" }
    },
    {
        row: 3,
        col: 1,
        type: 'function',
        content: { type: "recover", header: "rc.im", price: "200" }
    },
    {
        row: 2,
        col: 1,
        type: 'function',
        content: { type: "recover", header: "rc.co", price: "300" }
    },
]

export const useContainer = (): IModel => {
    // Navigator
    const navigate = useNavigate();
    const [is_registered, set_is_registered] = useState(false);
    const [currentPage, setCurrentPage] = useState('startGame');
    const [show_modal, set_show_modal] = useState(false);
    const [profile_avatar, set_profile_avatar] = useState("");
    const [show_profile_tab, set_show_profile_tab] = useState(false);
    let is_redirected = true;


    //Modal
    const handler_onRegister = (props: any) => {
        setCurrentPage(props);
    }

    const handler_toggleModal = () => {
        set_show_modal(prev => !prev);
    }

    useEffect(() => {
        setTimeout(() => {
            set_show_modal(true);
            handler_onRegister("startGame")
        }, 2000)
    }, []);

    useEffect(() => {
        let validate_token = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')!) : null;
        if (!validate_token) {
            set_is_registered(false)
        } else {
            apiclients().CheckRegisterated(validate_token)
                .then((command_result: any) => {
                    if (command_result.api_result) {
                        set_is_registered(true)
                    } else {
                        set_is_registered(false)
                    }
                })

        }

    }, []);

    useEffect(() => {
        if (is_registered) {
            apiclients().GetAvatarProfile()
                .then((command_result: any) => {
                    set_profile_avatar(command_result.api_result.avatar_color)
                }).catch((err: any) => {
                    console.log(err)
                })
        }
    }, [is_registered])


    const handler_OnStartGame = async () => {
        const token = localStorage.getItem("authTokens");
        if (token) {
            apiclients().GameStart()
                .then((result: any) => {
                    if (result.api_result) {
                        navigate("/nickname", { replace: true });
                    } else if (!result.api_result) {
                        if (show_modal) {
                            handler_onRegister("login");
                        } else {
                            handler_onRegister("login");
                            set_show_modal(true);
                        }
                    }
                })

        } else {
            if (show_modal) {
                handler_onRegister("login");
            } else {
                handler_onRegister("login");
                set_show_modal(true);
            }
        }
    }
    const handler_onShowProfileSetting = () => {
        set_show_profile_tab(prev => !prev)
    }

    const handler_onSignIn = () => {
        handler_onRegister("login");
        set_show_modal(true);
    }

    const handler_OnhowToPlay = () => {
        set_show_modal(true);
        handler_onRegister("howtoplay");
    }

    const handler_RedirectLobby = () => {
        navigate("/lobby");
    }


    return {
        handler_OnStartGame,
        is_registered,
        show_modal,
        handler_toggleModal,
        currentPage,
        handler_onRegister,
        handler_onSignIn,
        handler_OnhowToPlay,
        handler_RedirectLobby,
        profile_avatar,
        handler_onShowProfileSetting,
        show_profile_tab,
        is_redirected,
        board_game
    }
}