export const ErrorResult = (err: any) => {
    if (err.response) {
        const status = err.response.status;

        if (status >= 400 && status < 500) {
            const res_err = err.response.data;
            return res_err.detail || (res_err[0] && res_err[0].detail) || 'Unknown error';
        } else if (status >= 500 && status < 600) {
            return 'Server error';
        }
    } else if (err.message) {
        return err.message;
    }

    return 'Unknown error';
}