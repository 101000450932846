import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {

  interface Palette {
    primaryy: {
      light_blurry: string;
      blurry: string;
      light: string;
      main: string;
      dark: string;
      ddark: string;
      background_gradient: string;
    };
    neutrals: {
      mdark: string;
      dark: string;
      main: string;
      light: string;
      mlight: string;
      lLigth: string;
      xlligth: string;
      xxlligth: string;
      xxxlligth: string;
      xxxxllight: string;
      with: string;
      blurry: string;
      background_blury_gradient: string;
    };
    accent_one: {
      dark: string;
      main: string;
      light: string;
    };
    accent_two: {
      dark: string;
      main: string;
      light: string;
    };
    accent_three: {
      dark: string;
      main: string;
      light: string;
    };
    accent_four: {
      ddark: string;
      dark: string;
      main: string;
      light: string;
    };
    green: {
      dark: string;
      main: string;
    };
    red: {
      dark: string;
      main: string;
    };
    blue: {
      dark: string;
      main: string;
    };
    yellow: {
      dark: string;
      main: string;
    };
    purple: {
      dark: string;
      main: string;
    };
    blure_green: {
      main: string,
      ligth: string
    };
    blure_accent_four: {
      main: string,
      ligth: string
    },
    blure_accent_one: {
      main: string,
      ligth: string
    },
    blure_accent_two: {
      main: string,
      ligth: string
    },
    blure_accent_three: {
      main: string,
      ligth: string
    },
  }



  interface PaletteOptions {
    primaryy?: {
      light_blurry: string;
      blurry: string;
      light: string;
      main: string;
      dark: string;
      ddark: string;
      background_gradient: string;
    };
    neutrals: {
      mdark: string;
      dark: string;
      main: string;
      light: string;
      mlight: string;
      lLigth: string;
      xlligth: string;
      xxlligth: string;
      xxxlligth: string;
      xxxxllight: string;
      with: string;
      blurry: string;
      background_blury_gradient: string;
    };
    accent_one: {
      dark: string;
      main: string;
      light: string;
    };
    accent_two: {
      dark: string;
      main: string;
      light: string;
    };
    accent_three: {
      dark: string;
      main: string;
      light: string;
    };
    accent_four: {
      ddark: string;
      dark: string;
      main: string;
      light: string;
    };
    green: {
      dark: string;
      main: string;
    };
    red: {
      dark: string;
      main: string;
    };
    blue: {
      dark: string;
      main: string;
    };
    yellow: {
      dark: string;
      main: string;
    };
    purple: {
      dark: string;
      main: string;
    };
    blure_green: {
      main: string,
      ligth: string
    };
    blure_accent_four: {
      main: string,
      ligth: string
    },
    blure_accent_one: {
      main: string,
      ligth: string
    },
    blure_accent_two: {
      main: string,
      ligth: string
    },
    blure_accent_three: {
      main: string,
      ligth: string
    },
  }

}

export const customTheme = createTheme({
  palette: {
    mode: "dark",
    primaryy: {
      light_blurry: "rgba(0, 129, 138, 0.20);",
      blurry: "rgba(0, 149, 162, 0.10)",
      light: "#ABEAEE",
      main: "#0095A2",
      dark: "#00818A",
      ddark: "#005E5F",
      background_gradient: "radial-gradient(51.49% 102.27% at 50.72% 100%, #0A2230 0%, #060A16 100%)",
    },
    neutrals: {
      mdark: "#0C0D14",
      dark: "#121320",
      main: "#1F2030",
      light: "#404153",
      mlight: "#9D9DB1",
      lLigth: "#BCBCD1",
      xlligth: "#DFDFF5",
      xxlligth: "#EDEDFF",
      xxxlligth: "#F3F3FF",
      xxxxllight: "#F8F8FF",
      with: "#FFF",
      blurry: "rgba(12, 13, 20, 0.80)",
      background_blury_gradient: "linear-gradient(0deg, rgba(9, 68, 101, 0.40) -44.04%, rgba(20, 48, 66, 0.00) 59.16%, rgba(18, 43, 60, 0.40) 156.1%)"
    },
    error: {
      main: "#FB4021",
    },
    accent_one: {
      dark: "#DB2A1D",
      main: "#FB4021",
      light: "#FFCCCF",
    },
    accent_two: {
      dark: "#FCA600",
      main: "#F8E707",
      light: "#FFF9C0",
    },
    accent_three: {
      dark: "#00711D",
      main: "#00B64B",
      light: "#B4F0CD",
    },
    accent_four: {
      ddark: "#59009C",
      dark: "#920E96",
      main: "#B300B8",
      light: "#E9B9E9",
    },
    green: {
      dark: "#089341",
      main: "#00B64B",
    },
    red: {
      dark: "#DB2A1D",
      main: "#FB4021",
    },
    blue: {
      dark: "#00818A",
      main: "#0095A2",
    },
    yellow: {
      dark: "#C68200",
      main: "#FCA600",
    },
    purple: {
      dark: "#920E96",
      main: "#B300B8",
    },
    blure_green: {
      main: "rgba(0, 149, 162, 0.10)",
      ligth: "rgba(0, 149, 162, 0.20)"
    },
    blure_accent_four: {
      main: "rgba(179, 0, 184, 0.10)",
      ligth: "rgba(179, 0, 184, 0.20)"
    },
    blure_accent_one: {
      main: "rgba(251, 64, 33, 0.10)",
      ligth: "rgba(251, 64, 33, 0.20)"
    },
    blure_accent_two: {
      main: "rgba(252, 166, 0, 0.10)",
      ligth: "rgba(252, 166, 0, 0.20)"
    },
    blure_accent_three: {
      main: "rgba(0, 182, 75, 0.10)",
      ligth: "rgba(0, 182, 75, 0.20)"
    },
  },
  typography: {
    h1: {
      fontFamily: "Kanit",
    },
    h2: {
      fontFamily: "Kanit",
    },
    h3: {
      fontFamily: "Kanit",
    },
    h4: {
      fontFamily: "Kanit",
    },
    h5: {
      fontFamily: "Kanit",
    },
    h6: {
      fontFamily: "Kanit",
    },
    subtitle1: {
      fontFamily: "Inter",
    },
    subtitle2: {
      fontFamily: "Inter",
    },
    body1: {
      fontFamily: "Inter",
    },
    body2: {
      fontFamily: "Inter",
    },
    button: {
      fontFamily: "Inter",
    },
    caption: {
      fontFamily: "Inter",
    },
    overline: {
      fontFamily: "Inter",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          border: 'none',
          transition: 'background-color 0.3s',
          backgroundColor: 'none',
          fontFamily: 'Inter',
          '&:hover': {
            backgroundColor: 'inherit',
          },
        },
      },
    },

  },
  breakpoints: {
    values: {
      xs: 430,
      sm: 744,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },

});
