import { RedirectLink } from "../../../components/RedirectLink";
import { customTheme } from "../../../theme/muconfig";
import { BoddyOne, HeaderSix } from "../../../components/Headers/Headers";
import classes from "./footerStyle.module.scss";
import classNames from "classnames";

export const View = (props: any) => {
    return <div className={classNames(classes.footer)} >
        <div className={classNames(classes.footer_container, classes.row)}>
            <div className={classNames(classes.footer_items)} >
                <div className={classNames(classes.footer_item)}>
                    <RedirectLink exteraStyle={{ padding: "8px 0", color: customTheme.palette.neutrals.xxxxllight }} children={"Terms & conditions"} link={"https://cybersprint.app/condition"} />
                    <RedirectLink exteraStyle={{ padding: "8px 0", color: customTheme.palette.neutrals.xxxxllight }} children={"Privacy & Policy"} link={"https://cybersprint.app/privacy"} />
                </div>
                <div className={classNames(classes.footer_item)}>
                    <RedirectLink exteraStyle={{ padding: "8px 0", color: customTheme.palette.neutrals.xxxxllight }} children={"About NIST"} link={"https://www.nist.gov/"} />
                    <BoddyOne variant="body1" sx={{ py: 1, color: customTheme.palette.neutrals.xxxxllight, textTransform: "captalize" }}> www.nist.gov</BoddyOne>

                </div>
                <div className={classNames(classes.footer_item)}>
                    <HeaderSix variant="h6" sx={{ py: 1, color: customTheme.palette.neutrals.xxxxllight, textTransform: "captalize" }}>Contact us</HeaderSix>
                    <BoddyOne variant="body1" sx={{ py: 1, color: customTheme.palette.neutrals.xxxxllight }}>
                        Azadeh.haratian@gmail.com
                    </BoddyOne>

                </div>
            </div>
            <div className={classNames(classes.footer_item)} >
                <BoddyOne variant="body1" sx={{ py: 1, color: customTheme.palette.neutrals.mlight }}>
                    © 2023 CyberSprint. All rights reserved.
                </BoddyOne>
            </div>
        </div>
    </div>
}