import { useNavigate } from "react-router-dom";

export const useContainer = (props:any) => {
    const navigate = useNavigate();

    const handler_onBacktoStart=()=>{
        localStorage.removeItem('countdownTime');
        localStorage.removeItem('countdownTimestamp');
        localStorage.removeItem('playerInfo');
        navigate("/");
    }
    return {
        handler_onBacktoStart
    }
}