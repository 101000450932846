import Button from '@mui/material/Button';
import classNames from 'classnames';
import classes from "./buttons.module.scss";

interface IModel {
    children?: any;
    exteraStyle?: any;
    size?: any;
    onClick?: () => void;
    fullWidth?: any;
    disabled?: any;
    backgroundColor?: any;
    backgroundColor_hovered?: any;
    refuse?: any;
}

export const PrimaryButton = (props: IModel) => {
    return (
        <Button
            disabled={props.disabled}
            style={{ ...props.exteraStyle }}
            onClick={props.onClick}
            sx={
                (customTheme) => ({
                    color: customTheme.palette.neutrals.with, backgroundColor: customTheme.palette.primaryy.main, textTransform: "uppercase", '&:hover': { backgroundColor: customTheme.palette.primaryy.dark },
                    [customTheme.breakpoints.down("sm")]: {
                        fontSize: "7px"
                    },
                    [customTheme.breakpoints.between("sm", "md")]: {
                        fontSize: "10px"
                    },
                })
            } size={props.size ? props.size : "medium"} variant="contained"
            fullWidth={props.fullWidth}
        >
            {props.children}
        </Button>
    );
};

export const SecondaryButton = (props: IModel) => {
    return (
        <Button
            disabled={props.disabled}
            style={{ ...props.exteraStyle }}
            sx={
                (customTheme) => ({
                    backgroundColor: customTheme.palette.accent_four.main, color: customTheme.palette.neutrals.with,
                    '&:hover': { backgroundColor: customTheme.palette.accent_four.dark },
                    [customTheme.breakpoints.down("sm")]: {
                        fontSize: "7px"
                    },
                    [customTheme.breakpoints.between("sm", "md")]: {
                        fontSize: "10px"
                    },
                })
            } size={props.size ? props.size : "medium"} variant="contained"
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    );
};

export const AgreeButton = (props: IModel) => {
    return (
        <Button
            disabled={props.disabled}
            style={{ ...props.exteraStyle }}
            sx={
                (customTheme) => ({
                    px: 2, mx: 2, border: `1px solid ${customTheme.palette.accent_three.main}`, background: customTheme.palette.blure_accent_three.main, color: customTheme.palette.accent_three.main, '&:hover': { border: `1px solid ${customTheme.palette.accent_three.main}`, background: customTheme.palette.blure_accent_three.ligth },
                    [customTheme.breakpoints.down("sm")]: {
                        fontSize: "10px"
                    },
                    [customTheme.breakpoints.between("sm", "md")]: {
                        fontSize: "10px"
                    },
                })
            } size={props.size ? props.size : "medium"} variant="outlined"
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    );
};

export const DisAgreeButton = (props: IModel) => {
    return (
        <Button
            disabled={props.disabled}
            style={{ ...props.exteraStyle }}
            className={classNames(props.refuse === 1 && classes.shaked_button)}
            sx={
                (customTheme) => ({
                    px: 2, mx: 2, border: `1px solid ${customTheme.palette.accent_one.main}`, background: customTheme.palette.blure_accent_one.main, color: customTheme.palette.accent_one.main, '&:hover': { border: `1px solid ${customTheme.palette.accent_one.main}`, background: customTheme.palette.blure_accent_one.ligth },
                    [customTheme.breakpoints.down("sm")]: {
                        fontSize: "10px"
                    },
                    [customTheme.breakpoints.between("sm", "md")]: {
                        fontSize: "10px"
                    },
                })
            } size={props.size ? props.size : "medium"} variant="outlined"
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    );
};

export const BlureBtn = (props: IModel) => {
    return <Button
        disabled={props.disabled}
        size="medium" variant="outlined"
        style={{ ...props.exteraStyle }}
        onClick={props.onClick}

        sx={
            (customTheme) => ({
                fontSize: "12px", textTransform: "capitalize", color: customTheme.palette.primaryy.main, background: "rgba(0, 149, 162, 0.10)", border: `1px solid ${customTheme.palette.primaryy.main}`, '&:hover': { border: `1px solid ${customTheme.palette.primaryy.main}`, background: "rgba(0, 149, 162, 0.20)" },
                [customTheme.breakpoints.down("sm")]: {
                    fontSize: "7px",
                },
                [customTheme.breakpoints.down("md")]: {
                    fontSize: "10px"
                },
            })
        }
    >
        {props.children}
    </Button>
}

export const CustomButton = (props: IModel) => {
    return (
        <Button
            disabled={props.disabled}
            style={{ ...props.exteraStyle }}
            onClick={props.onClick}
            sx={
                (customTheme) => ({
                    color: customTheme.palette.neutrals.with, backgroundColor: props.backgroundColor, textTransform: "uppercase", '&:hover': { backgroundColor: props.backgroundColor_hovered },
                    [customTheme.breakpoints.down("sm")]: {
                        fontSize: "7px"
                    },
                    [customTheme.breakpoints.between("sm", "md")]: {
                        fontSize: "10px"
                    },
                })
            } size={props.size ? props.size : "medium"} variant="contained"
            fullWidth={props.fullWidth}
        >
            {props.children}
        </Button>
    );
};