import { useEffect, useState } from "react"
import { apiclients } from "../../serviceclients/apiclients"
import { useNavigate } from "react-router-dom";
import { IModel } from "./model";

export const useContainer = (props: IModel) => {
    const validate_token = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')!) : null;
    const [profile_fullname, set_profile_fullname] = useState("");
    const [profile_avatar, set_profile_avatar] = useState("");
    const navigate = useNavigate();


    useEffect(() => {
        apiclients().GetGeneralProfileInfo()
            .then((command_result: any) => {
                let fullname = command_result.api_result.fullname.split(" ").length > 3 && command_result.api_result.fullname.split("")[0] + command_result.api_result.fullname.split("")[1] <= 25 ? command_result.api_result.fullname.split("")[0] + command_result.api_result.fullname.split("")[1] : `${command_result.api_result.fullname.split(" ")[0]}...`;
                set_profile_fullname(fullname);
            })
            .catch((error_result: any) => {
                console.log(error_result)
            })

        apiclients().GetAvatarProfile()
            .then((command_result: any) => {
                set_profile_avatar(command_result.api_result.avatar_color);
            }).catch((err: any) => {
                console.log(err)
            })
    }, []);

    const handler_onRedirectProfileSettingPage = () => {
        navigate("/editprofile");
    }

    const handler_onSignOut = () => {
        if (validate_token) {
            localStorage.removeItem('authTokens');
            window.location.reload();
        }
    }

    let show_profile_tab = props.show_profile_tab
    let extera_style = props.extera_style

    return {
        profile_fullname,
        handler_onRedirectProfileSettingPage,
        handler_onSignOut,
        show_profile_tab,
        profile_avatar,
        extera_style
    }
}