import { Fragment, useCallback, useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import { BoardModule } from "./module-board/views";
import { PrivateRoute } from "./utils/PrivateRoute";
import { GameStartModule, SetNickNameModule } from "./module-gamepages/views";
import { SetNickNameSocket } from "./module-gamepages/views/SetNicknameSocket";
import { LobbyModule } from "./module-gamepages/views/LobbyContent";
import { EditeProfileModule } from "./module-accounts/views/EditeProfileContent";
import { PageNotFoundComponent } from "./module-errores/PageNotFoundComponent";
import { ServerDownComponent } from "./module-errores/ServerDownComponent/view";
import { ConditionModule } from "./module-gamepages/views/ConditionContent";
import { PrivacyModule } from "./module-gamepages/views/PrivacyContent";

function App() {

  let player_info = localStorage.getItem("playerInfo") ? true : false;
  const [show_nickname_page, set_show_nickname_page] = useState(true);
  const Elements = () => {
    return (
      <>
        {(show_nickname_page && !player_info) ? (
          <SetNickNameSocket set_show_nickname_page={set_show_nickname_page} />
        ) : (
          <BoardModule set_show_nickname_page={set_show_nickname_page} />
        )}
      </>
    );
  };



  return (
    <Fragment>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/nickname" element={<SetNickNameModule set_show_nickname_page={set_show_nickname_page} />} />
          <Route path='/editprofile' element={<EditeProfileModule />} />
        </Route>
        <Route path='/' element={<GameStartModule />} />
        <Route path='/board/:id' element={<Elements />} />
        <Route path='/lobby' element={<LobbyModule />} />
        <Route path='/servererror' element={<ServerDownComponent />} />
        <Route path='/condition' element={<ConditionModule />} />
        <Route path='/privacy' element={<PrivacyModule />} />
        <Route
          path="*"
          element={<PageNotFoundComponent />}
        />
      </Routes>
    </Fragment>
  );
}

export default App;

