import classNames from "classnames";
import classes from "./termandcondition.module.scss";
import { Header } from "../HeaderContent";
import { Footer } from "../FooterContent";
import { useRef } from "react";
import { BoddyOne, HeaderFiveTwo, HeaderFour, HeaderSix } from "../../../components/Headers/Headers";
import { List, ListItem } from "@mui/material";
import { customTheme } from "../../../theme/muconfig";
import { PrimaryButton } from "../../../components/Buttons/Button";

export const View = (props: any) => {
    const top = useRef(null);
    const scrollToSection = (elementRef: any) => {
        elementRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    return <div className={classNames(classes.parent)} ref={top}>
        <Header show_link={false} not_show_signin_btn={true} />
        <div className={classNames(classes.container)}>
            <HeaderFour variant="h4" color={customTheme.palette.neutrals.xxxxllight}>
                Terms and Conditions for CyberSprint Online Educational Game
            </HeaderFour>
            <BoddyOne color={customTheme.palette.neutrals.xxxxllight} sx={{ py: 2 }}>
                Welcome to CyberSprint! Please read these Terms and Conditions ("Terms") carefully before using the CyberSprint online educational game ("Service") operated by Cyber Tech Studio ("us", "we", or "our").
            </BoddyOne>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                1. Agreement to Terms
            </HeaderFiveTwo>
            <BoddyOne color={customTheme.palette.neutrals.xxxxllight} sx={{ py: 2 }}>
                By accessing or using our Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you do not have permission to access the Service
            </BoddyOne>

            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                2. Intellectual Property
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                The Service and its original content, features, and functionality are and will remain the exclusive property of Cyber Tech Studio and its licensors. The content is provided to you is for your information and personal use only and may not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of Cyber Tech Studio.
            </BoddyOne>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                3. User Accounts
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                When you create an account with us, you must provide information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.
                You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.
            </BoddyOne>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                4. Access and Use
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                You are granted a limited, non-exclusive, revocable license to access and use the Service strictly in accordance with these Terms. As a condition of your use of the Service, you warrant to Cyber Tech Studio that you will not use the Service for any purpose that is unlawful or prohibited by these Terms
            </BoddyOne>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                5. Modifications to Service
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                Cyber Tech Studio reserves the right at any time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that Cyber Tech Studio shall not be liable to you or to any third party for any modification, suspension, or discontinuance of the Service.
            </BoddyOne>

            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                6. Termination
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Service will immediately cease.
            </BoddyOne>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                7. Links to Other Web Sites
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                Our Service may contain links to third-party web sites or services that are not owned or controlled by Cyber Tech Studio Cyber Tech Studio has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services.
            </BoddyOne>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                8. Limitation of Liability
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                In no event shall Cyber Tech Studio, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage.
            </BoddyOne>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                9. Limitation of Liability
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                By using our Service, you acknowledge that you have read and understood this privacy policy and agree to its terms.
            </BoddyOne>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                10. Changes
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion
            </BoddyOne>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                11. Contact Us
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                If you have any questions about these Terms, please contact us at azadeh.haratian@gmail.com.
            </BoddyOne>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                By using CyberSprint, you acknowledge that you have read these terms and agree to be bound by them.
            </BoddyOne>

            <PrimaryButton onClick={() => props.handler_onBackToLobbyPage()} exteraStyle={{ margin: "32px 0px" }} >
                Back to lobby
            </PrimaryButton>
        </div>
        <Footer />
        <div className={classNames(classes.back_top_btn)}>
            <button onClick={() => scrollToSection(top)}>
                <img className={classNames(classes.back_top_btn_img)} src="/images/BackTopIcone/backtotop.svg" alt="top" />
            </button>
        </div>
    </div>
}