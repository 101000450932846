import { Link } from 'react-router-dom';
import { customTheme } from "../theme/muconfig";
import { Button } from '@mui/material';

interface IModel {
    children?: any;
    extraStyles?: any;
    link?: any;
    justifyContent?: any;
    handler_onRegister?: any;
    currentPage?: any;
    value?:any
}


export const LinkComponent = (props: IModel) => {
    return (
        <div style={{
            marginTop: "8px", marginBottom: "8px", width: "100%", ...props.extraStyles,
            display: "flex", justifyContent: props.justifyContent ? props.justifyContent : "center"
        }} >
            <Button
                style={{
                    textTransform: "capitalize",
                    color: customTheme.palette.primaryy.main,
                    fontFamily: "inherit",
                    fontSize: "inherit"
                }}
                onClick={props.handler_onRegister}
            >
                {props.children}
            </Button>
        </div>
    );
};