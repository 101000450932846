import * as Accounts from "../serviceclients";
import { IConfrimEmailResponseModel, ISignInComandRequestModlel, IAccuntFormCommandModel, IConfirmEmailComandRequestModlel, IConfrimIdentityComandRequestModlel, ISignupComandRequestModlel, ISignIntFormCommandModel, IForgotPassFormCommandModel, IConfirmEmailForgotPassComandRequestModlel, IConfrimEmailForgotPassResponseModel, IConfrimIdentityForgotPassComandRequestModlel, IConfrimIdentityResponseModel, IForgotPassComandRequestModlel, IForgotPassResponseModel } from "../../models";

interface IAccountFacade {
  SignUp: Function;
  ConfrimEmail: Function;
  ConfrimIdentity: Function;
  SignIn: Function;
  ConfrimEmailForgotPass:Function;
  ConfrimIdentityForgotPass:Function;
  ForgotPass:Function;
}

export const AccountFacade = (): IAccountFacade => {

  //sign up
  const ConfrimEmail = async (command_params: IAccuntFormCommandModel) => {
    const command_request: IConfirmEmailComandRequestModlel = {
      email: command_params.email!
    }
    try {
      const api_result = await Accounts.ServiceClient().ConfrimEmail(command_request);

      const api_response: IConfrimEmailResponseModel = {
        ok_response: true
      }
      return api_response;
    } catch (err_result: any) {
      throw err_result
    }
  };



  const ConfrimIdentity = async (command_params: IAccuntFormCommandModel) => {
    const command_request: IConfrimIdentityComandRequestModlel = {
      email: command_params.email!,
      company_name: command_params.company_name!,
      auth_token: command_params.verify_code!
    }
    try {
      const api_result = await Accounts.ServiceClient().ConfrimIdentity(command_request);
      const api_response: IConfrimEmailResponseModel = {
        ok_response: true
      }
      return api_response;
    } catch (err_result: any) {
      throw err_result
    }

  };

  const SignUp = async (command_params: IAccuntFormCommandModel) => {
    const command_request: ISignupComandRequestModlel = {
      email: command_params.email!,
      company_name: command_params.company_name!,
      fullname: command_params.full_name!,
      password: command_params.password!,
      password2: command_params.repeat_password!,
      how_did_you_found_us: command_params.find_us!,
    }

    try {
      const api_result = await Accounts.ServiceClient().SignUp(command_request);

      const api_response: IConfrimEmailResponseModel = {
        ok_response: true
      }
      return api_response;
    } catch (err_result: any) {
      throw err_result
    }

  };

  //signin
  const SignIn = async (command_params: ISignIntFormCommandModel) => {
    const command_request: ISignInComandRequestModlel = {
      email: command_params.email!,
      password: command_params.password!
    }
    try {
      const api_result = await Accounts.ServiceClient().SignIn(command_request);
      const api_response: IConfrimEmailResponseModel = {
        ok_response: true
      }
      return api_response;
    } catch (err_result: any) {
      throw err_result
    }

  };

  //forgotpass
  const ConfrimEmailForgotPass = async (command_params: IForgotPassFormCommandModel) => {
    const command_request: IConfirmEmailForgotPassComandRequestModlel = {
      email: command_params.email!
    }
    try {
      const api_result = await Accounts.ServiceClient().ConfrimEmailForgotPass(command_request);

      const api_response: IConfrimEmailForgotPassResponseModel = {
        ok_response: true
      }
      return api_response;
    } catch (err_result: any) {
      throw err_result
    }
  };



  const ConfrimIdentityForgotPass = async (command_params: IForgotPassFormCommandModel) => {
    const command_request: IConfrimIdentityForgotPassComandRequestModlel = {
      email: command_params.email!,
      token: command_params.verify_code!
    }
    try {
      const api_result = await Accounts.ServiceClient().ConfrimIdentityForgotPass(command_request);
      const api_response: IConfrimIdentityResponseModel = {
        ok_response: true
      }
      return api_response;
    } catch (err_result: any) {
      throw err_result
    }

  };

  const ForgotPass = async (command_params: IForgotPassFormCommandModel) => {
    const command_request: IForgotPassComandRequestModlel = {
      email: command_params.email!,
      token: command_params.verify_code!,
      password: command_params.password!,
      password2: command_params.repeat_password!,
    }

    try {
      const api_result = await Accounts.ServiceClient().ForgotPass(command_request);

      const api_response: IForgotPassResponseModel = {
        ok_response: true
      }
      return api_response;
    } catch (err_result: any) {
      throw err_result
    }

  };


  return {
    SignUp,
    ConfrimEmail,
    ConfrimIdentity,
    SignIn,
    ConfrimEmailForgotPass,
    ConfrimIdentityForgotPass,
    ForgotPass,
  };
};
