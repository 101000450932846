import { customTheme } from "../../theme/muconfig";
import classNames from "classnames";
import classes from "./winnerGame.module.scss"
import { HeaderSix, HeaderThree, HeaderTwo } from "../Headers/Headers";
import { PrimaryButton, SecondaryButton } from "../Buttons/Button";
import { useNavigate } from "react-router-dom";
import { ExpierGameErrorModule } from "../../module-errores/ExpierGameErrorContent";
import { CardPrime } from "../CardPrime/view";
import ReactConfetti from 'react-confetti';

interface IModel {
    winner_nickname: string;
    handler_toggleModal?: any;
    show_close_icon?: boolean;
    winner_loser_player_status?: string;
}

export const EndGame = (props: IModel) => {
    const navigate = useNavigate();

    const handler_OnPlayeAgain = () => {
        navigate("/");
    }

    const handler_RedirectLobby = () => {
        navigate("/lobby");
    }


    return (
        props.winner_nickname ? (
            <CardPrime show_close_icon={props.show_close_icon} handler_toggleModal={props.handler_toggleModal}>
                {
                    props.winner_loser_player_status === "winner" && <div className={classNames(classes.celebrate)}>
                        <ReactConfetti/>
                    </div>
                }

                <HeaderThree variant="h3" sx={{ color: customTheme.palette.accent_two.dark, textTransform: "capitalize" }}>{props.winner_loser_player_status === "kicked_out" ? "Kicked out" : "winner"}</HeaderThree>
                <img className={classNames(classes.winner_img)} src="/images/WinnerBadge/WinnerBadge.svg" />
                <HeaderSix variant="h6" sx={{ color: customTheme.palette.accent_one.light, mt: 1, mb: 3 }}>
                    {props.winner_nickname} is the winner!
                </HeaderSix>
                <div>
                    <PrimaryButton exteraStyle={{ margin: "0 6px" }} onClick={handler_OnPlayeAgain} >
                        play again
                    </PrimaryButton>
                    <SecondaryButton exteraStyle={{ margin: "0 6px" }} onClick={handler_RedirectLobby}>
                        back to lobby
                    </SecondaryButton>
                </div>
            </CardPrime>
        ) : (
            <ExpierGameErrorModule text={"the room is expiered no admin dont start game please click button below"} />
        )

    );


}