import classNames from "classnames";
import { useState } from "react";
import classes from './rolldice.module.scss';

interface IModel {
    rotationDiceOne?: any;
    rotationDiceTwo?: any;
    shaking_dice_one_animation?: boolean;
    shaking_dice_two_animation?:boolean;
}

export const Dice = (props: IModel) => {


    return <div className={classNames(classes.parent)} >
        <div className={classNames(classes.container)}>
            <div className={classNames(classes.cube,
                // props.shaking_dice_one_animation && classes.dice_shaking
            )}
                style={{
                    transform: `rotateX(${props.rotationDiceOne.x}deg) rotateY(${props.rotationDiceOne.y}deg) rotateZ(${props.rotationDiceOne.z}deg)`,
                    transition: '0.3s linear',
                    transformOrigin: `${props.rotationDiceOne.degx}% ${props.rotationDiceOne.degy}%`
                }}
            >
                <div className={classNames(classes.front)}>
                    <span>
                        <img src="/images/diceDot/dot.svg" alt="1" />
                    </span>
                </div>
                <div className={classNames(classes.back)}>
                    <div className={classNames(classes.firstPre)}><span className={classNames(classes.firstPreOne)}><img src="/images/diceDot/dot.svg" alt="dot" /></span><span className={classNames(classes.firstPreTwo)}><img src="/images/diceDot/dot.svg" alt="dot" /></span><span className={classNames(classes.firstPreThree)}><img src="/images/diceDot/dot.svg" alt="dot" /></span></div>
                    <div className={classNames(classes.secondPre)}><span className={classNames(classes.secondPreFour)}><img src="/images/diceDot/dot.svg" alt="dot" /></span><span className={classNames(classes.secondPreFive)}><img src="/images/diceDot/dot.svg" alt="dot" /></span><span className={classNames(classes.secondPreSix)}><img src="/images/diceDot/dot.svg" alt="dot" /></span></div>
                </div>
                <div className={classNames(classes.top)}>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                </div>
                <div className={classNames(classes.left)} >
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                </div>
                <div className={classNames(classes.right)} >
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                </div>
                <div className={classNames(classes.bottom)} >
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                </div>
            </div>
        </div>
        <div className={classNames(classes.container)}>
            <div className={classNames(classes.cube,
                // props.shaking_dice_two_animation && classes.dice_shaking
            )}
                style={{
                    transform: `rotateX(${props.rotationDiceTwo.x}deg) rotateY(${props.rotationDiceTwo.y}deg) rotateZ(${props.rotationDiceTwo.z}deg)`,
                    transition: '0.5s linear',
                    transformOrigin: `${props.rotationDiceTwo.degx}% ${props.rotationDiceTwo.degy}%`
                }}
            >
                <div className={classNames(classes.front)}>
                    <span>
                        <img src="/images/diceDot/dot.svg" alt="1" />
                    </span>
                </div>
                <div className={classNames(classes.back)}>
                    <div className={classNames(classes.firstPre)}><span className={classNames(classes.firstPreOne)}><img src="/images/diceDot/dot.svg" alt="dot" /></span><span className={classNames(classes.firstPreTwo)}><img src="/images/diceDot/dot.svg" alt="dot" /></span><span className={classNames(classes.firstPreThree)}><img src="/images/diceDot/dot.svg" alt="dot" /></span></div>
                    <div className={classNames(classes.secondPre)}><span className={classNames(classes.secondPreFour)}><img src="/images/diceDot/dot.svg" alt="dot" /></span><span className={classNames(classes.secondPreFive)}><img src="/images/diceDot/dot.svg" alt="dot" /></span><span className={classNames(classes.secondPreSix)}><img src="/images/diceDot/dot.svg" alt="dot" /></span></div>
                </div>
                <div className={classNames(classes.top)}>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                </div>
                <div className={classNames(classes.left)} >
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                </div>
                <div className={classNames(classes.right)} >
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                </div>
                <div className={classNames(classes.bottom)} >
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                    <span><img src="/images/diceDot/dot.svg" alt="dot" /></span>
                </div>
            </div>
        </div>
    </div>
}