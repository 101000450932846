import { useState } from "react";
import { CardPrime } from "../CardPrime/view";
import classNames from "classnames";
import classes from "./howtoplay.module.scss";

interface IModel {
    handler_toggleModal: any;
}

export const HowToPlaySlider = (props: IModel) => {
    const [active_step, set_active_step] = useState(1);
    const [close_modal, set_close_modal] = useState(false);
    const handler_onNextStep = () => {
        set_active_step(active_step + 1);
    }
    const handler_onPrevtStep = () => {
        set_active_step(active_step - 1);
    }

    return <CardPrime show_close_icon={true} handler_toggleModal={props.handler_toggleModal}>
        <div className={classNames(classes.stepper_container)}>
            <div className={classNames(classes.stepper_image)}>
                {
                    active_step === 1 ?
                        <img src="/images/HowToPlaySlide/rollDice.webp" alt="rollDice" /> :
                        active_step === 2 ?
                            <img src="/images/HowToPlaySlide/service.webp" alt="service" /> :
                            active_step === 3 ?
                                <img src="/images/HowToPlaySlide/function.webp" alt="function" /> :
                                active_step === 4 ?
                                    <img src="/images/HowToPlaySlide/quiz.webp" alt="quiz" /> :
                                    active_step === 5 ?
                                        <img src="/images/HowToPlaySlide/repair.webp" alt="repair" /> : ""
                }
            </div>
            <div className={classNames(classes.stepper_tooltip)}>
                <div className={classNames(classes.stepper_step_one, classes.stepper_step, active_step === 1 || active_step === 2 || active_step === 3 || active_step === 4 || active_step === 5 ? classes.stepper_step_full : "")} ></div>
                <div className={classNames(classes.stepper_step_two, classes.stepper_step, active_step === 2 || active_step === 3 || active_step === 4 || active_step === 5 ? classes.stepper_step_full : "")} ></div>
                <div className={classNames(classes.stepper_step_three, classes.stepper_step, active_step === 3 || active_step === 4 || active_step === 5 ? classes.stepper_step_full : "")} ></div>
                <div className={classNames(classes.stepper_step_four, classes.stepper_step, active_step === 4 || active_step === 5 ? classes.stepper_step_full : "")} ></div>
                <div className={classNames(classes.stepper_step_five, classes.stepper_step, active_step === 5 ? classes.stepper_step_full : "")} ></div>
            </div>
            <div className={classNames(classes.stepper_text)}>
                {
                    active_step === 1 ?
                        "Press “ROLL THE DICE” and start your turn. If you don't start your turn on time, a timer will appear, showing how much time you have left before being removed from the game." :
                        active_step === 2 ?
                            "This is a service block. By landing on a service block a sum of 50 or 150 cyber tokens will be reduced from your budget." :
                            active_step === 3 ?
                                " Implement or ignore the NIST subcategory card, and then press END TURN." :
                                active_step === 4 ?
                                    "By landing on a mystery chest block, you could either win something exciting, lose some of your budget, or end up in quiz time! If you answer correctly, you'll receive cyber tokens." :
                                    active_step === 5 ?
                                        "This is a repair block. By landing on it, you’ll have to dedicate a sum of 50, 90 or 100 cyber tokens to repair expenses." : ""
                }
            </div>
            <div className={classNames(classes.stepper_button_container)}>
                <button className={classNames(classes.stepper_button_skip)} onClick={handler_onPrevtStep} disabled={active_step == 1 && true} >
                    <img src="/images/HowToPlaySlide/skip.svg" alt="skip" />
                </button>
                <button onClick={active_step === 5 ? props.handler_toggleModal : handler_onNextStep} className={classNames(classes.stepper_button_next)}>
                    {
                        active_step === 5 ? <img src="/images/HowToPlaySlide/confirm.svg" alt="confirm" /> : <img src="/images/HowToPlaySlide/next.svg" alt="next" />
                    }
                </button>
            </div>
        </div>
    </CardPrime>
}