import { customTheme } from "../../../theme/muconfig";
import { BoddyOne, BoddyTwo, HeaderFivee, HeaderSix } from "../../../components/Headers/Headers";
import classes from "./howplaye.module.scss";
import classNames from "classnames";
import { CardPrime } from "../../../components/CardPrime/view";

export const View = (props: any) => {
    return <CardPrime how_play={true} handler_toggleModal={props.handler_toggleModal} show_close_icon={props.show_close_icon} exteraStyle={{ width: "100%" }}>
        <HeaderFivee variant="h5">
            How to play?
        </HeaderFivee>
        <HeaderSix variant="h6" sx={{ textAlign: "center", color: customTheme.palette.accent_three.main }}>
            Implement more blocks, manage your budget and be the last one whose budget ends.
        </HeaderSix>
        <div className={classNames(classes.game_rule_container)} >
            <div className={classNames(classes.game_rule_item)} >
                <img
                    alt="user"
                    src={`/images/HowToPlaye/user.svg`}
                    style={{ marginBottom: "16px" }}
                />
                <BoddyTwo variant="body2" sx={{ textAlign: "center", color: customTheme.palette.primaryy.light }}>
                    You are a CISO (Chief Information Security Officer) in a well-known company, aiming to implement the NIST framework.
                </BoddyTwo>
            </div>
            <div className={classNames(classes.game_rule_item)} >
                <img
                    alt="user"
                    src={`/images/HowToPlaye/dice.svg`} style={{ marginBottom: "16px" }} />
                <BoddyTwo variant="body2" sx={{ textAlign: "center", color: customTheme.palette.primaryy.light }}>
                    Roll the dice and Implement as many function blocks as you can.
                </BoddyTwo>
                <BoddyTwo variant="body2" sx={{ textAlign: "center", color: customTheme.palette.accent_two.dark }}>
                    If you roll a double, you get to roll twice!
                </BoddyTwo>
            </div>
            <div className={classNames(classes.game_rule_item)} >
                <img
                    alt="user"
                    src={`/images/HowToPlaye/money.svg`} style={{ marginBottom: "32px" }} />
                <BoddyTwo variant="body2" sx={{ textAlign: "center", color: customTheme.palette.primaryy.light }}>
                    If a player lands on your implemented block, they must pay you a service fee. And also in services and repairs will be added or reduced to your budget
                </BoddyTwo>
            </div>
        </div>
        <BoddyOne variant="body1" sx={{ textAlign: "center", color: customTheme.palette.primaryy.light }}>
            To create your own game room, you must have an account.
        </BoddyOne>
    </CardPrime>
}