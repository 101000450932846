import { CardContent, FormControl, Grid, Input, InputLabel, ThemeProvider } from "@mui/material";
import { customTheme } from "../../../theme/muconfig";
import { CardHeader } from "../../../components/CardHeader";
import { AlertComponent } from "../../../components/Error";
import { PlayersColor } from "../../../constants/enums";
import classNames from 'classnames';
import classes from "./nicknamestyle.module.scss";
import { HeaderSix } from "../../../components/Headers/Headers";
import { PrimaryButton } from "../../../components/Buttons/Button";
import { CardPrime } from "../../../components/CardPrime/view";
import { Header } from "../HeaderContent";
import { Footer } from "../FooterContent";
import { HowToPlaye } from "../HowPlayeContent";
import { BaseModalWrapper } from "../../../components/Modal/Modal";
import { GeneralErrorModule } from "../../../module-errores/GeneralErrorContent";

export const View = (props: any) => {
    const scrollToSection = (elementRef: any) => {
        elementRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <>
            <BaseModalWrapper show_modal={props.show_modal} onBackDropClick={props.handler_toggleModal} >
                {props.error_page && <GeneralErrorModule text_error={props.error_page} />}
            </BaseModalWrapper>
            <div className={classNames(classes.container)}>
                <Header handler_onSignIn={props.handler_onSignIn} />
                <div className={classNames(classes.content)}>
                    <div className={classNames(classes.Choose_nickname_container)}>
                        <CardPrime>
                            <CardHeader header={"Choose your nickname"} paragraph={"This is your displayed name in the game."} />
                            <CardContent>
                                <FormControl
                                    sx={{
                                        color: customTheme.palette.neutrals.mlight,
                                        width: "100%",
                                        my: 1,
                                    }}
                                    variant="standard"
                                    error={
                                        !!props.form_error.nick_name &&
                                        props.form_toutch.nick_name
                                    }
                                >
                                    <InputLabel
                                        sx={{
                                            color: customTheme.palette.neutrals.mlight,
                                            textTransform: "capitalize",
                                        }}
                                        htmlFor="nick_name"
                                    >
                                        Nick Name
                                    </InputLabel>
                                    <Input
                                        sx={{
                                            color: customTheme.palette.neutrals.mlight,
                                            textTransform: "capitalize"
                                        }}
                                        value={props.form_data.nick_name}
                                        onChange={(e) => props.handler_onChange(e)}
                                        type="text"
                                        id="nick_name"
                                        name="nick_name"
                                    />
                                    {!!props.form_error.nick_name &&
                                        props.form_toutch.nick_name && (
                                            <AlertComponent>
                                                {props.form_error.nick_name}
                                            </AlertComponent>
                                        )}
                                </FormControl>
                                <Grid className={classNames(classes.pick_pawns_container)} sx={{ my: 4, width: "100%" }} >
                                    <HeaderSix variant="h6" color={customTheme.palette.primaryy.light} sx={{ mb: 2, textAlign: "center" }}>
                                        Pick your CISO
                                    </HeaderSix>
                                    <Grid className={classNames(classes.pawns_container)} sx={{ display: "flex", justifyContent: "space-between" }} >
                                        {PlayersColor.map((item: any, i: any) => {
                                            return <Grid
                                                style={{ borderRadius: "10px", width: "32px", height: "32px" }}
                                                className={
                                                    `${classes.pawns} ${item === "red"
                                                        ? `${classes.red}`
                                                        : item === "yellow"
                                                            ? `${classes.yellow}`
                                                            : item === "green"
                                                                ? `${classes.green}`
                                                                : item === "purple"
                                                                    ? `${classes.purple}`
                                                                    : item === "blue"
                                                                        ? `${classes.blue}`
                                                                        : ""
                                                    } ${props.is_clicked === item ? `${classes.is_clicked}` : ""}`
                                                }

                                                key={i} onClick={() => props.handler_OnChoosePlayer(item)}>
                                                <img
                                                    style={{ width: "32px", height: "32px" }}
                                                    alt={item}
                                                    src={`/pawns/${item}_pawn.svg`} />
                                            </Grid>
                                        })}
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <PrimaryButton
                                fullWidth={true} size="large" exteraStyle={{ marginX: "6px", marginY: "8px" }} disabled={!(props.form_data.nick_name && props.form_data.id)} onClick={() => {
                                    props.handler_onSubmit()
                                }}
                            >
                                Let's Play
                            </PrimaryButton>
                        </CardPrime>
                    </div>
                    <div className={classNames(classes.game_rule)} >
                        <HowToPlaye show_close_icon={false} />
                    </div>
                </div>
                <Footer />
                <div className={classNames(classes.back_top_btn)}>
                    <button onClick={() => scrollToSection(top)}>
                        <img className={classNames(classes.back_top_btn_img)} src="/images/BackTopIcone/backtotop.svg" alt="top" />
                    </button>
                </div>
            </div>
        </>
    )
}
