import { useEffect, useState } from "react";
import { BoddyOne, BoddyTwo, Caption, HeaderFiveTwo, HeaderSix } from "../Headers/Headers";
import classes from "./functioncard.module.scss";
import classNames from "classnames";
import { AgreeButton, DisAgreeButton } from "../Buttons/Button";
import { List, ListItem, Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import { motion } from "framer-motion";
import { customTheme } from "../../theme/muconfig";
import { ANIMAITEDBTNONE, ANIMAITEDBTNTWO, ANIMAITEDFOOTERTWO, BTNONE, BTNTWO, FOOTERTWO } from "../Animation/animation";
interface IModel {
    handler_toggleModal?: any;
    type?: any;
    price: number;
    rent?: number;
    people?: number;
    number_page?: number;
    header_one?: string;
    header_two?: string;
    header_three?: string;
    content_one?: string;
    content_two?: any;
    content_three?: Array<string>;
    refuse?: any;
    is_animating?: boolean;
    handler_onActionImpliment: () => void;
    handler_onActionRefuse: () => void;
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

export const View = (props: IModel) => {
    const [active_step, set_active_step] = useState(1);
    const handler_OnClick = (button_number: number) => {
        set_active_step(button_number);
    };

    /////////////counter/////////////////////////
    const [initialNumber, setInitialNumber] = useState(props.price);
    const [price_number, set_price_number] = useState(initialNumber);
    const [intervalId, setIntervalId] = useState<any>(null);
    const [is_disabled, set_is_disabled] = useState(false);
    const startReducer = () => {
        set_price_number(initialNumber); // Reset number to initial value
        const id = setInterval(() => {
            set_price_number(prevNumber => prevNumber - 10);
        }, 80);
        setIntervalId(id);
        set_is_disabled(true)
    };

    useEffect(() => {
        if (price_number <= 0 && intervalId !== null) {
            clearInterval(intervalId);
            setIntervalId(null);
            setTimeout(() => {
                set_price_number(initialNumber);
            }, 10000);
        }
    }, [price_number, intervalId]);
  
    /////////////counter/////////////////////////
    let duration_time = price_number < 50 ? 1 : (price_number >= 100 && price_number <= 200) ? 2 : (price_number >= 200 && price_number <= 300) ? 4 : (price_number >= 300 && price_number <= 400) ? 5 : (price_number >= 400 && price_number <= 500) ? 6 : (price_number >= 500 && price_number <= 600) ? 7 : (price_number >= 600 && price_number <= 700) ? 8 : (price_number >= 700 && price_number <= 800) ? 9 : (price_number >= 700 && price_number <= 800) ? 10 : (price_number >= 800 && price_number <= 900) ? 11 : (price_number >= 900 && price_number <= 1000) ? 12 : (price_number >= 1000 && price_number <= 1500) ? 13 : 14;
    return <div className={classNames(classes.card)} >
        <div className={classNames(classes.card_container)} >
            <div className={classNames(classes.card_header,
                props.type === "identify"
                    ? [classes.quizcard_item_text_identify, classes.identify_header_background]
                    : props.type === "detect"
                        ? [classes.quizcard_item_text_detect, classes.detect_header_background]
                        : props.type === "respond"
                            ? [classes.quizcard_item_text_respond, classes.respond_header_background]
                            : props.type === "recover"
                                ? [classes.quizcard_item_text_recover, classes.recover_header_background]
                                : props.type === "protect"
                                    ? [classes.quizcard_item_text_protect, classes.protect_header_background]
                                    : ""
            )} >
                <HeaderFiveTwo variant="h5" className={classNames(
                    classes.card_header_text,
                    props.type === "identify"
                        ? classes.text_color_identify
                        : props.type === "detect"
                            ? classes.text_color_detect
                            : props.type === "respond"
                                ? classes.text_color_respond
                                : props.type === "recover"
                                    ? classes.text_color_recover
                                    : props.type === "protect"
                                        ? classes.text_color_protect
                                        : ""
                )}>
                    {props.type}
                </HeaderFiveTwo>
                <img src={`/function_cards/${props.type}/${props.type}_header_one.svg`} alt={`${props.type}_header_one`} />
                <img src={`/function_cards/${props.type}/${props.type}_header_two.svg`} alt={`${props.type}_header_two`} />
            </div>
            <div className={classNames(classes.card_content)}>
                <div className={classNames(classes.card_content_header)}>
                    <BootstrapTooltip title="Cost">
                        <div className={classNames(classes.card_content_header_items)}>
                            <img className={classNames(classes.card_content_header_item)} src={`/function_cards/price.svg`} alt={`price`} />
                            <BoddyOne className={classNames(classes.card_content_header_item,
                                price_number === 0 ? classes.become_zero : ""
                            )} variant="body2" >
                                {price_number}
                            </BoddyOne>
                        </div>
                    </BootstrapTooltip>
                    <BootstrapTooltip title="Human Resource">
                        <div className={classNames(classes.card_content_header_items)}>
                            <img className={classNames(classes.card_content_header_item)} src={`/function_cards/people.svg`} alt={`people`} />
                            <BoddyOne className={classNames(classes.card_content_header_item)} variant="body2" >
                                {props.people}
                            </BoddyOne>
                        </div>
                    </BootstrapTooltip>
                    <BootstrapTooltip title="Service Fee">
                        <div className={classNames(classes.card_content_header_items)}>
                            <img className={classNames(classes.card_content_header_item)} src={`/function_cards/rent.svg`} alt={`rent`} />
                            <BoddyOne className={classNames(classes.card_content_header_item)} variant="body2" >
                                {props.rent}
                            </BoddyOne>
                        </div>
                    </BootstrapTooltip>
                </div>
                <div className={classNames(classes.card_content_text,
                    active_step === 1 ?
                        classes.card_content_page_one :
                        classes.card_content_page_two)}>
                    {(active_step === 1) && (
                        <>
                            <div className={classNames(classes.card_content_text_icon)}>
                                <img src={`/function_cards/${props.type}/${props.type}_icon.svg`} alt={`${props.type}_icon`} />
                            </div>
                            <HeaderSix
                                variant="h6"
                                className={classNames(
                                    classes.card_content_text_header,
                                    props.type === "identify"
                                        ? [classes.text_color_identify, classes.text_shadow_color_identify]
                                        : props.type === "detect"
                                            ? [classes.text_color_detect, classes.text_shadow_color_detect]
                                            : props.type === "respond"
                                                ? [classes.text_color_respond, classes.text_shadow_color_respond]
                                                : props.type === "recover"
                                                    ? [classes.text_color_recover, classes.text_shadow_color_recover]
                                                    : props.type === "protect"
                                                        ? [classes.text_color_protect, classes.text_shadow_color_protect]
                                                        : ""
                                )}
                            >{props.header_one}</HeaderSix>
                            <BoddyTwo
                                variant="caption"
                                className={classNames(
                                    classes.card_content_text_caption,
                                    props.type === "identify"
                                        ? classes.text_color_identify
                                        : props.type === "detect"
                                            ? classes.text_color_detect
                                            : props.type === "respond"
                                                ? classes.text_color_respond
                                                : props.type === "recover"
                                                    ? classes.text_color_recover
                                                    : props.type === "protect"
                                                        ? classes.text_color_protect
                                                        : ""
                                )}
                            >
                                {props.content_one}
                            </BoddyTwo>
                        </>
                    )}
                    {(active_step === 2) && (
                        <>
                            <HeaderFiveTwo
                                variant="h5"
                                className={classNames(
                                    classes.card_content_text_header_list,
                                    props.type === "identify"
                                        ? [classes.text_color_identify, classes.text_shadow_color_identify]
                                        : props.type === "detect"
                                            ? [classes.text_color_detect, classes.text_shadow_color_detect]
                                            : props.type === "respond"
                                                ? [classes.text_color_respond, classes.text_shadow_color_respond]
                                                : props.type === "recover"
                                                    ? [classes.text_color_recover, classes.text_shadow_color_recover]
                                                    : props.type === "protect"
                                                        ? [classes.text_color_protect, classes.text_shadow_color_protect]
                                                        : ""
                                )}
                            >{props.header_two}</HeaderFiveTwo>
                            <List className={classNames(classes.card_content_text_items_page_two_container,)}>
                                {JSON.parse(props.content_two)?.map((item: any, index: any) => (
                                    <ListItem key={index} >
                                        <Caption className={classNames(classes.card_content_text_items_page_two_item,
                                            props.type === "identify"
                                                ? classes.text_color_identify
                                                : props.type === "detect"
                                                    ? classes.text_color_detect
                                                    : props.type === "respond"
                                                        ? classes.text_color_respond
                                                        : props.type === "recover"
                                                            ? classes.text_color_recover
                                                            : props.type === "protect"
                                                                ? classes.text_color_protect
                                                                : ""
                                        )} variant="body1" >
                                            &#8226; {item}
                                        </Caption>
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    )}
                    {(active_step === 3) && (
                        <>
                            <HeaderFiveTwo
                                variant="h5"
                                className={classNames(
                                    props.type === "identify"
                                        ? [classes.text_color_identify, classes.text_shadow_color_identify]
                                        : props.type === "detect"
                                            ? [classes.text_color_detect, classes.text_shadow_color_detect]
                                            : props.type === "respond"
                                                ? [classes.text_color_respond, classes.text_shadow_color_respond]
                                                : props.type === "recover"
                                                    ? [classes.text_color_recover, classes.text_shadow_color_recover]
                                                    : props.type === "protect"
                                                        ? [classes.text_color_protect, classes.text_shadow_color_protect]
                                                        : ""
                                )}
                            >{props.header_three}</HeaderFiveTwo>
                            <List className={classNames(classes.card_content_text_items_page_two_container,)}>
                                {props.content_three?.map((item: any) => (
                                    <ListItem >
                                        <Caption className={classNames(classes.card_content_text_items_page_two_item,
                                            props.type === "identify"
                                                ? classes.text_color_identify
                                                : props.type === "detect"
                                                    ? classes.text_color_detect
                                                    : props.type === "respond"
                                                        ? classes.text_color_respond
                                                        : props.type === "recover"
                                                            ? classes.text_color_recover
                                                            : props.type === "protect"
                                                                ? classes.text_color_protect
                                                                : ""
                                        )} variant="body1" >
                                            &#8226; {item}
                                        </Caption>
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    )}
                </div>
                <div className={classNames(classes.footer_card_container)}>
                    <div className={classNames(classes.footer_card_content)}>
                        <button className={classNames(classes.footer_card_btn_one, classes.footer_btn,
                            props.type === "identify"
                                ? [classes.btn_background_identify]
                                : props.type === "detect"
                                    ? [classes.btn_background_detect]
                                    : props.type === "respond"
                                        ? [classes.btn_background_respond]
                                        : props.type === "recover"
                                            ? [classes.btn_background_recover]
                                            : props.type === "protect"
                                                ? [classes.btn_background_protect]
                                                : "",
                        )}
                            onClick={() => handler_OnClick(1)}
                        >
                            <BTNONE type={props.type} />
                        </button>
                        <button className={classNames(classes.footer_card_btn_two, classes.footer_btn,
                            props.type === "identify"
                                ? [classes.btn_background_identify]
                                : props.type === "detect"
                                    ? [classes.btn_background_detect]
                                    : props.type === "respond"
                                        ? [classes.btn_background_respond]
                                        : props.type === "recover"
                                            ? [classes.btn_background_recover]
                                            : props.type === "protect"
                                                ? [classes.btn_background_protect]
                                                : "",
                        )}
                            onClick={() => handler_OnClick(2)}
                        >
                            <BTNTWO type={props.type} />
                        </button>
                        <div className={classNames(classes.footer_icone)}>
                            <FOOTERTWO type={props.type} />
                        </div>
                    </div>
                    <div className={classNames(classes.animated_footer_card_content)}>
                        <button className={classNames(classes.footer_card_btn_one, classes.footer_btn,
                            props.type === "identify"
                                ? [classes.btn_background_identify]
                                : props.type === "detect"
                                    ? [classes.btn_background_detect]
                                    : props.type === "respond"
                                        ? [classes.btn_background_respond]
                                        : props.type === "recover"
                                            ? [classes.btn_background_recover]
                                            : props.type === "protect"
                                                ? [classes.btn_background_protect]
                                                : "",
                        )}
                            onClick={() => handler_OnClick(1)}
                        >
                            <ANIMAITEDBTNONE price_number={price_number} is_animating={props.is_animating} duration_time={duration_time} />
                        </button>
                        <button className={classNames(classes.footer_card_btn_two, classes.footer_btn,
                            props.type === "identify"
                                ? [classes.btn_background_identify]
                                : props.type === "detect"
                                    ? [classes.btn_background_detect]
                                    : props.type === "respond"
                                        ? [classes.btn_background_respond]
                                        : props.type === "recover"
                                            ? [classes.btn_background_recover]
                                            : props.type === "protect"
                                                ? [classes.btn_background_protect]
                                                : "",
                        )}
                            onClick={() => handler_OnClick(2)}
                        >
                            <ANIMAITEDBTNTWO price_number={price_number} is_animating={props.is_animating} duration_time={duration_time} />
                        </button>
                        <div className={classNames(classes.footer_icone)}>
                            <ANIMAITEDFOOTERTWO price_number={price_number} is_animating={props.is_animating} duration_time={duration_time} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={classNames(classes.card_buttons)}>
            <AgreeButton onClick={() => {
                props.handler_onActionImpliment(); // Call the original handler
                startReducer(); // Call another function
            }} disabled={is_disabled} >Implement</AgreeButton>
            <DisAgreeButton disabled={is_disabled} refuse={props.refuse} onClick={props.handler_onActionRefuse}>{props./* The above code is a comment block in TypeScript React. It appears to be refusing or rejecting something, but without further context, it is unclear what exactly is being refused. */
            refuse === 0 ? "ignore" : "Are You sure"}</DisAgreeButton>
        </div>
    </div>
}



