import { useEffect, useState } from "react";
import { apiclients } from "../../serviceclients";
import { IDataReceiveModel, IModel } from "./model";

export const useContainer = (props: IModel) => {
  const [profile_avatar, set_profile_avatar] = useState("");
  const [show_profile_tab, set_show_profile_tab] = useState(false);
  let handler_onSignIn=props.handler_onSignIn
  let data_resived:IDataReceiveModel = {
    information: props.information,
    howPlay: props.howPlay,
    teamInfo: props.teamInfo,
    contact_us:props.contact_us
  }
let not_show_signin_btn=props.not_show_signin_btn

  let show_link = props.show_link;

  useEffect(() => {
    // apiclients().GetAvatarProfile()
    //   .then((command_result: any) => {
    //     set_profile_avatar(command_result.api_result.avatar_color);
    //   }).catch((err: any) => {
    //     console.log(err)
    //   })
  }, [])

  const handler_onShowProfileSetting=()=>{
    set_show_profile_tab(prev => !prev)
  }


  return {
    data_resived,
    show_link,
    profile_avatar,
    handler_onShowProfileSetting,
    show_profile_tab,
    handler_onSignIn,
    not_show_signin_btn
  }
}