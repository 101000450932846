import { create } from 'zustand'

interface IModel {
    mute: boolean;
    set_mute: (mute: boolean) => void;
}

export const useSoundStore = create<IModel>()(
    (set) => ({
        mute: false,
        set_mute: (value: boolean) => set((mute) => ({ mute: value })),
    }),
)