import { Button, Container, Grid, Input, Modal, Typography } from "@mui/material";
import { CardComponent } from "../../../components/Card";
import { customTheme } from "../../../theme/muconfig";
import classes from "./startgamepagestyle.module.scss";
import { IBoardModel, IModel } from "./model";
import { motion } from "framer-motion";
import { BaseModalWrapper } from "../../../components/Modal/Modal";
import { ForgotPasswordModule, SignInModule, SignUpModule } from "../../../module-accounts/views";
import { HowToPlaye } from "../HowPlayeContent";
import { Caption, HeaderSix } from "../../../components/Headers/Headers";
import { BlureBtn, PrimaryButton, SecondaryButton } from "../../../components/Buttons/Button";
import classNames from 'classnames';
import { CardPrime } from "../../../components/CardPrime/view";
import { Dice } from "../../../components/RollDice/view";
import { ProfilePanel } from "../../../module-accounts/views/MenuProfileContent";


const dropIn: any = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.2,
            type: "spring",
            damping: 25,
            stiffness: 500
        }
    },
    exit: {
        y: "100vh",
        opacity: 0,
    }
}

export const View = (props: IModel) => {
    return (
        <>
            <BaseModalWrapper show_modal={props.show_modal} onBackDropClick={props.handler_toggleModal} >
                {props.currentPage === 'startGame' && <motion.div
                    variants={dropIn}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    className="modal-parent"
                >
                    <CardPrime exteraStyle={{ width: "100%" }} show_close_icon={true} handler_toggleModal={props.handler_toggleModal} >
                        <div className={classes.game_logo_started_page}>
                            <img alt="logo" src="./images/BoardGameIcones/board_logo.svg" />
                        </div>
                        <HeaderSix sx={{ textTransform: "capitalize", color: customTheme.palette.primaryy.main, marginY: "15px" }} variant="h6">
                            learn with fun!
                        </HeaderSix>
                        <div className={classNames(classes.game_logo_started_page_btns)}>
                            <PrimaryButton onClick={props.handler_OnStartGame} exteraStyle={{ margin: "0 6px", width: "40%" }}>
                                Let's play !
                            </PrimaryButton>
                            <SecondaryButton onClick={props.handler_RedirectLobby} exteraStyle={{ margin: "0 6px", width: "40%" }}>
                                go to lobby
                            </SecondaryButton>
                        </div>
                    </CardPrime>
                </motion.div>}
                {props.currentPage === 'login' && <SignInModule is_redirected={props.is_redirected} show_close_icon={true} handler_toggleModal={props.handler_toggleModal} handler_onRegister={props.handler_onRegister} />}
                {props.currentPage === 'signUp' && <SignUpModule is_redirected={props.is_redirected} show_close_icon={true} handler_toggleModal={props.handler_toggleModal} handler_onRegister={props.handler_onRegister} />}
                {props.currentPage === 'forgotpass' && <ForgotPasswordModule show_close_icon={true} handler_toggleModal={props.handler_toggleModal} handler_onRegister={props.handler_onRegister} />}
                {props.currentPage === 'howtoplay' && <div className={classNames(classes.how_play_container)}>
                    <HowToPlaye show_close_icon={true} handler_toggleModal={props.handler_toggleModal} />
                </div>}
            </BaseModalWrapper>
            <div className={classNames(classes.container)}>
                <div className={classNames(classes.left_container, classes.item)}>
                    <div className={classNames(classes.player_navbar_container, classes.box_item)} >
                        <div className={classNames(classes.player_navbar_container_avatar)} >
                            {props.is_registered ?
                                <Container className={classNames(classes.player_navbar_container_avatar)} onClick={() => props.handler_onShowProfileSetting()}  >
                                    <img alt={`${props.profile_avatar}`} src={`/images/Avatars/${props.profile_avatar}.svg`} />
                                    <ProfilePanel show_profile_tab={props.show_profile_tab} />
                                </Container>
                                :
                                <BlureBtn onClick={() => props.handler_onSignIn()} exteraStyle={{ marginLeft: "10px" }}>
                                    sign in
                                </BlureBtn>
                            }
                        </div>
                        <div className={classNames(classes.player_navbar_container_sounds)} >
                            <img alt="sounds" src="/images/ProfilePanel/sound.svg" />
                        </div>
                    </div>
                    <div className={classNames(classes.advertise_container, classes.box_item)} >
                        <img alt="advertis" src="/images/advertis/addbanner.svg" />
                    </div>
                </div>
                <div className={classNames(classes.board_container, classes.item)}>
                    {props.board_game.map((square: IBoardModel, index: number) => {
                        let square_content;
                        if (square.type === "block") {
                            square_content = (
                                <div className={classNames(classes.board_square_block_container,
                                    square.content!.type === "mystery" ? classes.board_square_block_mystery : classes.board_square_block_other,
                                    ((square.col === 11 && square.row === 5) || (square.col === 11 && square.row === 8) || (square.col === 11 && square.row === 10)) ? classes.board_square_block_right_side : ((square.col === 1 && square.row === 4) || (square.col === 1 && square.row === 8)) ? classes.board_square_block_left_side : classes.board_square_block_top_bottom_side
                                )}>
                                    <img
                                        alt={square.type}
                                        src={`/images/BoardGameIcones/${square.content!.type}.svg`} />
                                </div>
                            )
                        } else if (square.type === "function") {
                            if (square.row === 1 && 2 <= square.col && square.col <= 10) {
                                square_content = (
                                    <div className={classNames(classes.board_square_function_container, classes.board_upper_side,
                                        square.content?.type === "identify" ? classes.board_square_function_container_identify :
                                            square.content?.type === "protect" ? classes.board_square_function_container_protect :
                                                square.content?.type === "detect" ? classes.board_square_function_container_detect :
                                                    square.content?.type === "recover" ? classes.board_square_function_container_recover :
                                                        square.content?.type === "respond" ? classes.board_square_function_container_respond : ""
                                    )}>
                                        <div className={classNames(classes.board_square_function_price_container)}>
                                            <Caption>{square.content?.price}</Caption>
                                        </div>
                                        <img className={classNames(classes.board_square_function_icon)} alt={square.content?.type} src={`/images/BoardGameIcones/${square.content?.type}.svg`} />
                                        <Caption>{square.content?.header}</Caption>
                                    </div>
                                )
                            } else if (square.row === 11 && 2 <= square.col && square.col <= 10) {
                                square_content = (
                                    <div className={classNames(classes.board_square_function_container, classes.board_bottom_side,
                                        square.content?.type === "identify" ? classes.board_square_function_container_identify :
                                            square.content?.type === "protect" ? classes.board_square_function_container_protect :
                                                square.content?.type === "detect" ? classes.board_square_function_container_detect :
                                                    square.content?.type === "recover" ? classes.board_square_function_container_recover :
                                                        square.content?.type === "respond" ? classes.board_square_function_container_respond : ""
                                    )}>
                                        <Caption>{square.content?.header}</Caption>
                                        <img className={classNames(classes.board_square_function_icon)} alt={square.content?.type} src={`/images/BoardGameIcones/${square.content?.type}.svg`} />
                                        <div className={classNames(classes.board_square_function_price_container)}>
                                            <Caption>{square.content?.price}</Caption>
                                        </div>
                                    </div>
                                )
                            } else if (square.col === 1 && 2 <= square.row && square.row <= 10) {
                                square_content = (
                                    <div className={classNames(classes.board_square_function_container, classes.board_left_side,
                                        square.content?.type === "identify" ? classes.board_square_function_container_identify :
                                            square.content?.type === "protect" ? classes.board_square_function_container_protect :
                                                square.content?.type === "detect" ? classes.board_square_function_container_detect :
                                                    square.content?.type === "recover" ? classes.board_square_function_container_recover :
                                                        square.content?.type === "respond" ? classes.board_square_function_container_respond : ""
                                    )}>
                                        <Caption className={classNames(classes.board_square_function_header_left_side)} >{square.content?.header}</Caption>
                                        <img alt={square.content?.type} src={`/images/BoardGameIcones/${square.content?.type}.svg`} />
                                        <div className={classNames(classes.board_square_function_price_container)}>
                                            <Caption>{square.content?.price}</Caption>
                                        </div>
                                    </div>
                                )
                            } else if (square.col === 11 && 2 <= square.row && square.row <= 10) {
                                square_content = (
                                    <div className={classNames(classes.board_square_function_container, classes.board_right_side,
                                        square.content?.type === "identify" ? classes.board_square_function_container_identify :
                                            square.content?.type === "protect" ? classes.board_square_function_container_protect :
                                                square.content?.type === "detect" ? classes.board_square_function_container_detect :
                                                    square.content?.type === "recover" ? classes.board_square_function_container_recover :
                                                        square.content?.type === "respond" ? classes.board_square_function_container_respond : ""
                                    )}>
                                        <Caption className={classNames(classes.board_square_function_header_right_side)}>{square.content?.header}</Caption>
                                        <img alt={square.content?.type} src={`/images/BoardGameIcones/${square.content?.type}.svg`} />
                                        <div className={classNames(classes.board_square_function_price_container)}>
                                            <Caption>{square.content?.price}</Caption>
                                        </div>
                                    </div>
                                )
                            }
                        }

                        return <div
                            style={{
                                gridRow: square.row,
                                gridColumn: square.col,
                            }}
                            className={classNames(classes.board_square)}
                        >
                            {square_content}
                        </div>
                    })}
                    < div className={classNames(classes.board_middle)} >
                        <div className={classNames(classes.dice_contents)}  >
                            <div className={classNames(classes.dice_container)} >
                                {/* <img
                                    src={`./images/Dice/${1}.svg`}
                                    alt={"one"}
                                    style={{ width: "60%", height: "60%" }}
                                />
                                <img
                                    src={`./images/Dice/${2}.svg`}
                                    alt={"two"}
                                    style={{ width: "60%", height: "60%" }}
                                /> */}
                                {/* <RollDice dice_1={1} dice_2={1} call_animation={false} /> */}
                                <Dice rotationDiceOne={{ x: -310, y: -362, z: -38, degx: -120, degy: 0 }} rotationDiceTwo={{ x: -310, y: -362, z: -38, degx: -120, degy: 0 }} />
                            </div>
                            <div className={classNames(classes.board_middle_btns)} >
                                <PrimaryButton onClick={props.handler_OnStartGame} exteraStyle={{ margin: "0 6px" }}>
                                    Let's play !
                                </PrimaryButton>
                                <SecondaryButton onClick={props.handler_RedirectLobby} exteraStyle={{ margin: "0 6px" }}>
                                    go to lobby
                                </SecondaryButton>

                            </div>
                        </div>

                        <div className={classNames(classes.game_logo)} >
                            <img alt="logo" src="./images/BoardGameIcones/board_logo.svg" />
                        </div>

                    </div>
                </div>

                <div className={classNames(classes.right_container, classes.item)}>

                    <div className={classNames(classes.game_rule_container, classes.box_item)} >
                        <HeaderSix sx={{ p: 1, width: "100%" }} color={customTheme.palette.primaryy.light} textAlign={"center"} textTransform={"capitalize"} variant="h6">how to play ?</HeaderSix>
                        <BlureBtn onClick={props.handler_OnhowToPlay} exteraStyle={{ width: "70%", textTransform: "uppercase" }}>
                            how to play?
                            <img style={{ marginLeft: "4px" }} src="/images/HowPlayeBtn/icon.svg" alt="icon" />
                        </BlureBtn>
                    </div>
                </div>
            </div>
        </>
    )
}