// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagenotfound_container__BLnbx {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.pagenotfound_item__6kRuQ {
  width: 50%;
}

.pagenotfound_text_container__ipNlI {
  text-align: center;
}

.pagenotfound_img_container__49b7c img {
  width: 100%;
}

@media (min-width: 430px) and (max-width: 743px) {
  .pagenotfound_container__BLnbx {
    flex-direction: column;
    margin-top: 10px;
  }
  .pagenotfound_img_container__49b7c {
    margin-top: 10px;
  }
}
@media (min-width: 320px) and (max-width: 429px) {
  .pagenotfound_container__BLnbx {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/module-errores/PageNotFoundComponent/pagenotfound.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAIA;EACI;IACI,sBAAA;IACA,gBAAA;EADN;EAIE;IACI,gBAAA;EAFN;AACF;AAKA;EACI;IACI,sBAAA;EAHN;AACF","sourcesContent":[".container {\n    display: flex;\n    width: 100%;\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n}\n\n.item {\n    width: 50%;\n}\n\n.text_container {\n    text-align: center;\n}\n\n.img_container img {\n    width: 100%;\n}\n\n//////////////////////media query//////////\n\n@media (min-width:430px)and (max-width:743px) {\n    .container {\n        flex-direction: column;\n        margin-top: 10px;\n    }\n\n    .img_container {\n        margin-top: 10px;\n    }\n}\n\n@media (min-width:320px)and (max-width:429px) {\n    .container {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pagenotfound_container__BLnbx`,
	"item": `pagenotfound_item__6kRuQ`,
	"text_container": `pagenotfound_text_container__ipNlI`,
	"img_container": `pagenotfound_img_container__49b7c`
};
export default ___CSS_LOADER_EXPORT___;
