import { useState } from "react"
import { apiclients } from "../../serviceclients/apiclients"
import { ErrorResult } from "../../../module-errores/error/container";
import { useNavigate } from "react-router-dom";

export const useContainer = (props:any) => {

    const [is_clicked, set_is_clicked] = useState(null);
    const [error, set_error] = useState<any>(null);
    const handler_OnChooseAvatar = (type: any) => {
        set_is_clicked(type)
    }
    const navigate = useNavigate();
    let show_close_icon=props.show_close_icon;
    let handler_toggleModal=props.handler_toggleModal
    const handler_onSaveAvatarSelected = () => {
        
       
        if (is_clicked) {
            let command_request = {
                avatar_color: is_clicked
            }
            apiclients().SaveAvatarSelected(command_request)
                .then((command_result: any) => {
                    if (command_result.api_result) {
                        window.location.reload();
                    }
                })
                .catch((error_result: any) => {
                    let error_variable = ErrorResult(error_result)
                    if(error_variable==="server_error"){
                        navigate("/servererror")
                      }
                    set_error(error_variable)
                })
        }else{
            set_error("please select avatar then press save change button")
        }

    }


    return {
        handler_OnChooseAvatar,
        is_clicked,
        handler_onSaveAvatarSelected,
        error,
        show_close_icon,
        handler_toggleModal
    }
}