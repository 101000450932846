import { List, ListItem } from "@mui/material"
import { BoddyOne } from "../../../components/Headers/Headers"
import classes from "./headerstyle.module.scss";
import classNames from "classnames";
import { ProfilePanel } from "../../../module-accounts/views/MenuProfileContent";
import { BlureBtn } from "../../../components/Buttons/Button";

export const View = (props: any) => {
    const scrollToSection = (elementRef: any) => {
        elementRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <div className={classNames(classes.navbar, classes.row)}>
            <div className={classNames(classes.navbar_logo)}>
                <img src="/images/SyberLogoes/smal.svg" />
            </div>
            {props.show_link &&
                <List className={classNames(classes.navbar_links)}>
                    <ListItem onClick={() => scrollToSection(props.data_resived.information)}>
                        <BoddyOne className={classNames(classes.navbar_links_item)} variant="body1" >
                            More information
                        </BoddyOne>
                    </ListItem>
                    <ListItem onClick={() => scrollToSection(props.data_resived.howPlay)}>
                        <BoddyOne className={classNames(classes.navbar_links_item)} variant="body1" >
                            How to play ?
                        </BoddyOne>
                    </ListItem>
                    <ListItem onClick={() => scrollToSection(props.data_resived.teamInfo)}>
                        <BoddyOne className={classNames(classes.navbar_links_item)} variant="body1" >
                            Our team
                        </BoddyOne>
                    </ListItem>
                    <ListItem onClick={() => scrollToSection(props.data_resived.contact_us)}>
                        <BoddyOne className={classNames(classes.navbar_links_item)} variant="body1" >
                            Contact us
                        </BoddyOne>
                    </ListItem>
                </List>
            }
            {/* {
                props.profile_avatar ? (
                    <div className={classNames(classes.navbar_accuant)} onClick={() => props.handler_onShowProfileSetting()} >
                        <img alt={`${props.profile_avatar}`} src={`/images/Avatars/${props.profile_avatar}.svg`} />
                        <ProfilePanel show_profile_tab={props.show_profile_tab} extera_style={{ right: 0 }} />
                    </div>) :
                    (
                        props.not_show_signin_btn ? <></> : <BlureBtn onClick={() => props.handler_onSignIn()} exteraStyle={{ marginLeft: "10px" }}>
                            sign in
                        </BlureBtn>
                    )
            } */}

        </div>
    )
}