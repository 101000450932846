export const useContainer = (props: any) => {
    return {
        handler_toggleModal: props.andler_toggleModal,
        type: props.type,
        price: props.price,
        rent: props.rent,
        people: props.people,
        number_page: props.number_page,
        header_one: props.header_one,
        header_two: props.header_two,
        header_three: props.header_three,
        content_one: props.content_one,
        content_two: props.content_two,
        content_three: props.content_three,
        handler_onActionImpliment: props.handler_onActionImpliment,
        handler_onActionRefuse: props.handler_onActionRefuse,
        refuse:props.refuse,
        is_animating:props.is_animating,
    }
}