import {
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material";
import { CardComponent } from "../../../components/Card";
import { CardHeader } from "../../../components/CardHeader";
import { customTheme } from "../../../theme/muconfig";
import { AlertComponent } from "../../../components/Error";
import { ButtonLinkComponent } from "../../../components/LinkButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Paragraph } from "../../../components/Paragraph";
import { CardPrime } from "../../../components/CardPrime/view";
import classes from "./forgotpassword.module.scss";


export const View = (props: any) => {
  const handler_OnKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission behavior
      props.handler_OnSubmit();
    }
  };
  return (
    <div className={classes.container}>
      <CardPrime handler_toggleModal={props.handler_toggleModal} show_close_icon={props.show_close_icon} step={props.active_step}>
        <CardHeader
          header={
            props.active_step === 1
              ? "Verify It’s you"
              : props.active_step === 2
                ? "Insert the code"
                : props.active_step === 3
                  ? "Almost There"
                  : ""
          }
          paragraph={
            props.active_step === 1
              ? "Please insert your email to receive a 6 digit verification code"
              : props.active_step === 2
                ? "We’ve sent a 6 digit code to your email. please check your inbox or spam and insert the code"
                : props.active_step === 3
                  ? "Set up your account essentials and let the game begin. "
                  : ""
          }
        />

        {props.active_step === 1 && (
          <>
            <FormControl
              sx={{ width: "100%", my: 1 }}
              variant="standard"
              error={!!props.form_error.email && props.form_toutch.email}
            >
              <InputLabel
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                htmlFor="email"
              >
                Email
              </InputLabel>
              <Input
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                onChange={(e) => props.handler_OnChange(e)}
                type="email"
                id="email"
                name="email"
                onKeyDown={handler_OnKeyDown}
              />
              {!!props.form_error.email && props.form_toutch.email && (
                <AlertComponent>{props.form_error.email}</AlertComponent>
              )}
            </FormControl>
            <Button sx={{ backgroundColor: customTheme.palette.primaryy.main, color: customTheme.palette.neutrals.with, marginX: "6px", '&:hover': { backgroundColor: customTheme.palette.primaryy.dark }, my: 1 }} onClick={props.handler_OnSubmit} fullWidth size="large" variant="contained" disabled={!(props.form_data.email)}>Reset Password</Button>
          </>
        )}
        {props.active_step == 2 && (
          <>
            <FormControl
              sx={{ width: "100%", my: 1 }}
              variant="standard"
              error={
                !!props.form_error.verify_code &&
                props.form_toutch.verify_code
              }
            >
              <InputLabel
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                htmlFor="verify_code"
              >
                Verify Code
              </InputLabel>
              <Input
                placeholder="XXXXXX"
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                onChange={(e) => props.handler_OnChange(e)}
                type="text"
                id="verify_code"
                name="verify_code"
                onKeyDown={handler_OnKeyDown}
              />
              {!!props.form_error.verify_code &&
                props.form_toutch.verify_code && (
                  <AlertComponent>
                    {props.form_error.verify_code}
                  </AlertComponent>
                )}
            </FormControl>
            {props.time_remaining ? (
              <Paragraph extraStyles={{ margin: "8px" }}>{props.handler_FormatTime(props.time_remaining)}</Paragraph>
            ) : (<Paragraph extraStyles={{ margin: "8px", display: "none" }}>{props.handler_FormatTime(props.time_remaining)}</Paragraph>)}

            <ButtonLinkComponent justifyContent={"center"} display={props.time_remaining} handler_Action={props.handler_ResendCode}>
              Resend Code
            </ButtonLinkComponent>
            <Paragraph extraStyles={{ my: 1 }}>By continuing, you agree that we create an account for you</Paragraph>

            <Button sx={{ backgroundColor: customTheme.palette.primaryy.main, color: customTheme.palette.neutrals.with, marginX: "6px", '&:hover': { backgroundColor: customTheme.palette.primaryy.dark }, my: 1 }} onClick={props.handler_OnSubmit} fullWidth size="large" variant="contained" disabled={!(props.form_data.verify_code)}>Reset Password</Button>
          </>
        )}
        {props.active_step == 3 && (
          <>
            <FormControl
              sx={{ width: "100%", my: 1 }}
              variant="standard"
              error={
                !!props.form_error.password && props.form_toutch.password
              }
            >
              <InputLabel
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                htmlFor="password"
              >
                Password
              </InputLabel>
              <Input
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                }}
                onChange={(e) => props.handler_OnChange(e)}
                id="password"
                name="password"
                type={props.show_password ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={props.handler_ClickShowPassword}
                      onMouseDown={props.handler_MouseDownPassword}
                    >
                      {props.show_password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                onKeyDown={handler_OnKeyDown}
              />
              {!!props.form_error.password &&
                props.form_toutch.password && (
                  <AlertComponent>
                    {props.form_error.password}
                  </AlertComponent>
                )}
            </FormControl>
            <FormControl
              sx={{ width: "100%", my: 1 }}
              variant="standard"
              error={
                !!props.form_error.repeat_password &&
                props.form_toutch.repeat_password
              }
            >
              <InputLabel
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                  textTransform: "capitalize",
                }}
                htmlFor="repeat_password"
              >
                repeat password
              </InputLabel>
              <Input
                sx={{
                  color: customTheme.palette.neutrals.mlight,
                }}
                onChange={(e) => props.handler_OnChange(e)}
                id="repeat_password"
                name="repeat_password"
                type={props.show_repeat_password ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={props.handler_ClickShowRepeatPassword}
                      onMouseDown={props.handler_MouseDownPassword}
                    >
                      {props.show_repeat_password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                onKeyDown={handler_OnKeyDown}
              />
              {!!props.form_error.repeat_password &&
                props.form_toutch.repeat_password && (
                  <AlertComponent>
                    {props.form_error.repeat_password}
                  </AlertComponent>
                )}
            </FormControl>
            <Button sx={{ backgroundColor: customTheme.palette.primaryy.main, color: customTheme.palette.neutrals.with, marginX: "6px", '&:hover': { backgroundColor: customTheme.palette.primaryy.dark }, my: 1 }} onClick={props.handler_OnSubmit} fullWidth size="large" variant="contained" disabled={!(props.form_data.password)}>Reset Password</Button>
          </>
        )}
      </CardPrime>
    </div>
  );

}