import { CardComponent } from "../Card"
import { BoddyTwo, HeaderFour, HeaderSix } from "../Headers/Headers";
import classes from "./quizcard.module.scss";
import classNames from "classnames";
import { AgreeButton, DisAgreeButton } from "../Buttons/Button";
import { CardPrimeComponent } from "../CardPrime";
import { CardPrime } from "../CardPrime/view";

interface IModel {
    type?: string;
    header?: string;
    text?: string;
    handler_toggleModal?: any;
    handler_onAgree?: any;
    handler_onDisAgree?: any
}

export const View = (props: IModel) => {
    return (
        <div className={classNames(classes.container)}>
            <CardPrime>
                <div className={classNames(classes.quizcard_container)} >
                    <div className={classNames(
                        classes.quizcard_item,
                        classes.quizcard_item_text,
                        props.type === "identify"
                            ? classes.quizcard_item_text_identify
                            : props.type === "detect"
                                ? classes.quizcard_item_text_detect
                                : props.type === "respond"
                                    ? classes.quizcard_item_text_respond
                                    : props.type === "recover"
                                        ? classes.quizcard_item_text_recover
                                        : props.type === "protect"
                                            ? classes.quizcard_item_text_protect
                                            : ""
                    )}
                    >
                        <HeaderFour variant="h4"
                            className={classNames(
                                classes.header_four,
                                props.type === "identify"
                                    ? classes.text_color_identify
                                    : props.type === "detect"
                                        ? classes.text_color_detect
                                        : props.type === "respond"
                                            ? classes.text_color_respond
                                            : props.type === "recover"
                                                ? classes.text_color_recover
                                                : props.type === "protect"
                                                    ? classes.text_color_protect
                                                    : ""
                            )}

                        >Quiz time!</HeaderFour>
                        <div className={classNames(classes.header_six_container)}>
                            <HeaderSix
                                className={classNames(
                                    classes.header_six,
                                    props.type === "identify"
                                        ? [classes.text_color_identify, classes.text_shadow_color_identify]
                                        : props.type === "detect"
                                            ? [classes.text_color_detect, classes.text_shadow_color_detect]
                                            : props.type === "respond"
                                                ? [classes.text_color_respond, classes.text_shadow_color_respond]
                                                : props.type === "recover"
                                                    ? [classes.text_color_recover, classes.text_shadow_color_recover]
                                                    : props.type === "protect"
                                                        ? [classes.text_color_protect, classes.text_shadow_color_protect]
                                                        : ""
                                )}
                                variant="h6"
                            >{props.type} : {props.header}
                            </HeaderSix>
                            <BoddyTwo variant="body2"
                                className={classNames(
                                    classes.price,
                                )}
                            >+/-10</BoddyTwo>
                        </div>
                        <BoddyTwo
                            className={classNames(
                                classes.body_two,
                                props.type === "identify"
                                    ? classes.text_color_identify
                                    : props.type === "detect"
                                        ? classes.text_color_detect
                                        : props.type === "respond"
                                            ? classes.text_color_respond
                                            : props.type === "recover"
                                                ? classes.text_color_recover
                                                : props.type === "protect"
                                                    ? classes.text_color_protect
                                                    : ""
                            )}
                            variant="body2" >
                            {props.text}
                        </BoddyTwo>
                    </div>
                    <div className={classNames(classes.quizcard_item, classes.quizcard_item_button)} >
                        <AgreeButton onClick={props.handler_onAgree}>true</AgreeButton>
                        <DisAgreeButton onClick={props.handler_onDisAgree}>false</DisAgreeButton>
                    </div>
                </div>
            </CardPrime>
        </div>
    )
}