import classNames from "classnames";
import classes from "./serverdown.module.scss";
import { BoddyTwo, HeaderFour, HeaderSix, HeaderThree, HeaderTwo } from "../../components/Headers/Headers";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons/Button";
import { customTheme } from "../../theme/muconfig";
import { useNavigate } from "react-router-dom";

interface IModel{
    err_number?:any
}

export const ServerDownComponent = (props: IModel) => {
    const navigate = useNavigate();
    const handler_onBacktoStart = () => {
        localStorage.removeItem('countdownTime');
        localStorage.removeItem('countdownTimestamp');
        localStorage.removeItem('playerInfo');
        navigate("/");
    }
    return <div className={classNames(classes.container)}>
        <HeaderThree sx={{ my: 2 }} color={customTheme.palette.neutrals.with} variant="h3" >Error {props.err_number} </HeaderThree>
        <img src="/images/Errors/server_error.svg" />
        <HeaderSix sx={{ my: 1 }} color={customTheme.palette.neutrals.with} variant="h6" >server error</HeaderSix>
        <BoddyTwo sx={{ my: 2}} color={customTheme.palette.neutrals.with} variant="body2">We’re working on it to get back to you soon..</BoddyTwo>
        <PrimaryButton onClick={() => handler_onBacktoStart()}>Back to start</PrimaryButton>
    </div>
}