import { useFormik } from "formik";
import * as Yup from "yup";
import { IModel, IFormData } from "./model";
import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { apiclients } from "../../serviceclients";

export const useContainer = (props: any) => {

  const navigate = useNavigate();

  const [is_clicked, set_is_clicked] = React.useState("");
  const [error_page, set_error_page] = React.useState("");
  const [show_modal, set_show_modal] = useState(false);

  const initialFormState: IFormData = {
    nick_name: "",
    id: ""
  };

  const handler_toggleModal = () => {
    set_show_modal(prev => !prev);
  }

  const handler_OnChoosePlayer = (type: string) => {
    formik.setFieldValue('id', type);
    set_is_clicked(type)
  };


  const handler_onChange = (e: any) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };

  const formik = useFormik<IFormData>({
    initialValues: initialFormState,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      nick_name: Yup.string()
        .min(2, "Too Short!")
        .max(15, "Too Long!")
        .required("Required"),
      id: Yup.string().required("Required"),

    }),
    onSubmit: (values, { resetForm }) => handleSubmit(values, { resetForm }),
  });

  const handleSubmit = (
    values: IFormData,
    { resetForm }: { resetForm: () => void }
  ) => {
    apiclients().SetNickName(values)
      .then((result: any) => {
        const room_id = result.api_result.room_id;
        props.set_show_nickname_page(false)
        setTimeout(() => {
          navigate(`/board/${room_id}`, { replace: true }); // Redirect to the appropriate page after a delay
        }, 1000);

      })
      .catch((error: any) => {
        const res_err = error.response.data.detail || (error.response.data[0] && error.response.data[0].detail) || 'Unknown error';
        set_error_page(res_err);
        handler_toggleModal()
      })

    resetForm();
  };



  return {
    form_data: formik.values,
    form_error: formik.errors,
    form_toutch: formik.touched,
    handler_OnChoosePlayer,
    handler_onChange,
    handler_onSubmit: formik.handleSubmit,
    is_clicked,
    error_page,
    show_modal
  }

}
