import { useNavigate } from "react-router-dom"
import { HeaderFour, HeaderSix, HeaderThree } from "../../components/Headers/Headers"
import { customTheme } from "../../theme/muconfig"
import classes from "./generalerror.module.scss"
import classNames from "classnames"

interface IModel {
    text_error?: any
}

export const View = (props: IModel) => {

    return <div className={classNames(classes.container)}>
        <HeaderThree color={customTheme.palette.accent_one.light} sx={{ my: 1 }} variant="h3">
            Error
        </HeaderThree>
        <div className={classNames(classes.error_image_container)}>
            <img src="/images/Errors/generalerr.svg" />
        </div>
        <HeaderFour sx={{ my: 1 }} color={customTheme.palette.accent_one.light} textTransform={"capitalize"} variant="h4">
            {props.text_error ? props.text_error : "error happend"}
        </HeaderFour>
        <HeaderSix sx={{ my: 1 }} color={customTheme.palette.accent_one.light} variant="h6">
            some error happen please refresh page .
        </HeaderSix>
    </div>
}