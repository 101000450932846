import { useNavigate } from "react-router-dom";

export const useContainer = (props: any) => {
    const navigate = useNavigate();
    const handler_onBackToLobbyPage = () => {
        navigate("/lobby");
    }
    return {
        handler_onBackToLobbyPage
    }
}