import classNames from "classnames";
import classes from "./privacy.module.scss";
import { Header } from "../HeaderContent";
import { Footer } from "../FooterContent";
import { useRef } from "react";
import { BoddyOne, HeaderFiveTwo, HeaderFour, HeaderSix } from "../../../components/Headers/Headers";
import { List, ListItem } from "@mui/material";
import { customTheme } from "../../../theme/muconfig";
import { PrimaryButton } from "../../../components/Buttons/Button";

export const View = (props: any) => {
    const top = useRef(null);
    const scrollToSection = (elementRef: any) => {
        elementRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    return <div className={classNames(classes.parent)} ref={top}>
        <Header show_link={false} not_show_signin_btn={true} />
        <div className={classNames(classes.container)}>
            <HeaderFour variant="h4" color={customTheme.palette.neutrals.xxxxllight}>
                Privacy Policy for CyberSprint
            </HeaderFour>
            <BoddyOne color={customTheme.palette.neutrals.xxxxllight} sx={{ py: 2 }}>
                Welcome to CyberSprint! EduGames Inc. ("us", "we", or "our") operates the CyberSprint online educational game ("Service"). This privacy policy is crafted in compliance with the General Data Protection Regulation (GDPR) and aims to inform you of your privacy rights and how the law protects you while you use our Service.
            </BoddyOne>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                1-Information Collection and Use
            </HeaderFiveTwo>
            <BoddyOne color={customTheme.palette.neutrals.xxxxllight} sx={{ py: 2 }}>
                We collect various types of personal information for various purposes to provide and improve our Service to you. The basis for processing your personal data includes providing a contracted service, complying with legal obligations, protecting the public interest, and our legitimate interests unless overridden by your rights and interests.
            </BoddyOne>
            <HeaderSix color={customTheme.palette.neutrals.xxxxllight}>
                Personal Data Collected:
            </HeaderSix>
            <List color={customTheme.palette.neutrals.xxxxllight}>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Personal Identification Information
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Name
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Email address
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  company name
                    </BoddyOne>
                </ListItem>
            </List>
            <HeaderSix color={customTheme.palette.neutrals.xxxxllight}>
                Usage Data:
            </HeaderSix>
            <List color={customTheme.palette.neutrals.xxxxllight}>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Internet Protocol address (IP address)
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Browser type and version
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Pages of our Service that you visit
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Time and date of your visit
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Time spent on those pages
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Unique device identifiers and other diagnostic data
                    </BoddyOne>
                </ListItem>
            </List>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight} >
                2-Legal Basis for Processing Personal Data Under GDPR
            </HeaderFiveTwo>
            <BoddyOne color={customTheme.palette.neutrals.xxxxllight} sx={{ py: 2 }}>
                We process your personal data under the following bases:
            </BoddyOne>
            <List color={customTheme.palette.neutrals.xxxxllight}>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Consent: You have given clear consent for us to process your personal data for a specific purposeInternet Protocol address (IP address)
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226; Consent: The processing is necessary for a contract you have with us, or because we have been asked to take specific steps before entering into a contract.
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Legal Obligation: The processing is necessary for us to comply with the law (not including contractual obligations).
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Vital Interests: The processing is necessary to protect someone’s life.
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Public Task: The processing is necessary for us to perform a task in the public interest or for our official functions, and the task or function has a clear basis in law.
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Legitimate Interests: The processing is necessary for our legitimate interests or the legitimate interests of a third party unless there is a good reason to protect the individual’s personal data which overrides those legitimate interests.
                    </BoddyOne>
                </ListItem>
            </List>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                3. Data Retention
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                We will retain your personal data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.
            </BoddyOne>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                4. Data Transfer
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
            </BoddyOne>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                5. Data Rights
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                Under GDPR, you have various rights in relation to your personal data:

            </BoddyOne>
            <List color={customTheme.palette.neutrals.xxxxllight}>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Right to access: You have the right to request copies of your personal data.
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226; Right to rectification: You have the right to request correction of any information you believe is inaccurate or incomplete.
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Right to erasure: You have the right to request that we erase your personal data under certain conditions.
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Right to restrict processing: You have the right to request that we restrict the processing of your personal data under certain conditions
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Right to object to processing: You have the right to object to our processing of your personal data under certain conditions.
                    </BoddyOne>
                </ListItem>
                <ListItem>
                    <BoddyOne variant="body1" color={customTheme.palette.neutrals.xxxxllight} >
                        &#8226;  Right to data portability: You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                    </BoddyOne>
                </ListItem>
            </List>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                6. Data Protection Officer
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                We have appointed a Data Protection Officer (DPO) who is responsible for overseeing questions in relation to this privacy notice. If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact the DPO at dpo@edugames.com.
            </BoddyOne>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                7. Changes to This Privacy Policy
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "effective date" at the top of this policy.
            </BoddyOne>
            <HeaderFiveTwo color={customTheme.palette.neutrals.xxxxllight}>
                8. Contact Us
            </HeaderFiveTwo>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at azadeh.haratian@gmail.com.
            </BoddyOne>
            <BoddyOne sx={{ py: 2 }} color={customTheme.palette.neutrals.xxxxllight}>
                By using our Service, you acknowledge that you have read and understood this privacy policy and agree to its terms.
            </BoddyOne>

            <PrimaryButton onClick={() => props.handler_onBackToLobbyPage()} exteraStyle={{ margin: "32px 0px" }} >
                Back to lobby
            </PrimaryButton>
        </div>
        <Footer />
        <div className={classNames(classes.back_top_btn)}>
            <button onClick={() => scrollToSection(top)}>
                <img className={classNames(classes.back_top_btn_img)} src="/images/BackTopIcone/backtotop.svg" alt="top" />
            </button>
        </div>
    </div>
}