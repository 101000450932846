import { customTheme } from "../../../theme/muconfig"
import { HeaderFivee } from "../../../components/Headers/Headers";
import { PrimaryButton } from "../../../components/Buttons/Button";
import classNames from "classnames";
import classes from "./pickavatarstyle.module.scss";
import { AlertComponent } from "../../../components/Error";
import { CardPrime } from "../../../components/CardPrime/view";

export const View = (props: any) => {
   let arr = ["red", "green", "blue", "yellow", "purple", "pink"]
   return <div className={classes.container} >
      <CardPrime show_close_icon={props.show_close_icon} handler_toggleModal={props.handler_toggleModal}>
         <HeaderFivee variant="h5" sx={{ color: customTheme.palette.primaryy.light }}>pick your avatar</HeaderFivee>
         <div className={classNames(classes.pick_avatar_item)} >
            {
               arr.map((item: any, i: any) => (
                  <div
                     className={
                        classNames(
                           `${classes.avatar} ${item === "purple"
                              ? `${classes.purple}`
                              : item === "blue"
                                 ? `${classes.blue}`
                                 : item === "yellow"
                                    ? `${classes.yellow}`
                                    : item === "pink"
                                       ? `${classes.pink}`
                                       : item === "red"
                                          ? `${classes.red}`
                                          : item === "green"
                                             ? `${classes.green}` : "green"
                           } ${props.is_clicked === item && `${classes.is_clicked}`}`)
                     }

                     key={i} onClick={() => props.handler_OnChooseAvatar(item)}>

                     <img style={{ width: "100%" }} src={`/images/Avatars/${item}.svg`} alt={`${item}`} />
                  </div>
               ))
            }
         </div>
         {
            props.error && <AlertComponent extera_style={{ marginBottom: "16px", width: "100%" }} children={props.error} />
         }
         <PrimaryButton onClick={() => props.handler_onSaveAvatarSelected()} >
            save changes
         </PrimaryButton>
      </CardPrime>
   </div>
}