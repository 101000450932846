import { Button, Checkbox, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Modal, Typography } from "@mui/material";
import { customTheme } from "../../../theme/muconfig";
import { CardHeader } from "../../../components/CardHeader";
import { CardContents } from "../../../components/CardContents";
import { AlertComponent } from "../../../components/Error";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Paragraph } from "../../../components/Paragraph";
import { LinkComponent } from "../../../components/LinkButtonComponent";
import { CardPrimeComponent } from "../../../components/CardPrime";
import { CardPrime } from "../../../components/CardPrime/view";
import { BoddyOne, BoddyTwo } from "../../../components/Headers/Headers";
import classNames from "classnames";
import classes from "./signin.module.scss";

export const View = (props: any) => {

  const handler_OnKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission behavior
      props.handleSubmit();
    }
  };
  return (
    <div className={classNames(classes.container)}>
      <CardPrime show_close_icon={props.show_close_icon} handler_toggleModal={props.handler_toggleModal} >
        <CardHeader paragraph={"Login to your account and start playing."} header={"welcome to CyberSprint!"} ></CardHeader>
        <CardContents>
          <FormControl
            sx={{ width: "100%", my: 1 }}
            variant="standard"
            error={(!!props.form_error.email && props.form_toutch.email)}
          >
            <InputLabel
              sx={{
                color: customTheme.palette.neutrals.mlight,
                textTransform: "capitalize",
              }}
              htmlFor="email"
            >
              Email
            </InputLabel>
            <Input
              sx={{
                color: customTheme.palette.neutrals.mlight,
                textTransform: "capitalize",
              }}
              onChange={(e) => props.handleChange(e)}
              type="email"
              id="email"
              name="email"
            />
            {!!props.form_error.email && props.form_toutch.email && (
              <AlertComponent>{props.form_error.email}</AlertComponent>
            )}
          </FormControl>
          <FormControl
            sx={{ width: "100%", my: 1 }}
            variant="standard"
            error={
              !!props.form_error.password && props.form_toutch.password
            }
          >
            <InputLabel
              sx={{
                color: customTheme.palette.neutrals.mlight,
                textTransform: "capitalize",
              }}
              htmlFor="password"
            >
              Password
            </InputLabel>
            <Input
              sx={{
                color: customTheme.palette.neutrals.mlight,
              }}
              onChange={(e) => props.handleChange(e)}
              id="password"
              name="password"
              type={props.show_password ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={props.handler_ClickShowPassword}
                    onMouseDown={props.handler_MouseDownPassword}
                  >
                    {props.show_password ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              onKeyDown={handler_OnKeyDown}
            />
            {!!props.form_error.password &&
              props.form_toutch.password && (
                <AlertComponent>
                  {props.form_error.password}
                </AlertComponent>
              )}
          </FormControl>
          <LinkComponent handler_onRegister={() => props.handler_onRegister("forgotpass")} justifyContent="end" link={"/forgotpassword"} >
            forgot password
          </LinkComponent>
          <Grid container sx={{ justifyContent: "flex-start", width: "100%", alignItems: "center", }}>
            <Checkbox />
            <BoddyOne sx={{ textTransform: 'capitalize', color: customTheme.palette.neutrals.xlligth }}>Remember me</BoddyOne>
          </Grid>
        </CardContents>

        <Button onClick={props.handleSubmit} fullWidth size="medium" variant="contained" sx={{ backgroundColor: customTheme.palette.primaryy.main, color: customTheme.palette.neutrals.with, marginX: "6px", '&:hover': { backgroundColor: customTheme.palette.primaryy.dark }, my: 1 }} disabled={!(props.form_data.password && props.form_data.email)}>login to play!</Button>

        <Grid container sx={{ alignItems: "center", justifyContent: "center", my: 1 }}>
          <Grid item sx={{ mx: 1 }}><Paragraph>Don't have an account ?</Paragraph></Grid>
          <Grid item sx={{ mx: 1 }}>
            <LinkComponent handler_onRegister={() => props.handler_onRegister("signUp")}  >
              Sign up
            </LinkComponent>
          </Grid>
        </Grid>
      </CardPrime>
    </div>
  );
};

