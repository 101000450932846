import * as React from "react";
import { IModel, IFormDataModel, IApiCallModel, IConfrimEmailChangeEmailModel, IVerifyCodeChangeEmailModel } from "./model";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Constants from "../../../constants/constants";
import { apiclients } from "../../serviceclients/apiclients";
import { ErrorResult } from "../../../module-errores/error/container";


export const useContainer = (props: any): IModel => {
  let handler_toggleModal = props.handler_toggleModal;
  let show_close_icon = props.show_close_icon;
  const initial_form_state: IFormDataModel = {
    verify_code: "",
    email: ""
  }
  const [active_step, setactive_step] = React.useState(1);
  const [confrim_schema, set_confrim_schema] = React.useState(
    Yup.object().shape({})
  );
  const [show_password, setshow_password] = React.useState(false);
  const [show_repeat_password, setshow_repeat_password] = React.useState(false);
  const [show_error, set_show_error] = React.useState("");

  const handler_ClickShowPassword = () => setshow_password((show) => !show);

  const handler_MouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };


  const handler_ClickShowRepeatPassword = () =>
    setshow_repeat_password((show) => !show);

  const handler_MouseDownRepeatPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };


  // Count Down
  const initial_time = 120; // Initial countdown time in seconds
  const [time_remaining, settime_remaining] = React.useState(0);

  // Convert seconds to a display format (e.g., "00:10")
  const handler_FormatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  React.useEffect(() => {
    // Function to update the timer every second
    const updateTimer = () => {
      if (time_remaining > 0) {
        settime_remaining(time_remaining - 1);
      }
    };

    // Set up an interval to call the updateTimer function every second
    const intervalId = setInterval(updateTimer, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [time_remaining]);

  React.useEffect(() => {
    settime_remaining(initial_time)
  }, [])

  React.useEffect(() => {
    if (active_step === 1) {
      set_confrim_schema(
        Yup.object().shape({
          verify_code: Yup.number()
            .typeError("Input must be a number") // Display this message if it's not a number
            .test(
              "is-six-digits",
              "Number must have exactly 6 digits",
              (value) => {
                if (value === undefined) return true; // Skip validation if the value is not provided
                const stringValue = value.toString(); // Convert the number to a string
                return stringValue.length === 6; // Check if it has 6 digits
              }
            )
            .required("Number is required"),

        })
      );
    } else if (active_step === 2) {
      set_confrim_schema(
        Yup.object().shape({
          email: Yup.string()
            .matches(Constants.REGEX_EMAIL, "invalid email address")
            .required(""),
        })
      );
    }
  }, [active_step]);


  const ApiCalles = (): IApiCallModel => {

    const ResendCodeChangeEmail = () => {
      apiclients().ResendCodeChangeEmailRequest()
        .then((command_result: any) => {
          console.log(command_result)
        })
        .catch((error_result: any) => {
          console.log(error_result)
        })
    }

    const ConfrimIdentityChangeEmailRequest = (values: IVerifyCodeChangeEmailModel, { setErrors }: { setErrors: any }) => {
      let command_request = {
        token: values.verify_code!
      }
      confrim_schema.isValid(values).then((is_valid) => {
        if (is_valid) {
          return apiclients().ConfrimIdentityChangeEmailRequest(command_request)
        } else {
          console.log("not valid values")
        }
      }).then((command_result: any) => {
        if (command_result.api_result) {
          setactive_step((prev) => prev + 1)
        }
      })
        .catch((command_error: any) => {
          let error_variable = ErrorResult(command_error)
          setErrors({ verify_code: error_variable })
        })
    }

    const ChangeEmailRequest = (values: any, { setErrors }: { setErrors: any }) => {
      let command_request = {
        new_email: formik.values.email!,
        token: formik.values.verify_code!
      }
      confrim_schema.isValid(values).then((is_valid) => {
        if (is_valid) {
          return apiclients().ChangeEmailRequest(command_request)
        } else {
          console.log("not valid values")
        }
      }).then((command_result: any) => {
        window.location.reload();
      })
        .catch((command_error: any) => {
          let error_variable = ErrorResult(command_error)
          setErrors({ email: error_variable })
        })
    }

    return {
      ResendCodeChangeEmail,
      ConfrimIdentityChangeEmailRequest,
      ChangeEmailRequest,
    }
  }


  const handler_ResendCode = () => {
    settime_remaining(initial_time);
    ApiCalles().ResendCodeChangeEmail();
  };

  const formik = useFormik<IFormDataModel>({
    initialValues: initial_form_state,
    validationSchema: confrim_schema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (
      values: IFormDataModel
      , { setErrors }
    ) => {
      if (active_step === 1) {
        ApiCalles().ConfrimIdentityChangeEmailRequest(values, { setErrors });
      } else if (active_step === 2) {
        ApiCalles().ChangeEmailRequest(values, { setErrors });
      }

    }
  });


  const handler_OnChange = (e: any) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };




  return {
    active_step,
    show_password,
    show_repeat_password,
    time_remaining,
    show_error,
    form_data: formik.values,
    form_error: formik.errors,
    form_toutch: formik.touched,
    handler_OnSubmit: formik.handleSubmit,
    handler_OnChange,
    handler_FormatTime,
    handler_ClickShowPassword,
    handler_ClickShowRepeatPassword,
    handler_MouseDownPassword,
    handler_MouseDownRepeatPassword,
    handler_ResendCode,
    handler_toggleModal,
    show_close_icon
  };
};
