import { API, APIPrivate } from "../../apis";
import { ISetNickNameApiRequestModlel, ISetNickNameCommandRequestModlel, } from "../../models";


interface IAPICall {
  CheckRegisterated: Function;
  GameStart: Function;
  SetNickName: Function;
  GetAvatarProfile:Function;
}



export const apiclients = (): IAPICall => {

  const GameStart = async () => {

    const token = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')!) : null;

    try {
      const verify_access = await API.post("accounts/verify/", JSON.stringify({ token: token.access }));

      if (verify_access.status == 200) {
        try {
          const verify_refresh = await API.post("accounts/refresh/", JSON.stringify({ refresh: token.refresh }));
          if (verify_refresh.status == 200) {
            let obj = { "refresh": token.refresh, "access": verify_refresh.data.access }
            localStorage.removeItem('authTokens');
            localStorage.setItem("authTokens", JSON.stringify(obj));
            return {
              api_result: true
            }
          }
        } catch (error) {
          localStorage.removeItem('authTokens');
          return {
            api_result: false,
          }
        }
      }

    } catch (error) {
      try {
        const verify_refresh = await API.post("accounts/refresh/", JSON.stringify({ refresh: token.refresh }));

        if (verify_refresh.status == 200) {
          let obj = { "refresh": token.refresh, "access": verify_refresh.data.access }
          localStorage.removeItem('authTokens');
          localStorage.setItem("authTokens", JSON.stringify(obj));
          return {
            api_result: true
          }
        }
      }
      catch (error) {
        localStorage.removeItem('authTokens');

        return {
          api_result: false
        }
      }
    }

  }

  const SetNickName = async (command_params: ISetNickNameCommandRequestModlel) => {

    let api_request: ISetNickNameApiRequestModlel = {
      p1_nickname: command_params.nick_name,
      p1_color: command_params.id,
    }
    try {
      const create_room = await APIPrivate.post("gameroom/create/", JSON.stringify(api_request));
      if (create_room.status == 201) {
        localStorage.setItem("playerInfo", JSON.stringify(create_room.data));
        return {
          api_result: create_room.data,
        }
      }
    } catch (error) {
      throw error;
    }
  }

  const CheckRegisterated = async (api_request: any) => {
    try {
      const verify_access = await API.post("accounts/verify/", JSON.stringify({ token: api_request.access }));

      if (verify_access.status == 200) {
        try {
          const verify_refresh = await API.post("accounts/refresh/", JSON.stringify({ refresh: api_request.refresh }));
          if (verify_refresh.status == 200) {
            let obj = { "refresh": api_request.refresh, "access": verify_refresh.data.access }
            localStorage.removeItem('authTokens');
            localStorage.setItem("authTokens", JSON.stringify(obj));
            return {
              api_result: true
            }
          }
        } catch (error) {
          localStorage.removeItem('authTokens');
          return {
            api_result: false,
          }
        }
      }

    } catch (error) {
      try {
        const verify_refresh = await API.post("accounts/refresh/", JSON.stringify({ refresh: api_request.refresh }));

        if (verify_refresh.status == 200) {
          let obj = { "refresh": api_request.refresh, "access": verify_refresh.data.access }
          localStorage.removeItem('authTokens');
          localStorage.setItem("authTokens", JSON.stringify(obj));
          return {
            api_result: true
          }
        }
      }
      catch (error) {
        localStorage.removeItem('authTokens');

        return {
          api_result: false
        }
      }
    }
  }

  const GetAvatarProfile = async () => {
    try {
      const avatar_data = await APIPrivate.get("accounts/avatar/");
      if (avatar_data.status == 200) {
        return {
          api_result: avatar_data.data,
        }
      }
    } catch (error) {
      throw error;
    }
  }



  return {
    GameStart,
    SetNickName,
    CheckRegisterated,
    GetAvatarProfile,
  }

}
