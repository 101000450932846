import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import { apiclients } from "../../serviceclients"
import { useNavigate, useParams } from "react-router-dom";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useSoundStore } from "../../../zustand/sound-store";
import moment from 'moment-timezone';

export const useContainer = (props: any) => {
    const board_game = [
        {
            row: 1,
            col: 1,
            type: 'block',
            content: { type: "start" }
        },
        {
            row: 1,
            col: 2,
            type: 'function',
            content: { type: "identify", header: "id.am", price: "700" }
        },
        {
            row: 1,
            col: 3,
            type: 'function',
            content: { type: "identify", header: "id.be", price: "250" }
        },
        {
            row: 1,
            col: 4,
            type: 'block',
            content: { type: "services", header: "buy services", price: "-50" }
        },
        {
            row: 1,
            col: 5,
            type: 'function',
            content: { type: "identify", header: "id.gv", price: "200" }
        },
        {
            row: 1,
            col: 6,
            type: 'block',
            content: { type: "repair", header: "repair", price: "-50" }
        },
        {
            row: 1,
            col: 7,
            type: 'block',
            content: { type: "mystery" }
        },
        {
            row: 1,
            col: 8,
            type: 'function',
            content: { type: "identify", header: "id.ra", price: "840" }
        },
        {
            row: 1,
            col: 9,
            type: 'function',
            content: { type: "identify", header: "id.rm", price: "150" }
        },
        {
            row: 1,
            col: 10,
            type: 'block',
            content: { type: "services", header: "buy services", price: "-50" }
        },
        {
            row: 1,
            col: 11,
            type: 'block',
            content: { type: "budget_freeze", header: "budget freeze" }
        },
        {
            row: 2,
            col: 11,
            type: 'function',
            content: { type: "identify", header: "id.sc", price: "300" }
        },
        {
            row: 3,
            col: 11,
            type: 'function',
            content: { type: "protect", header: "pr.ac", price: "1300" }
        },
        {
            row: 4,
            col: 11,
            type: 'function',
            content: { type: "protect", header: "pr.at", price: "350" }
        },
        {
            row: 5,
            col: 11,
            type: 'block',
            content: { type: "services", header: "buy services", price: "-50" }
        },
        {
            row: 6,
            col: 11,
            type: 'function',
            content: { type: "protect", header: "pr.ds", price: "1300" }
        },
        {
            row: 7,
            col: 11,
            type: 'function',
            content: { type: "protect", header: "pr.ip", price: "350" }
        },
        {
            row: 8,
            col: 11,
            type: 'block',
            content: { type: "repair", header: "repair", price: "-50" }
        },
        {
            row: 9,
            col: 11,
            type: 'function',
            content: { type: "protect", header: "pr.ma", price: "300" }
        },
        {
            row: 10,
            col: 11,
            type: 'block',
            content: { type: "mystery" }
        },
        {
            row: 11,
            col: 11,
            type: 'block',
            content: { type: "hacked", header: "hacked" }
        },
        {
            row: 11,
            col: 10,
            type: 'function',
            content: { type: "protect", header: "pr.pt", price: "1000" }
        },
        {
            row: 11,
            col: 9,
            type: 'function',
            content: { type: "detect", header: "de.ae", price: "450" }
        },
        {
            row: 11,
            col: 8,
            type: 'block',
            content: { type: "mystery" }
        },
        {
            row: 11,
            col: 7,
            type: 'function',
            content: { type: "detect", header: "de.cm", price: "850" }
        },
        {
            row: 11,
            col: 6,
            type: 'block',
            content: { type: "services", header: "buy services", price: "-150" }
        },
        {
            row: 11,
            col: 5,
            type: 'block',
            content: { type: "mystery" }
        },
        {
            row: 11,
            col: 4,
            type: 'function',
            content: { type: "detect", header: "de.dp", price: "400" }
        },
        {
            row: 11,
            col: 3,
            type: 'function',
            content: { type: "respond", header: "rs.rp", price: "50" }
        },
        {
            row: 11,
            col: 2,
            type: 'block',
            content: { type: "repair", header: "repair", price: "-50" }
        },
        {
            row: 11,
            col: 1,
            type: 'block',
            content: { type: "audit", header: "waiting for audit" }
        },
        {
            row: 10,
            col: 1,
            type: 'function',
            content: { type: "respond", header: "rs.co", price: "250" }
        },
        {
            row: 9,
            col: 1,
            type: 'function',
            content: { type: "respond", header: "rs.an", price: "500" }
        },
        {
            row: 8,
            col: 1,
            type: 'block',
            content: { type: "mystery" }
        },
        {
            row: 7,
            col: 1,
            type: 'function',
            content: { type: "respond", header: "rs.mi", price: "400" }
        },
        {
            row: 6,
            col: 1,
            type: 'function',
            content: { type: "respond", header: "rs.im", price: "200" }
        },
        {
            row: 5,
            col: 1,
            type: 'function',
            content: { type: "recover", header: "rc.rp", price: "50" }
        },
        {
            row: 4,
            col: 1,
            type: 'block',
            content: { type: "repair", header: "repair", price: "-100" }
        },
        {
            row: 3,
            col: 1,
            type: 'function',
            content: { type: "recover", header: "rc.im", price: "200" }
        },
        {
            row: 2,
            col: 1,
            type: 'function',
            content: { type: "recover", header: "rc.co", price: "300" }
        },
    ]

    const params = useParams();
    const params_url = params.id; //room_id
    const sound_store = useSoundStore();
    const [current_page, set_current_page] = useState('');
    const [show_modal, set_show_modal] = useState(false);
    const [show_counter_down, set_show_counter_down] = useState(false);
    const [number_dice, set_number_dice] = useState<any>({
        dice1: 0,
        dice2: 0
    });
    const [prev_number_dice, set_prev_number_dice] = useState<any>({
        dice1: 0,
        dice2: 0
    });

    const [link_copied, set_link_copied] = useState(false);
    const [call_kicked_out_timer, set_call_kicked_out_timer] = useState(false);
    const [error_interaction_message, set_error_interaction_message] = useState({});
    const [is_registered, set_is_registered] = useState(true);
    const [hacked_alert, set_hacked_alert] = useState("");
    const [role, set_role] = useState<string>("");
    const [players_state, set_players_state] = useState<Array<any>>([]);
    const [player_state, set_player_state] = useState<any>({});
    const [player_status, set_player_status] = useState("");
    const [winner_nickname, set_winner_nickname] = useState(null);
    const [function_card, set_function_card] = useState<any>({});
    const [quiz_card, set_quiz_card] = useState<any>({});
    const [board_game_squares, set_board_game_squares] = useState<Array<any>>(board_game);
    const pawns_container = useRef<HTMLDivElement>(null);
    const [profile_avatar, set_profile_avatar] = useState("");
    const [is_animating, set_is_animating] = useState(false);
    const [show_modal_two, set_show_modal_two] = useState(false);
    const navigate = useNavigate();
    const [prev_players_state, set_prev_players_state] = useState<Array<any>>([]);
    const [refuse, set_refuse] = useState(0);
    const [show_rent_payed_hired_gift, set_show_rent_payed_hired_gift] = useState(false);
    const [successed_failed, set_successed_failed] = useState("");
    const [active_light_modal, set_active_light_modal] = useState(false);
    const [show_buy_services_repair, set_show_buy_services_repair] = useState(false);
    const [winner_loser_player_status, set_winner_loser_player_status] = useState("");
    const [services_text, set_services_text] = useState("");
    const [showHackedAlert, setShowHackedAlert] = useState(true);
    const [move_dice_rolling, set_move_dice_rolling] = useState(false);
    const [expire_time_room, set_expire_time_room] = useState<any>();
    const [expire_time_player, set_expire_time_player] = useState<any>();
    const [time, setTime] = useState('');
    const [previous_money, set_previous_money] = useState<number>(0);
    const [is_mute, set_is_mute] = useState<boolean>(sound_store.mute);
    const player_info = localStorage.getItem("playerInfo") ? JSON.parse(localStorage.getItem("playerInfo")!) : false;
    const validate_token = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')!) : null;
    const storedTime = localStorage.getItem('countdownTime');
    const storedTimestamp = localStorage.getItem('countdownTimestamp');
    //Roll Dice
    const [rotationDiceOne, setRotationDiceOne] = useState({ x: -310, y: -362, z: -38, degx: -120, degy: 0 });
    const [rotationDiceTwo, setRotationDiceTwo] = useState({ x: -310, y: -362, z: -38, degx: -120, degy: 0 });
    const [shaking_dice_one_animation, set_shaking_dice_one_animation] = useState(false);
    const [shaking_dice_two_animation, set_shaking_dice_two_animation] = useState(false);
    const [prevRandomAngleStateDiceOne, setPrevRandomAngleStateDiceOne] = useState(0);
    const [prevRandomAngleStateDiceTwo, setPrevRandomAngleStateDiceTwo] = useState(0);
    const angleArray = [
        [-310, -362, -38, -120, 0],
        [-400, -320, -2, 370, -100],
        [135, -217, -88, 20, 200],
        [-224, -317, 5, 400, 100],
        [-47, -219, -81, 200, -80],
        [-133, -360, -53, 700, 390]
    ];

    const startAnimation = (diceOne: any, diceTwo: any) => {
        const randomAngleDiceOne = diceOne;
        const randomAngleDiceOneTwo = randomAngleDiceOne !== 1 ? randomAngleDiceOne - 1 : 0;
        const randomAngleDiceTwo = diceTwo;
        const randomAngleDiceTwoTwo = randomAngleDiceTwo !== 1 ? randomAngleDiceTwo - 1 : 0;
        if (prevRandomAngleStateDiceOne !== randomAngleDiceOne) {
            setRotationDiceOne({
                x: angleArray[randomAngleDiceOneTwo][0],
                y: angleArray[randomAngleDiceOneTwo][1],
                z: angleArray[randomAngleDiceOneTwo][2],
                degx: angleArray[randomAngleDiceOneTwo][3],
                degy: angleArray[randomAngleDiceOneTwo][4],
            });
        }
        else {
            set_shaking_dice_one_animation(true);
            const randomAngleDiceOneTwoEqual = randomAngleDiceOneTwo == 0 ? 1 : randomAngleDiceOneTwo == 5 ? 4 : randomAngleDiceOneTwo - 1;

        }

        if (prevRandomAngleStateDiceTwo !== randomAngleDiceTwo) {
            setRotationDiceTwo({
                x: angleArray[randomAngleDiceTwoTwo][0],
                y: angleArray[randomAngleDiceTwoTwo][1],
                z: angleArray[randomAngleDiceTwoTwo][2],
                degx: angleArray[randomAngleDiceTwoTwo][3],
                degy: angleArray[randomAngleDiceTwoTwo][4],
            });
        }
        else {
            set_shaking_dice_two_animation(true);
            const randomAngleDiceTwoTwoEqual = randomAngleDiceTwoTwo == 0 ? 1 : randomAngleDiceTwoTwo == 5 ? 4 : randomAngleDiceTwoTwo - 1;

        }
        setPrevRandomAngleStateDiceOne(randomAngleDiceOne);
        setPrevRandomAngleStateDiceTwo(randomAngleDiceTwo);
    };


    const playAudio = (value: string) => {
        if (!sound_store.mute) {
            const audio = new Audio(`/sounds/${value}`);

            let playPromise = audio.play();
            if (playPromise !== undefined) {
                playPromise.then(_ => {
                })
                    .catch(error => {
                    });
            }
        }

    };

    //Modal
    const handler_toggleModal = () => {
        set_show_modal((prev: any) => !prev);
    }

    const handler_onCurrentPage = (props: any) => {
        setTimeout(() => {
            set_show_modal(true);
            set_current_page(props);

        }, 900)
    }

    const handler_toggleModal_two = () => {
        set_show_modal_two((prev: any) => !prev);
    }

    //copy input link
    const dynamicLink = `https://cybersprint.app/board/${params_url}`;

    async function copyTextToClipboard(text: any) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            throw new Error('Clipboard API not available');
        }
    }

    const handler_OnCopyLink = () => {
        copyTextToClipboard(dynamicLink)
            .then(() => {
                set_link_copied(true);
                setTimeout(() => {
                    set_link_copied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    //move pawn
    const [pawnPosition, setPawnPosition] = useState({ x: 0, y: 0, player_number: 0 });
    const [currentPawnPosition, setcurrentPawnPosition] = useState({ x: 0, y: 0, player_number: 0 });

    const current_position = (row: any, col: any) => {
        let p = { x: 0, y: 0 }
        for (let i = 1; i < 12; i++) {
            if (row == i) {
                p.y = i - 1
            }
        }
        for (let j = 1; j < 12; j++) {
            if (col == j) {
                p.x = j - 1
            }
        }
        return p
    }

    const player_location = (location: any, size: any) => {
        let row = 1;
        let col = 1;
        let i = 1;
        while (i < location) {
            if (i < size) {
                col++;
            } else if (i < 2 * size - 1) {
                row++;
            } else if (i < 3 * size - 2) {
                col--;
            } else {
                row--;
            }

            i++
        }

        return [row, col]
    }

    const movePawn = (player_number: any, location: any, prev_location: any, status: any) => {

        const [current_row, current_col] = player_location(prev_location + 1, 11);
        const [next_row, next_col] = player_location(location + 1, 11);

        if (next_row === 11 && next_col == 1 && window.innerWidth < 425 && window.innerWidth >= 320) {
            setPawnPosition({
                x: -11,
                y: 33,
                player_number: player_number
            });
            setcurrentPawnPosition({
                x: 9,
                y: 0,
                player_number: player_number
            })
        } else {
            setPawnPosition({
                x: (next_col - current_col),
                y: (next_row - current_row),
                player_number: player_number
            });
            setcurrentPawnPosition({
                x: current_position(current_row, current_col).x,
                y: current_position(current_row, current_col).y,
                player_number: player_number
            })
        }

    };
    //move pawn


    //Socket

    const url = `${process.env.REACT_APP_SOCKET_URL}game_room/${params_url}/`;
    const { sendMessage, lastMessage, readyState } = useWebSocket(url, {
        onOpen: () => {
            sendMessage(JSON.stringify({ "command": "validate_auth_token", "player_auth_token": player_info.player_auth_token }))
        },
    });
    // 
    useEffect(() => {
        if (move_dice_rolling) {
            startAnimation(number_dice.dice1, number_dice.dice2);
        }
    }, [prev_number_dice, number_dice, move_dice_rolling]);


    useEffect(() => {
        if (lastMessage !== null) {
            const data_receved = JSON.parse(lastMessage.data);
            if (data_receved.error && data_receved.error === "You do not have enough money to buy") {
                set_error_interaction_message(data_receved);
                handler_toggleModal();
            } else if (data_receved.error && data_receved.error === "User authentication failed, given token is not valid") {
                localStorage.removeItem('countdownTime');
                localStorage.removeItem('countdownTimestamp');
                localStorage.removeItem('playerInfo');
                localStorage.removeItem('dice_number');
                window.location.reload()
            } else if (data_receved.error) {
                set_error_interaction_message(data_receved);
                set_current_page("error")
            } else if (data_receved.private.removed_player_token && data_receved.private.removed_player_token === player_info.player_auth_token) {
                localStorage.removeItem('playerInfo');
                props.set_show_nickname_page(true);
                window.location.reload();
            } else if (data_receved.private.status === 'kicked_out') {
                localStorage.removeItem('countdownTime');
                localStorage.removeItem('countdownTimestamp');
                set_show_counter_down(false);
            }
            else {
                set_error_interaction_message("")
                set_number_dice(data_receved.general.dice);
                set_role(player_info.role);
                set_players_state(data_receved.general.players);
                set_player_state(data_receved.private);
                if (data_receved.general.status === "game-started") {
                    const playerInTurn = data_receved.general.players.find((player: { is_turn: any; }) => player.is_turn);
                    if (playerInTurn.status !== "kicked_out") {
                        set_expire_time_player(playerInTurn.player_timeout);
                        set_call_kicked_out_timer(true);
                    } else {
                        set_call_kicked_out_timer(false);
                    }
                    if (playerInTurn && playerInTurn.location !== playerInTurn.prev_location && !player_state.end_turn && playerInTurn.status !== "freezed") {
                        set_move_dice_rolling(true);
                    } else if (playerInTurn && !player_state.end_turn && playerInTurn.status !== "freezed" && playerInTurn.money === 5000) {
                        set_move_dice_rolling(true);
                    } else {
                        set_move_dice_rolling(false);
                    }

                }

                set_prev_number_dice(number_dice);
                const selected_player = data_receved.general.players.find((value: any) => value.player_number === data_receved.private.player_number)
                if (selected_player && selected_player.money !== previous_money && !is_animating && !show_modal) {
                    playAudio('coin.mp3');
                    localStorage.setItem("player_money", selected_player.money)
                    set_previous_money(selected_player.money);
                }
                if (data_receved.private.message) {
                    setTimeout(() => {
                        set_services_text(data_receved.private.message)
                    }, 1500);
                } else {
                    set_services_text("")
                }

                if (data_receved.private.status === "rent_payed" || data_receved.private.status === "hired_gift") {
                    setTimeout(() => {
                        set_show_rent_payed_hired_gift(true);
                    }, 1300);
                    set_show_rent_payed_hired_gift(false);
                } else {
                    set_show_rent_payed_hired_gift(false);
                }

                set_player_status(data_receved.general.status);

                data_receved.general.players.map((item: any) => {
                    if (item.purchased_houses.length > 0) {
                        item.purchased_houses.map((i: any) => {
                            board_game_squares[i]["purchesed_color"] = item.color;
                        })
                    }
                })
                set_board_game_squares(board_game_squares);
                if (data_receved.private.status === "punished") {
                    setTimeout(() => {
                        set_hacked_alert(data_receved.private.response_text);
                    }, 1000);
                }
                if (data_receved.private.status === "ask_question") {
                    setTimeout(() => {
                        handler_onCurrentPage("quiz");
                        set_quiz_card(data_receved.private.question);
                    }, 1000);
                }
                if (data_receved.private.status === "buying_house") {
                    setTimeout(() => {
                        handler_onCurrentPage("function_card");
                        set_function_card(data_receved.private.card_obj);
                    }, 1000);
                }
                if (data_receved.private.status === "correct") {
                    set_active_light_modal(true)
                    set_successed_failed("correct");
                    handler_toggleModal_two();
                    playAudio('currect-answer.mp3')
                    setTimeout(() => {
                        handler_toggleModal_two();
                        handler_toggleModal();
                        set_active_light_modal(false);
                    }, 4000);
                }
                if (data_receved.private.status === "wrong") {
                    set_active_light_modal(true)
                    set_successed_failed("wrong");
                    handler_toggleModal_two();
                    playAudio('wrong-answer.mp3')
                    setTimeout(() => {
                        handler_toggleModal_two();
                        handler_toggleModal();
                        set_active_light_modal(false);
                    }, 4000);
                }
                if (data_receved.general.status === "game-finished") {
                    setTimeout(() => {
                        handler_onCurrentPage("game_finished");
                        playAudio('win.mp3')
                    }, 1000);
                    const check_winner = (item: any) => {
                        return item.status === "winner";
                    };
                    const winnerPlayer = data_receved.general.players.find(check_winner);
                    const winnerNickname = winnerPlayer ? winnerPlayer.nickname : undefined;
                    set_winner_nickname(winnerNickname);
                    set_winner_loser_player_status(data_receved.private.status);

                    localStorage.removeItem('quize');
                    localStorage.removeItem('countdownTime');
                    localStorage.removeItem('countdownTimestamp');
                    localStorage.removeItem('playerInfo');
                    localStorage.removeItem('dice_number');
                }

                if (showHackedAlert && data_receved.private.status === "punished" && data_receved.private.end_turn && data_receved.general.status === "game-started" && data_receved.private.location === 20) {
                    setShowHackedAlert(false);
                    setTimeout(() => {
                        set_current_page("hacked_alert");
                        handler_toggleModal_two();
                        playAudio('hacked-alert.mp3');
                    }, 1000);
                    setTimeout(() => {
                        handler_toggleModal_two();
                    }, 8000);
                }


            }

        }

    }, [lastMessage, showHackedAlert]);


    useEffect(() => {
        if (!validate_token) {
            set_is_registered(false)
        } else {
            apiclients().CheckRegisterated(validate_token)
                .then((command_result: any) => {
                    if (command_result.api_result) {
                        set_is_registered(true)
                        apiclients().GetAvatarProfile()
                            .then((command_result: any) => {
                                set_profile_avatar(command_result.api_result.avatar_color)
                            })
                    } else {
                        set_is_registered(false)
                    }
                })

        }
    }, []);



    const handler_onStartGame = () => {
        sendMessage(JSON.stringify({ "command": "start_the_game" }));
        set_show_counter_down(false);
        if (storedTime) {
            localStorage.removeItem('countdownTime');
        }
        if (storedTimestamp) {
            localStorage.removeItem('countdownTimestamp');

        }
    }

    const handler_OnhowToPlay = () => {
        set_show_modal(true);
        handler_onCurrentPage("howtoplay");
    }

    const handler_onRollingDice = () => {
        playAudio('roll-dice.mp3');
        sendMessage(JSON.stringify({ "command": "roll_the_dice" }));
        set_move_dice_rolling(true);
        set_show_counter_down(false);
        if (storedTime) {
            localStorage.removeItem('countdownTime');
        }
        if (storedTimestamp) {
            localStorage.removeItem('countdownTimestamp');
        }
    }

    const handler_onActionImpliment = () => {
        let time = 0
        sendMessage(JSON.stringify({ "command": "buy_card", "id": function_card.id }));
        set_is_animating(true);
        if (function_card.price > 1000) {
            time = 13000
        } else if (function_card.price > 900 && function_card.price <= 1000) {
            time = 10000
        }
        else if (function_card.price > 800 && function_card.price <= 900) {
            time = 9000
        }
        else if (function_card.price > 600 && function_card.price <= 700) {
            time = 9000
        }
        else if (function_card.price > 500 && function_card.price <= 600) {
            time = 8000
        }
        else if (function_card.price > 300 && function_card.price <= 500) {
            time = 7000
        }
        else if (function_card.price > 100 && function_card.price <= 300) {
            time = 6000
        }
        else if (function_card.price <= 100) {
            time = 5000
        } else {
            time = 10000
        }
        setTimeout(() => {
            handler_toggleModal();
            set_is_animating(false);
        }, time);
        set_refuse(0)
    }

    const handler_onActionRefuse = () => {
        if (refuse == 0) {
            set_refuse((counter: any) => counter + 1);
        }
        if (refuse == 1) {
            handler_toggleModal()
            set_refuse(0)
        }
    }

    const handler_onAgree = () => {
        sendMessage(JSON.stringify({ "command": "answer_question", "id": quiz_card.id, "answer": 1 }));
    }

    const handler_onDisAgree = () => {
        sendMessage(JSON.stringify({ "command": "answer_question", "id": quiz_card.id, "answer": 0 }));
    }

    const handler_onEndTurn = () => {
        sendMessage(JSON.stringify({ "command": "end_turn" }));
        set_show_counter_down(false);
        if (storedTime) {
            localStorage.removeItem('countdownTime');
        }
        if (storedTimestamp) {
            localStorage.removeItem('countdownTimestamp');
        }
    }

    const handler_onPayToken = () => {
        sendMessage(JSON.stringify({ "command": "pay_for_punishment" }))
        if (storedTime) {
            localStorage.removeItem('countdownTime');
        }
        if (storedTimestamp) {
            localStorage.removeItem('countdownTimestamp');
        }
    }

    const handler_onUseGiftCard = () => {
        sendMessage(JSON.stringify({ "command": "pay_by_gift_card" }))
        if (storedTime) {
            localStorage.removeItem('countdownTime');
        }
        if (storedTimestamp) {
            localStorage.removeItem('countdownTimestamp');
        }
    }

    const handler_onDobleRollingDice = () => {
        playAudio('roll-dice.mp3');
        sendMessage(JSON.stringify({ "command": "roll_for_punishment" }));
        set_show_counter_down(false);
        if (storedTime) {
            localStorage.removeItem('countdownTime');
        }
        if (storedTimestamp) {
            localStorage.removeItem('countdownTimestamp');
        }
    }

    const handler_OnPlayeAgain = () => {
        navigate("/");
    }

    const handler_RedirectLobby = () => {
        navigate("/lobby");
    }

    const onclick_sound = () => {
        set_is_mute(!is_mute)
        sound_store.set_mute(!sound_store.mute)
    }

    const handler_onRemovedPlayer = (player_number: any) => {
        sendMessage(JSON.stringify({ "command": "remove_user", "player_id": player_number }));
        props.set_show_nickname_page(true);
        window.location.reload();
    }

    useEffect(() => {
        const updateTime = () => {
            const tehranTime = moment().tz('Asia/Tehran').format('YYYY-MM-DD HH:mm:ss');
            setTime(tehranTime);
        };

        updateTime();
        const intervalId = setInterval(updateTime, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {

        if (player_status === "game-started" && call_kicked_out_timer) {
            const checkTimeDifference = () => {

                const currentMoment = moment(time, 'YYYY-MM-DD HH:mm:ss');
                const expireMoment = moment(expire_time_player, 'YYYY-MM-DD HH:mm:ss');
                const duration = moment.duration(expireMoment.diff(currentMoment));
                let minutes = duration.asMinutes();

                if (minutes < 2 && minutes > 0) {
                    set_show_counter_down(true);
                } else if (minutes <= 0) {
                    sendMessage(JSON.stringify({ "command": "expire_player" }));
                    localStorage.removeItem('countdownTime');
                    localStorage.removeItem('countdownTimestamp');
                    set_show_counter_down(false);
                    set_call_kicked_out_timer(false);
                    window.location.reload();
                }
            };

            checkTimeDifference();
            const intervalId = setInterval(checkTimeDifference, 1000);

            return () => clearInterval(intervalId);
        }

        set_call_kicked_out_timer(false);
        set_call_kicked_out_timer(false);

    }, [expire_time_player, call_kicked_out_timer, time])


    return {
        handler_OnCopyLink,
        dynamicLink,
        is_registered,
        role,
        players_state,
        handler_onStartGame,
        number_dice,
        error_interaction_message,
        player_state,
        player_status,
        handler_toggleModal,
        show_modal,
        current_page,
        handler_OnhowToPlay,
        handler_onRollingDice,
        function_card,
        handler_onActionImpliment,
        handler_onActionRefuse,
        handler_onEndTurn,
        board_game_squares,
        handler_onPayToken,
        handler_onUseGiftCard,
        handler_onDobleRollingDice,
        hacked_alert,
        quiz_card,
        prev_players_state,
        pawns_container,
        show_counter_down,
        winner_nickname,
        handler_OnPlayeAgain,
        handler_RedirectLobby,
        profile_avatar,
        handler_onAgree,
        handler_onDisAgree,
        pawnPosition,
        currentPawnPosition,
        refuse,
        is_animating,
        show_modal_two,
        handler_toggleModal_two,
        successed_failed,
        active_light_modal,
        rotationDiceOne,
        rotationDiceTwo,
        onclick_sound,
        is_mute,
        show_rent_payed_hired_gift,
        show_buy_services_repair,
        winner_loser_player_status,
        services_text,
        shaking_dice_one_animation,
        shaking_dice_two_animation,
        handler_onRemovedPlayer
    }

}
