import { Button } from "@mui/material";
import classes from "./cardprime.module.scss";
import classNames from "classnames";

interface IModel {
    children?: any;
    step?: any;
    exteraStyle?: any;
    handler_toggleModal?: any;
    show_close_icon?: any;
    how_play?: boolean;
}

export const CardPrime = (props: IModel) => {
    return (
        <div className={classNames(classes.container, props.how_play && classes.how_play)}
            style={{ ...props.exteraStyle }}
        >
            {props.show_close_icon &&
                <Button onClick={props.handler_toggleModal} className={classNames(classes.close_card)} >
                    <img src="/images/CloseIcon/close.svg" alt="close" />
                </Button>
            }
            {props.children}
        </div>
    );
};