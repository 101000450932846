import ReactDOM from 'react-dom';
import classes from "./lightermodalstyle.module.scss";
import classNames from 'classnames';

interface LighterModal {
    onBackDropClick?: () => void;
    show_modal: boolean;
    children?: any;
    currentPage?: any;
    z?: any;
    slowly_success?: any;
    successed_failed?: any;
}

export const LighterModal = (props: LighterModal) => {
    if (!props.show_modal) {
        return null
    }
    return ReactDOM.createPortal(
        <div className={classNames(classes.modal_container,
            props.slowly_success &&
            classes.modal_container_two, props.successed_failed === "correct" ? classes.successed_background : classes.failed_background
        )} style={{ zIndex: 100 }} onClick={e => e.stopPropagation()} >
            {props.children}
        </div>, document.getElementById('modal-root')!
    )
}