export const useContainer = (props:any) => {
    let handler_onAgree=props.handler_onAgree;
    let handler_onDisAgree=props.handler_onDisAgree;
    let type= props.type;
    let header= props.header;
    let text= props.text;


    return{
        handler_onAgree,
        handler_onDisAgree,
        type,
        header,
        text
    }

}