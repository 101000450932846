import { API, APIPrivate } from "../../apis";

interface IAPICall {
    CheckRegisterated: Function;
    GetBoardData:Function;
    GetAvatarProfile:Function;
}

export const apiclients = (): IAPICall => {

    const GetBoardData = async (api_request: any) => {
        try {
            const initial_gameroom = await APIPrivate.get("gameroom/initialize/")
            if (initial_gameroom.status == 200) {
                return {
                    api_result:initial_gameroom.data
                }
            }
        } catch (err) {
            console.log("err", err)
        }
    }

    const CheckRegisterated = async (api_request: any) => {
        try {
            const verify_access = await API.post("accounts/verify/", JSON.stringify({ token: api_request.access }));

            if (verify_access.status == 200) {
                try {
                    const verify_refresh = await API.post("accounts/refresh/", JSON.stringify({ refresh: api_request.refresh }));
                    if (verify_refresh.status == 200) {
                        let obj = { "refresh": api_request.refresh, "access": verify_refresh.data.access }
                        localStorage.removeItem('authTokens');
                        localStorage.setItem("authTokens", JSON.stringify(obj));
                        return {
                            api_result: true
                        }
                    }
                } catch (error) {
                    localStorage.removeItem('authTokens');
                    return {
                        api_result: false,
                    }
                }
            }

        } catch (error) {
            try {
                const verify_refresh = await API.post("accounts/refresh/", JSON.stringify({ refresh: api_request.refresh }));

                if (verify_refresh.status == 200) {
                    let obj = { "refresh": api_request.refresh, "access": verify_refresh.data.access }
                    localStorage.removeItem('authTokens');
                    localStorage.setItem("authTokens", JSON.stringify(obj));
                    return {
                        api_result: true
                    }
                }
            }
            catch (error) {
                localStorage.removeItem('authTokens');

                return {
                    api_result: false
                }
            }
        }
    }

    const GetAvatarProfile = async () => {
        try {
          const avatar_data = await APIPrivate.get("accounts/avatar/");
          if (avatar_data.status == 200) {
            return {
              api_result: avatar_data.data,
            }
          }
        } catch (error) {
          throw error;
        }
      }

    return {
        CheckRegisterated,
        GetBoardData,
        GetAvatarProfile
    }
}