import ReactDOM from 'react-dom';
import classes from "./modalstyle.module.scss";
import { Button } from '@mui/material';
import classNames from 'classnames';

interface BaseModalWrapper {
    onBackDropClick?: () => void;
    show_modal: boolean;
    children?: any;
    currentPage?: any;
    z?:any;
}



export const BaseModalWrapper = (props: BaseModalWrapper) => {
    if (!props.show_modal) {
        return null
    }
    return ReactDOM.createPortal(
        <div className={classNames(classes.modal_container)} style={props.z &&{ zIndex:100}} onClick={e => e.stopPropagation()} >
            {(props.onBackDropClick && props.currentPage !== "error") && <Button onClick={props.onBackDropClick} className={classNames(classes.close_modal)} >
                <img src="/images/CloseIcon/close.svg" alt="close" />
            </Button>}
            {props.children}
        </div>, document.getElementById('modal-root')!
    )
}

