import { Grid } from "@mui/material";
import {HeaderFive} from "./HeaderFive";
import {Paragraph} from "./Paragraph";

interface IModel {
  header?: any;
  paragraph?: any;
}

export const CardHeader = (props: IModel) => {
  return (
    <Grid style={{ textAlign: "center" }}>
      <HeaderFive>
        {props.header}
      </HeaderFive>
      <Paragraph extraStyles={{my:1}}>
        {props.paragraph}
      </Paragraph>
    </Grid>
  );
};
