import { Container, Grid, Typography } from "@mui/material"
import { customTheme } from "../../../theme/muconfig";
import classes from "./menuprofile.module.scss";
import classNames from "classnames";
import { BoddyOne, BoddyTwo, Caption } from "../../../components/Headers/Headers";
import { IModel } from "./model";

export const View = (props: IModel) => {
    if (!props.show_profile_tab) {
        return null; 
    }
    return (
        < Grid className={classNames(classes.profile_container)} sx={{ border: `1px solid ${customTheme.palette.neutrals.xlligth}`, borderRadius: "4px", width: "170px", p: 2, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "absolute", backgroundColor: customTheme.palette.neutrals.main, zIndex: 1, ...props.extera_style }
        } >
            <div className={classNames(classes.profile_iteme_contents)}>
                <img alt={`${props.profile_avatar}`} src={`/images/Avatars/${props.profile_avatar}.svg`} />
            </div>
            <div className={classNames(classes.profile_iteme_contents, classes.profile_iteme_text)}>
                <BoddyOne variant="body1" sx={{ transform: "captalize" }}>
                    {props.profile_fullname}
                </BoddyOne>
            </div>
            <div className={classNames(classes.profile_iteme_box_content)} >
                <img src="./images/profileModal/setting.svg" alt="user" />
                <BoddyOne variant="body2" sx={{ transform: "captalize" }} onClick={() => props.handler_onRedirectProfileSettingPage()}>
                    Profile setting
                </BoddyOne>
            </div>
            <div className={classNames(classes.profile_iteme_box_content)} >
                <img src="./images/profileModal/signout.svg" alt="user" />
                <BoddyOne variant="body2" sx={{ transform: "captalize" }} onClick={() => props.handler_onSignOut()}>
                    Sign out
                </BoddyOne>
            </div>
        </Grid >

    );
}